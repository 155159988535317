import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Typography,
  Box,
  Grid,
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Alert,
} from '@mui/material';
import debounce from 'lodash/debounce';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import api from '../api/base';
import Day from '../components/Day/Day';

const DAYS_PER_WEEK = 7;

const useWeeks = (initialWeeks: number) => {
  const [weeks, setWeeks] = useState(initialWeeks);
  const addWeek = useCallback(() => setWeeks((prev) => prev + 1), []);
  const removeWeek = useCallback(() => setWeeks((prev) => Math.max(1, prev - 1)), []);
  return { weeks, setWeeks, addWeek, removeWeek };
};

const useDebouncedSave = (programId: string | undefined) => {
  const debouncedSaveDayContent = useRef(
    debounce(async (programId: string, weekNumber: number, dayNumber: number, content: string) => {
      try {
        await api.post(`/api/programs/${programId}/days`, { weekNumber, dayNumber, content });
        console.log('Day content saved successfully');
      } catch (error) {
        console.error('Error saving day content:', error);
      }
    }, 500),
  ).current;

  const debouncedSave = useCallback(
    (weekNumber: number, dayNumber: number, content: string) => {
      if (programId) {
        debouncedSaveDayContent(programId, weekNumber, dayNumber, content);
      }
    },
    [programId, debouncedSaveDayContent],
  );

  return { debouncedSave };
};

function WorkoutPlanner() {
  const { weeks, setWeeks, addWeek, removeWeek } = useWeeks(2);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<{ status: number; data: any } | null>(null);
  const [firstCellContent, setFirstCellContent] = useState('');
  const { programId } = useParams<{ programId: string }>();
  const [programData, setProgramData] = useState<any>(null);
  const { debouncedSave } = useDebouncedSave(programId);

  useEffect(() => {
    if (programId) {
      fetchProgramData(parseInt(programId));
    }
  }, [programId]);

  const fetchProgramData = async (id: number) => {
    try {
      const response = await api.get(`/api/programs/${id}`);
      setProgramData(response.data);
      if (response.data.days && response.data.days.length > 0) {
        const maxWeek = Math.max(...response.data.days.map((day: any) => day.weekNumber));
        setWeeks(Math.max(2, maxWeek));
        setFirstCellContent(
          response.data.days.find((day: any) => day.weekNumber === 1 && day.dayNumber === 1)
            ?.content || '',
        );
      }
    } catch (error) {
      console.error('Error fetching program data:', error);
    }
  };

  const handleSendClick = () => {
    if (!firstCellContent || firstCellContent.trim() === '') {
      alert('Please enter content in the first cell (Week 1, Day 1) before sending.');
      return;
    }
    setOpenDialog(true);
  };

  const handleConfirmSend = async () => {
    setOpenDialog(false);
    setIsLoading(true);

    try {
      const response = await api.post('/wods', { content: firstCellContent });
      setResponse({ status: response.data.code, data: response.data });
    } catch (error: any) {
      setResponse({
        status: error.response?.data?.code || 500,
        data: error.response?.data?.error || 'An unexpected error occurred',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFirstCellChange = useCallback((content: string) => {
    setFirstCellContent(content);
  }, []);

  return (
    <Container maxWidth="xl">
      <Header title="Program" onSendClick={handleSendClick} />
      <WeekGrid
        weeks={weeks}
        daysPerWeek={DAYS_PER_WEEK}
        onFirstCellChange={handleFirstCellChange}
        onDayContentChange={debouncedSave}
        initialDaysData={programData?.days || []}
      />
      <ActionButtons onAddWeek={addWeek} onRemoveWeek={removeWeek} />
      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirmSend}
      />
      {isLoading && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      )}
      {response && (
        <Box mt={2}>
          <Alert severity={response.status === 200 ? 'success' : 'error'}>
            Status: {response.status}
            <pre>{JSON.stringify(response.data, null, 2)}</pre>
          </Alert>
        </Box>
      )}
    </Container>
  );
}

export default WorkoutPlanner;

interface HeaderProps {
  title: string;
  onSendClick: () => void;
}

const Header: React.FC<HeaderProps> = ({ title, onSendClick }) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
    <Typography variant="h5">{title}</Typography>
    <Box display="flex" alignItems="center" gap={2}>
      {/* Send button temporarily removed
      <Button
        variant="contained"
        color="primary"
        onClick={onSendClick}
        sx={{ textTransform: 'none' }}
      >
        Send
      </Button>
      */}
    </Box>
  </Box>
);

interface WeekGridProps {
  weeks: number;
  daysPerWeek: number;
  onFirstCellChange: (content: string) => void;
  onDayContentChange: (weekNumber: number, dayNumber: number, content: string) => void;
  initialDaysData: any[];
}

const WeekGrid: React.FC<WeekGridProps> = ({
  weeks,
  daysPerWeek,
  onFirstCellChange,
  onDayContentChange,
  initialDaysData,
}) => (
  <Box sx={{ border: 1, borderColor: 'grey.300', borderRadius: 1, overflow: 'hidden' }}>
    {[...Array(weeks)].map((_, weekIndex) => (
      <Grid container key={weekIndex}>
        {[...Array(daysPerWeek)].map((_, dayIndex) => {
          const dayNumber = weekIndex * daysPerWeek + dayIndex + 1;
          const initialContent =
            initialDaysData.find(
              (day) => day.weekNumber === weekIndex + 1 && day.dayNumber === dayNumber,
            )?.content || '';
          return (
            <Day
              key={dayNumber}
              weekNumber={weekIndex + 1}
              dayNumber={dayNumber}
              isFirstInRow={dayIndex === 0}
              initialContent={initialContent}
              onContentChange={(content) => {
                if (weekIndex === 0 && dayIndex === 0) {
                  onFirstCellChange(content);
                }
                onDayContentChange(weekIndex + 1, dayNumber, content);
              }}
            />
          );
        })}
      </Grid>
    ))}
  </Box>
);

const buttonStyles = {
  addButton: {
    textTransform: 'none',
    bgcolor: '#1976d2',
    '&:hover': { bgcolor: '#1565c0' },
  },
  removeButton: {
    textTransform: 'none',
    color: '#1976d2',
    borderColor: '#1976d2',
    '&:hover': { bgcolor: 'rgba(25, 118, 210, 0.04)' },
  },
};

interface ActionButtonsProps {
  onAddWeek: () => void;
  onRemoveWeek: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ onAddWeek, onRemoveWeek }) => (
  <Box mt={2} display="flex" gap={1}>
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      onClick={onAddWeek}
      sx={buttonStyles.addButton}
    >
      Add Week
    </Button>
    <Button
      variant="outlined"
      startIcon={<RemoveIcon />}
      onClick={onRemoveWeek}
      sx={buttonStyles.removeButton}
    >
      Remove Week
    </Button>
  </Box>
);

interface ConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, onClose, onConfirm }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{'Confirm Send'}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Are you sure you want to send the content of Week 1, Day 1 to Wodup?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onConfirm} autoFocus>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

import { WorkoutAttrTypes } from './types';

export function typeCheck(value: string, type: WorkoutAttrTypes): boolean {
  switch (type & ~WorkoutAttrTypes.Optional) {
    case WorkoutAttrTypes.Number:
      return !isNaN(Number(value));
    case WorkoutAttrTypes.String:
      return value[0] === '"' && value[value.length - 1] === '"';
    case WorkoutAttrTypes.Time:
      const split = value.split(':');
      return split.length === 2 && !isNaN(Number(split[0])) && !isNaN(Number(split[1]));
    default:
      return true;
  }
}

export function normalizeName(name: string): string {
  return name.replace(' ', '_').toLowerCase();
}

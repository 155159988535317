import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  IconButton,
  FormGroup,
  Typography,
} from '@mui/material';
import React from 'react';

import { Movement } from '../../services/movementsService';

import * as styles from './MovementLibraryDialog.styles';
import { MovementDialogProps } from './MovementLibraryDialog.types';

const MovementLibraryDialog: React.FC<MovementDialogProps> = ({
  open,
  onClose,
  onSave,
  onDelete,
  movement,
}) => {
  const [formData, setFormData] = React.useState<Movement>({
    id: movement?.id || 0,
    name: '',
    has_reps: false,
    has_load: false,
    has_duration: false,
    has_distance: false,
    has_height: false,
    has_power: false,
    has_calories: false,
    is_complex: false,
    video_url: movement?.videos ? movement.video_url : '',
  });

  const [isEditingUrl, setIsEditingUrl] = React.useState(false);
  const [urlError, setUrlError] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      setIsEditingUrl(false);
      if (movement) {
        setFormData({
          ...movement,
          video_url: movement.videos ? movement.video_url : '',
        });
      } else {
        setFormData({
          id: 0,
          name: '',
          has_reps: false,
          has_load: false,
          has_duration: false,
          has_distance: false,
          has_height: false,
          has_power: false,
          has_calories: false,
          is_complex: false,
          video_url: '',
        });
      }
    } else {
      setIsEditingUrl(false);
    }
  }, [open, movement]);

  const attributes = [
    { name: 'Reps', field: 'has_reps' },
    { name: 'Load', field: 'has_load' },
    { name: 'Duration', field: 'has_duration' },
    { name: 'Distance', field: 'has_distance' },
    { name: 'Height', field: 'has_height' },
    { name: 'Power', field: 'has_power' },
    { name: 'Calories', field: 'has_calories' }
  ];

  const handleAttributeChange = (field: string) => {
    setFormData((prev) => ({
      ...prev,
      [field]: !prev[field as keyof Movement],
    }));
  };

  const validateUrl = (url: string) => {
    const pattern = new RegExp('^(https?:\\/\\/)?'+
      '((([a-z0-9](?:[a-z0-9-]*[a-z0-9])?)\\.)+[a-z]{2,}|'+
      'localhost|'+
      '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|'+
      '\\[?[a-f0-9]*:[a-f0-9:]+\\]?)'+
      '(\\:\\d+)?(\\/[-a-z0-9%_.~+]*)*'+
      '(\\?[;&a-z0-9%_.~+=-]*)?'+
      '(\\#[-a-z0-9_]*)?$','i');
    return !!pattern.test(url);
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUrl = e.currentTarget.value;
    setFormData((prev) => ({ ...prev, video_url: newUrl }));

    if (validateUrl(newUrl) || newUrl === '') {
      setUrlError(false);
    } else {
      setUrlError(true);
    }
  };

  const handleSave = () => {
    if (urlError) {
      return;
    }
    onSave(formData);
    setIsEditingUrl(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setIsEditingUrl(false);
      }}
      maxWidth="sm"
      fullWidth
      PaperProps={{ sx: styles.dialogPaperProps }}
    >
      <DialogTitle sx={styles.dialogTitleStyles}>
        {movement ? 'Edit movement' : 'New movement'}
        <IconButton onClick={onClose} sx={styles.closeIconStyles}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={styles.dialogContentStyles}>
        <Box sx={styles.nameBoxStyles}>
          <Typography variant="subtitle1" sx={styles.helperTextStyles}>
            Name <span style={{ color: '#1976d2' }}>*</span>
          </Typography>
          <TextField
            required
            fullWidth
            value={formData.name}
            onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
            sx={styles.textFieldStyles}
          />
        </Box>

        <Typography variant="subtitle1" sx={styles.helperTextStyles}>
          Attributes <span style={{ color: '#1976d2' }}>*</span>
        </Typography>
        <FormGroup sx={styles.formGroupStyles}>
          {attributes.map(({ name, field }) => (
            <FormControlLabel
              key={field}
              control={
                <Checkbox
                  checked={formData[field as keyof Movement] as boolean}
                  onChange={() => handleAttributeChange(field)}
                  sx={styles.checkboxStyles}
                />
              }
              label={name}
            />
          ))}
        </FormGroup>
        <Typography variant="body2" color="text.secondary" sx={styles.helperTextStyles}>
          These are the things you might possibly log for this movement.
        </Typography>

        {isEditingUrl ? (
          <TextField
            fullWidth
            placeholder="Enter video URL"
            value={formData.video_url}
            onChange={handleUrlChange}
            error={urlError}
            helperText={urlError ? "The video URL is wrong" : ""}
            sx={styles.videoUrlFieldStyles}
          />
        ) : (
          <>
            {formData.video_url ? (
              <>
                <Typography variant="body2" color="text.secondary" sx={styles.helperTextStyles}>
                  <a href={formData.video_url} target="_blank" rel="noopener noreferrer">
                    {formData.video_url}
                  </a>
                  <Button onClick={() => setIsEditingUrl(true)} sx={styles.editButtonStyles}>
                    Edit
                  </Button>
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body2" color="text.secondary" sx={styles.helperTextStyles}>
                  No video url attached
                  <Button onClick={() => setIsEditingUrl(true)} sx={styles.editButtonStyles}>
                    Edit
                  </Button>
                </Typography>
              </>
            )}
          </>
        )}

        <TextField
          fullWidth
          multiline
          rows={4}
          label="Description / instructions (optional)"
          value={formData.complex_details || ''}
          onChange={(e) => setFormData((prev) => ({ ...prev, complex_details: e.target.value }))}
          sx={styles.textFieldStyles}
        />
      </DialogContent>

      <DialogActions sx={styles.dialogActionsStyles}>
        <Box>
          {movement && onDelete && (
            <Button
              startIcon={<DeleteOutlineIcon />}
              onClick={onDelete}
              sx={styles.removeButtonStyles}
              variant="outlined"
            >
              Remove from library
            </Button>
          )}
        </Box>
        <Box>
          <Button onClick={() => {
            onClose();
            setIsEditingUrl(false);
          }} sx={styles.cancelButtonStyles}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!formData.name || urlError}
            sx={styles.saveButtonStyles}
          >
            {movement ? 'Save changes' : 'Create movement'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default MovementLibraryDialog;

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HelpIcon from '@mui/icons-material/Help';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import { List, ListItem, ListItemText, ListItemIcon, Drawer, Box, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAuth } from '../../auth/context/AuthContext';

import { styles } from './Navigation.styles';
import { MenuItem } from './Navigation.types';

const menuItems: MenuItem[] = [
  {
    text: 'Dashboard',
    icon: (
      <img
        src={require('../../assets/red_logo.jpg')}
        alt="Logo"
        style={{ width: 60, height: 60 }}
      />
    ),
    path: '/programs',
    isDashboard: true,
  },
  {
    text: 'Clients',
    icon: <PeopleIcon fontSize="small" />,
    path: '/clients',
    inProgress: true,
  },
  { text: 'Library', icon: <LibraryBooksIcon fontSize="small" />, path: '/movement-library' },
  { text: 'Programs', icon: <CalendarTodayIcon fontSize="small" />, path: '/programs' },
  {
    text: 'Team',
    icon: <PeopleIcon fontSize="small" />,
    path: '/team/123',
    inProgress: true,
  },
  { text: 'Graphs', icon: <EqualizerIcon fontSize="small" />, path: '/graphs' },
  {
    text: 'Settings',
    icon: <SettingsIcon fontSize="small" />,
    path: '/account',
    inProgress: true,
  },
  {
    text: 'Help Center',
    icon: <HelpIcon fontSize="small" />,
    path: 'https://www.ttt.com',
    inProgress: true,
  },
  {
    text: 'Logout',
    icon: <ExitToAppIcon fontSize="small" />,
    path: '/logout',
    isLogout: true,
  },
];

const Navigation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAuth();

  const renderMenuItem = (
    item: MenuItem,
    index: number,
    justifyContent: 'flex-start' | 'center' = 'flex-start',
  ) => (
    <ListItem
      component="button"
      key={index}
      onClick={() => {
        if (item.isLogout) {
          user.logoutAction();
        } else if (!item.inProgress) {
          navigate(item.path);
        }
      }}
      sx={{
        ...styles.menuItem(location.pathname === item.path && !item.isDashboard, justifyContent),
        ...(item.inProgress && styles.inProgressMenuItem),
      }}
    >
      <Box sx={item.inProgress ? styles.inProgressBadge : undefined}>
        <ListItemIcon sx={styles.listItemIcon}>{item.icon}</ListItemIcon>
      </Box>
      {justifyContent === 'flex-start' && (
        <ListItemText
          primary={
            <Typography variant="body2" sx={styles.listItemText}>
              {item.text}
            </Typography>
          }
        />
      )}
    </ListItem>
  );

  return (
    <Drawer variant="permanent" anchor="left" sx={styles.drawer}>
      <Box sx={styles.box}>
        <List>
          {menuItems.slice(0, 1).map((item, index) => renderMenuItem(item, index, 'center'))}
          {menuItems.slice(1, 6).map((item, index) => renderMenuItem(item, index))}
        </List>
        <Box sx={{ flexGrow: 1 }} />
        <List>
          {menuItems.slice(6).map((item, index) => renderMenuItem(item, index, 'center'))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Navigation;

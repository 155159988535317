import { jwtDecode, JwtPayload } from 'jwt-decode';

import { TOKEN_KEY } from '../constants/auth';

export const tokenService = {
  getToken: () => localStorage.getItem(TOKEN_KEY),

  setToken: (token: string) => localStorage.setItem(TOKEN_KEY, token),

  removeToken: () => localStorage.removeItem(TOKEN_KEY),

  isTokenExpired: (token: string): boolean => {
    try {
      const decodedToken = jwtDecode<JwtPayload>(token);
      const currentTime = Date.now() / 1000;
      return !decodedToken.exp || decodedToken.exp < currentTime;
    } catch {
      return true;
    }
  },
};

// The logger wraps the console, which itself uses any
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

export enum LogLevel {
  ERROR = 0,
  WARN = 1,
  INFO = 2,
  DEBUG = 3,
}

export default class Logger {
  private level: LogLevel;

  private parseLogLevel(val?: string): LogLevel {
    switch (val?.toUpperCase().trim() ?? '') {
      case 'ERROR':
        return LogLevel.ERROR;
      case 'WARN':
        return LogLevel.WARN;
      case 'INFO':
        return LogLevel.INFO;
      case 'DEBUG':
        return LogLevel.DEBUG;
      default:
        if (process.env.NODE_ENV !== 'test') {
          console.warn("Logger :: config error -- couldn't get log level, defaulting to ERROR");
        }
        return LogLevel.ERROR;
    }
  }

  constructor() {
    this.level = this.getDefaultLogLevel();
  }

  private getDefaultLogLevel(): LogLevel {
    switch (process.env.NODE_ENV) {
      case 'production':
        return LogLevel.ERROR;
      case 'test':
        return LogLevel.DEBUG;
      case 'development':
        return this.parseLogLevel(process.env.REACT_APP_LOG_LEVEL);
      default:
        return LogLevel.ERROR;
    }
  }

  private shouldLog(ll: LogLevel) {
    return ll <= this.level;
  }

  public error(message?: any, ...optionalParams: any[]): void {
    if (this.shouldLog(LogLevel.ERROR)) {
      console.error(message, optionalParams);
    }
  }
  public warn(message?: any, ...optionalParams: any[]): void {
    if (this.shouldLog(LogLevel.WARN)) {
      console.warn(message, optionalParams);
    }
  }
  public info(message?: any, ...optionalParams: any[]): void {
    if (this.shouldLog(LogLevel.INFO)) {
      console.info(message, optionalParams);
    }
  }
  public debug(message?: any, ...optionalParams: any[]): void {
    if (this.shouldLog(LogLevel.DEBUG)) {
      console.debug(message, optionalParams);
    }
  }

  public log(logLevel: LogLevel, message?: any, ...optionalParams: any[]): void {
    switch (logLevel) {
      case LogLevel.DEBUG:
        this.debug(message, optionalParams);
        break;
      case LogLevel.INFO:
        this.info(message, optionalParams);
        break;
      case LogLevel.WARN:
        this.warn(message, optionalParams);
        break;
      case LogLevel.ERROR:
        this.error(message, optionalParams);
        break;
      default:
        break;
    }
  }
}
export const logger = new Logger();

// Generated from UniversalWorkoutLanguageLexer.g4 by ANTLR 4.13.1

import * as antlr from "antlr4ng";
import { Token } from "antlr4ng";


export class UniversalWorkoutLanguageLexer extends antlr.Lexer {
    public static readonly WS = 1;
    public static readonly NL = 2;
    public static readonly WORKOUT_SEPARATOR = 3;
    public static readonly MOVEMENT_MARKER = 4;
    public static readonly CHOICE_KW = 5;
    public static readonly OPTION_KW = 6;
    public static readonly WOD_KW = 7;
    public static readonly DEF_KW = 8;
    public static readonly NEW_MOV_KW = 9;
    public static readonly REST_KW = 10;
    public static readonly STRING = 11;
    public static readonly GENERIC_STRING = 12;
    public static readonly PAREN_L = 13;
    public static readonly ALTERNATE_KW = 14;
    public static readonly LBRACE = 15;
    public static readonly RBRACE = 16;
    public static readonly COLON = 17;
    public static readonly INLINE_COMMENT = 18;
    public static readonly MULTILINE_COMMENT = 19;
    public static readonly WORKOUT_IDENTIFIER = 20;
    public static readonly ALTERNATION_NUMBER = 21;
    public static readonly ERROR_CHAR = 22;
    public static readonly WORKOUT_WS = 23;
    public static readonly WORKOUT_PAREN_L = 24;
    public static readonly WORKOUT_PAREN_R = 25;
    public static readonly WORKOUT_COLON = 26;
    public static readonly WORKOUT_COMMA = 27;
    public static readonly WORKOUT_QUOTE = 28;
    public static readonly WORKOUT_STRING = 29;
    public static readonly WORKOUT_ML_STRING = 30;
    public static readonly WORKOUT_TIME = 31;
    public static readonly WORKOUT_NUMBER = 32;
    public static readonly WORKOUT_UNIT_TIME = 33;
    public static readonly WORKOUT_IDEN = 34;
    public static readonly CHOICE_WS = 35;
    public static readonly CHOICE_NAME = 36;
    public static readonly MOVEMENT_NAME_REP_MARKER = 37;
    public static readonly SIMPLE_MOVEMENT = 38;
    public static readonly MOVEMENT_NAME_REPS = 39;
    public static readonly MOVEMENT_NAME_COMPOENENT_SEPARATOR = 40;
    public static readonly COMPLEX_MOVEMENT = 41;
    public static readonly MOVEMENT_NAME_TERMINATOR = 42;
    public static readonly MOVEMENT_NAME_WS = 43;
    public static readonly MC_WS = 44;
    public static readonly MC_NUMBER = 45;
    public static readonly MC_MAX = 46;
    public static readonly MC_TIME = 47;
    public static readonly MC_SET_SEPARATOR = 48;
    public static readonly LOAD_MARKER = 49;
    public static readonly TEMPO_MARKER = 50;
    public static readonly DURATION_MARKER = 51;
    public static readonly DISTANCE_MARKER = 52;
    public static readonly MOVEMENT_TERM = 53;
    public static readonly MC_CONTENT = 54;
    public static readonly MOV_ERR = 55;
    public static readonly ALT_WS = 56;
    public static readonly ALT_KW = 57;
    public static readonly DEF_WS = 58;
    public static readonly DEF_COMMENT = 59;
    public static readonly DEF_REGEX = 60;
    public static readonly DEF_IDENTIFIER = 61;
    public static readonly DEF_QUOTE = 62;
    public static readonly DEF_COLON = 63;
    public static readonly DEF_OPTIONAL = 64;
    public static readonly DEF_COMMA = 65;
    public static readonly DEF_PAREN_L = 66;
    public static readonly DEF_PAREN_R = 67;
    public static readonly DEF_BRACE_L = 68;
    public static readonly DEF_BRACE_R = 69;
    public static readonly DEF_SUPER_KW = 70;
    public static readonly DEF_TYPES = 71;
    public static readonly DEF_ENUM_KW = 72;
    public static readonly NEW_MOV_WS = 73;
    public static readonly NEW_MOV_QUOTE = 74;
    public static readonly NEW_MOV_IDENTIFIER = 75;
    public static readonly NEW_MOV_COLON = 76;
    public static readonly NEW_MOV_COMMA = 77;
    public static readonly NEW_MOV_SEMI = 78;
    public static readonly NEW_MOV_PARAM = 79;
    public static readonly M_WORKOUT = 1;
    public static readonly CH_NAME = 2;
    public static readonly M_MOVEMENT_NAME = 3;
    public static readonly M_MOVEMENT_CONTENT = 4;
    public static readonly M_ALT = 5;
    public static readonly M_DEF = 6;
    public static readonly M_NEW_MOV = 7;

    public static readonly channelNames = [
        "DEFAULT_TOKEN_CHANNEL", "HIDDEN", "CH_WS", "CH_ERR", "CH_COMM"
    ];

    public static readonly literalNames = [
        null, null, null, null, null, null, null, null, "'define'", "'newMovement'", 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, "'M'", null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, "'?'", 
        null, null, null, null, null, null, null, "'enum'", null, null, 
        null, null, null, "';'"
    ];

    public static readonly symbolicNames = [
        null, "WS", "NL", "WORKOUT_SEPARATOR", "MOVEMENT_MARKER", "CHOICE_KW", 
        "OPTION_KW", "WOD_KW", "DEF_KW", "NEW_MOV_KW", "REST_KW", "STRING", 
        "GENERIC_STRING", "PAREN_L", "ALTERNATE_KW", "LBRACE", "RBRACE", 
        "COLON", "INLINE_COMMENT", "MULTILINE_COMMENT", "WORKOUT_IDENTIFIER", 
        "ALTERNATION_NUMBER", "ERROR_CHAR", "WORKOUT_WS", "WORKOUT_PAREN_L", 
        "WORKOUT_PAREN_R", "WORKOUT_COLON", "WORKOUT_COMMA", "WORKOUT_QUOTE", 
        "WORKOUT_STRING", "WORKOUT_ML_STRING", "WORKOUT_TIME", "WORKOUT_NUMBER", 
        "WORKOUT_UNIT_TIME", "WORKOUT_IDEN", "CHOICE_WS", "CHOICE_NAME", 
        "MOVEMENT_NAME_REP_MARKER", "SIMPLE_MOVEMENT", "MOVEMENT_NAME_REPS", 
        "MOVEMENT_NAME_COMPOENENT_SEPARATOR", "COMPLEX_MOVEMENT", "MOVEMENT_NAME_TERMINATOR", 
        "MOVEMENT_NAME_WS", "MC_WS", "MC_NUMBER", "MC_MAX", "MC_TIME", "MC_SET_SEPARATOR", 
        "LOAD_MARKER", "TEMPO_MARKER", "DURATION_MARKER", "DISTANCE_MARKER", 
        "MOVEMENT_TERM", "MC_CONTENT", "MOV_ERR", "ALT_WS", "ALT_KW", "DEF_WS", 
        "DEF_COMMENT", "DEF_REGEX", "DEF_IDENTIFIER", "DEF_QUOTE", "DEF_COLON", 
        "DEF_OPTIONAL", "DEF_COMMA", "DEF_PAREN_L", "DEF_PAREN_R", "DEF_BRACE_L", 
        "DEF_BRACE_R", "DEF_SUPER_KW", "DEF_TYPES", "DEF_ENUM_KW", "NEW_MOV_WS", 
        "NEW_MOV_QUOTE", "NEW_MOV_IDENTIFIER", "NEW_MOV_COLON", "NEW_MOV_COMMA", 
        "NEW_MOV_SEMI", "NEW_MOV_PARAM"
    ];

    public static readonly modeNames = [
        "DEFAULT_MODE", "M_WORKOUT", "CH_NAME", "M_MOVEMENT_NAME", "M_MOVEMENT_CONTENT", 
        "M_ALT", "M_DEF", "M_NEW_MOV",
    ];

    public static readonly ruleNames = [
        "G_WS", "G_NL", "G_FULL_WS", "G_ML_COMMENT", "WS", "NL", "INTEGER_LITERAL", 
        "FLOAT_LITERAL", "G_TIME_LITERAL", "G_UNIT_TIME_LITERAL", "REGEX_MARKER", 
        "REGEX_LITERAL", "ERR_G", "WORKOUT_SEPARATOR", "MOVEMENT_MARKER", 
        "CHOICE_KW", "OPTION_KW", "WOD_KW", "DEF_KW", "NEW_MOV_KW", "REST_KW", 
        "STRING", "GENERIC_STRING", "PAREN_L", "ALTERNATE_KW", "WORD_G", 
        "LBRACE", "RBRACE", "COLON", "INLINE_COMMENT", "MULTILINE_COMMENT", 
        "WORKOUT_IDENTIFIER", "ALTERNATION_NUMBER", "ERROR_CHAR", "WORKOUT_WS", 
        "WORKOUT_PAREN_L", "WORKOUT_PAREN_R", "WORKOUT_COLON", "WORKOUT_COMMA", 
        "WORKOUT_QUOTE", "WORKOUT_STRING", "WORKOUT_ML_STRING", "WORKOUT_TIME", 
        "WORKOUT_NUMBER", "WORKOUT_UNIT_TIME", "WORKOUT_IDEN", "CHOICE_WS", 
        "CHOICE_NAME", "MOVEMENT_NAME_REP_MARKER", "SIMPLE_MOVEMENT", "MOVEMENT_NAME_REPS", 
        "MOVEMENT_NAME_COMPOENENT_SEPARATOR", "COMPLEX_MOVEMENT", "MOVEMENT_NAME_TERMINATOR", 
        "MOVEMENT_NAME_WS", "MC_WS", "MC_NUMBER", "MC_MAX", "MC_TIME", "MC_SET_SEPARATOR", 
        "LOAD_MARKER", "TEMPO_MARKER", "DURATION_MARKER", "DISTANCE_MARKER", 
        "MOVEMENT_TERM", "MC_CONTENT", "MOV_ERR", "ALT_WS", "ALT_KW", "DEF_WS", 
        "DEF_COMMENT", "DEF_REGEX", "DEF_IDENTIFIER", "DEF_QUOTE", "DEF_COLON", 
        "DEF_OPTIONAL", "DEF_COMMA", "DEF_PAREN_L", "DEF_PAREN_R", "DEF_BRACE_L", 
        "DEF_BRACE_R", "DEF_SUPER_KW", "DEF_TYPES", "DEF_ENUM_KW", "NEW_MOV_WS", 
        "NEW_MOV_QUOTE", "NEW_MOV_IDENTIFIER", "NEW_MOV_COLON", "NEW_MOV_COMMA", 
        "NEW_MOV_SEMI", "NEW_MOV_PARAM",
    ];


    public constructor(input: antlr.CharStream) {
        super(input);
        this.interpreter = new antlr.LexerATNSimulator(this, UniversalWorkoutLanguageLexer._ATN, UniversalWorkoutLanguageLexer.decisionsToDFA, new antlr.PredictionContextCache());
    }

    public get grammarFileName(): string { return "UniversalWorkoutLanguageLexer.g4"; }

    public get literalNames(): (string | null)[] { return UniversalWorkoutLanguageLexer.literalNames; }
    public get symbolicNames(): (string | null)[] { return UniversalWorkoutLanguageLexer.symbolicNames; }
    public get ruleNames(): string[] { return UniversalWorkoutLanguageLexer.ruleNames; }

    public get serializedATN(): number[] { return UniversalWorkoutLanguageLexer._serializedATN; }

    public get channelNames(): string[] { return UniversalWorkoutLanguageLexer.channelNames; }

    public get modeNames(): string[] { return UniversalWorkoutLanguageLexer.modeNames; }

    public static readonly _serializedATN: number[] = [
        4,0,79,785,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,6,-1,2,0,7,0,2,1,7,
        1,2,2,7,2,2,3,7,3,2,4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,
        9,2,10,7,10,2,11,7,11,2,12,7,12,2,13,7,13,2,14,7,14,2,15,7,15,2,
        16,7,16,2,17,7,17,2,18,7,18,2,19,7,19,2,20,7,20,2,21,7,21,2,22,7,
        22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,26,2,27,7,27,2,28,7,28,2,
        29,7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,33,7,33,2,34,7,34,2,35,7,
        35,2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,39,2,40,7,40,2,41,7,41,2,
        42,7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,46,7,46,2,47,7,47,2,48,7,
        48,2,49,7,49,2,50,7,50,2,51,7,51,2,52,7,52,2,53,7,53,2,54,7,54,2,
        55,7,55,2,56,7,56,2,57,7,57,2,58,7,58,2,59,7,59,2,60,7,60,2,61,7,
        61,2,62,7,62,2,63,7,63,2,64,7,64,2,65,7,65,2,66,7,66,2,67,7,67,2,
        68,7,68,2,69,7,69,2,70,7,70,2,71,7,71,2,72,7,72,2,73,7,73,2,74,7,
        74,2,75,7,75,2,76,7,76,2,77,7,77,2,78,7,78,2,79,7,79,2,80,7,80,2,
        81,7,81,2,82,7,82,2,83,7,83,2,84,7,84,2,85,7,85,2,86,7,86,2,87,7,
        87,2,88,7,88,2,89,7,89,2,90,7,90,1,0,4,0,192,8,0,11,0,12,0,193,1,
        1,3,1,197,8,1,1,1,1,1,3,1,201,8,1,1,2,1,2,3,2,205,8,2,1,2,1,2,3,
        2,209,8,2,4,2,211,8,2,11,2,12,2,212,1,3,1,3,1,3,1,3,5,3,219,8,3,
        10,3,12,3,222,9,3,1,3,1,3,1,3,1,4,4,4,228,8,4,11,4,12,4,229,1,4,
        1,4,1,5,3,5,235,8,5,1,5,1,5,3,5,239,8,5,1,5,1,5,1,6,1,6,1,6,5,6,
        246,8,6,10,6,12,6,249,9,6,3,6,251,8,6,1,7,1,7,1,7,5,7,256,8,7,10,
        7,12,7,259,9,7,3,7,261,8,7,1,7,1,7,4,7,265,8,7,11,7,12,7,266,3,7,
        269,8,7,1,8,5,8,272,8,8,10,8,12,8,275,9,8,1,8,1,8,1,8,3,8,280,8,
        8,1,9,4,9,283,8,9,11,9,12,9,284,1,9,3,9,288,8,9,1,9,5,9,291,8,9,
        10,9,12,9,294,9,9,1,10,1,10,1,11,1,11,4,11,300,8,11,11,11,12,11,
        301,1,11,1,11,1,12,1,12,1,13,1,13,1,14,1,14,1,14,1,14,1,15,1,15,
        1,15,1,15,1,15,1,15,1,15,1,15,1,15,1,16,1,16,1,16,1,16,1,16,1,16,
        1,16,1,16,1,16,1,17,1,17,1,17,1,17,1,17,1,17,1,17,1,17,1,17,1,17,
        1,17,1,17,1,17,1,17,1,17,1,17,1,17,1,17,1,17,1,17,1,17,1,17,1,17,
        3,17,355,8,17,1,17,3,17,358,8,17,1,18,1,18,1,18,1,18,1,18,1,18,1,
        18,1,18,1,18,1,19,1,19,1,19,1,19,1,19,1,19,1,19,1,19,1,19,1,19,1,
        19,1,19,1,19,1,19,1,20,1,20,1,20,1,20,1,20,1,21,1,21,5,21,390,8,
        21,10,21,12,21,393,9,21,1,21,1,21,1,22,1,22,5,22,399,8,22,10,22,
        12,22,402,9,22,1,22,1,22,1,23,1,23,1,23,1,23,1,24,1,24,1,24,1,24,
        1,25,4,25,415,8,25,11,25,12,25,416,1,26,1,26,1,27,1,27,1,28,1,28,
        1,29,1,29,5,29,427,8,29,10,29,12,29,430,9,29,1,29,1,29,1,30,1,30,
        1,30,1,30,1,31,1,31,5,31,440,8,31,10,31,12,31,443,9,31,1,31,1,31,
        1,32,4,32,448,8,32,11,32,12,32,449,1,32,1,32,1,33,1,33,1,34,1,34,
        1,34,1,34,1,35,1,35,1,36,1,36,1,36,1,36,1,37,1,37,1,38,1,38,1,39,
        1,39,1,40,1,40,5,40,474,8,40,10,40,12,40,477,9,40,1,40,1,40,1,41,
        1,41,1,41,1,41,1,41,5,41,486,8,41,10,41,12,41,489,9,41,1,41,1,41,
        1,41,1,41,1,42,1,42,1,43,1,43,1,44,1,44,1,45,1,45,5,45,503,8,45,
        10,45,12,45,506,9,45,1,46,1,46,1,46,1,46,1,47,1,47,5,47,514,8,47,
        10,47,12,47,517,9,47,1,47,1,47,1,48,1,48,4,48,523,8,48,11,48,12,
        48,524,1,49,4,49,528,8,49,11,49,12,49,529,1,49,4,49,533,8,49,11,
        49,12,49,534,3,49,537,8,49,1,49,1,49,5,49,541,8,49,10,49,12,49,544,
        9,49,1,50,1,50,5,50,548,8,50,10,50,12,50,551,9,50,1,50,1,50,1,51,
        1,51,1,52,3,52,558,8,52,1,52,1,52,1,52,1,52,3,52,564,8,52,1,52,1,
        52,5,52,568,8,52,10,52,12,52,571,9,52,1,53,1,53,1,53,1,53,1,54,1,
        54,1,54,1,54,1,55,4,55,582,8,55,11,55,12,55,583,1,55,1,55,1,56,4,
        56,589,8,56,11,56,12,56,590,1,56,1,56,4,56,595,8,56,11,56,12,56,
        596,3,56,599,8,56,1,57,1,57,1,58,1,58,1,59,1,59,1,60,1,60,1,61,1,
        61,1,62,1,62,1,62,1,63,1,63,1,63,1,63,1,64,3,64,619,8,64,1,64,1,
        64,3,64,623,8,64,1,64,1,64,1,65,4,65,628,8,65,11,65,12,65,629,1,
        66,1,66,1,66,1,66,1,67,1,67,1,67,1,67,1,68,1,68,1,68,1,68,1,68,1,
        68,3,68,646,8,68,1,68,1,68,3,68,650,8,68,1,68,1,68,1,69,1,69,1,69,
        1,69,1,70,1,70,1,70,1,70,5,70,662,8,70,10,70,12,70,665,9,70,1,70,
        1,70,1,70,1,71,1,71,1,72,1,72,4,72,674,8,72,11,72,12,72,675,1,72,
        1,72,1,73,1,73,1,74,1,74,1,75,1,75,1,76,1,76,1,77,1,77,1,78,1,78,
        1,79,1,79,1,80,1,80,1,80,1,80,1,81,1,81,1,81,1,81,1,81,1,81,1,81,
        1,81,1,81,1,81,1,81,1,81,1,81,1,81,1,81,1,81,1,81,1,81,1,81,1,81,
        1,81,1,81,1,81,1,81,1,81,1,81,1,81,3,81,725,8,81,1,82,1,82,1,82,
        1,82,1,82,1,82,1,82,1,82,1,82,1,82,1,82,1,82,1,82,1,82,1,82,1,82,
        1,82,1,82,1,82,1,82,1,82,1,82,1,82,1,82,1,82,1,82,1,82,3,82,754,
        8,82,1,83,1,83,1,83,1,83,1,83,1,84,1,84,1,84,1,84,1,85,1,85,1,86,
        1,86,4,86,769,8,86,11,86,12,86,770,1,86,1,86,1,87,1,87,1,88,1,88,
        1,89,1,89,1,90,4,90,782,8,90,11,90,12,90,783,7,220,301,391,400,475,
        487,663,0,91,8,0,10,0,12,0,14,0,16,1,18,2,20,0,22,0,24,0,26,0,28,
        0,30,0,32,0,34,3,36,4,38,5,40,6,42,7,44,8,46,9,48,10,50,11,52,12,
        54,13,56,14,58,0,60,15,62,16,64,17,66,18,68,19,70,20,72,21,74,22,
        76,23,78,24,80,25,82,26,84,27,86,28,88,29,90,30,92,31,94,32,96,33,
        98,34,100,35,102,36,104,37,106,38,108,39,110,40,112,41,114,42,116,
        43,118,44,120,45,122,46,124,47,126,48,128,49,130,50,132,51,134,52,
        136,53,138,54,140,55,142,56,144,57,146,58,148,59,150,60,152,61,154,
        62,156,63,158,64,160,65,162,66,164,67,166,68,168,69,170,70,172,71,
        174,72,176,73,178,74,180,75,182,76,184,77,186,78,188,79,8,0,1,2,
        3,4,5,6,7,28,2,0,9,9,32,32,1,0,49,57,1,0,48,57,2,0,65,90,97,122,
        1,0,62,62,2,0,67,67,99,99,2,0,79,79,111,111,2,0,65,65,97,97,2,0,
        77,77,109,109,2,0,80,80,112,112,2,0,87,87,119,119,2,0,68,68,100,
        100,2,0,82,82,114,114,3,0,48,57,65,90,97,122,2,0,10,10,13,13,4,0,
        45,45,65,90,95,95,97,122,5,0,45,45,48,57,65,90,95,95,97,122,6,0,
        32,32,45,45,48,57,65,90,95,95,97,122,2,0,88,88,120,120,5,0,45,45,
        47,47,65,90,95,95,97,122,6,0,32,32,45,45,47,57,65,90,95,95,97,122,
        1,0,32,32,1,0,58,58,2,0,73,73,105,105,2,0,70,70,102,102,5,0,9,10,
        13,13,32,32,44,44,64,64,2,0,83,83,115,115,3,0,65,90,95,95,97,122,
        831,0,16,1,0,0,0,0,18,1,0,0,0,0,34,1,0,0,0,0,36,1,0,0,0,0,38,1,0,
        0,0,0,40,1,0,0,0,0,42,1,0,0,0,0,44,1,0,0,0,0,46,1,0,0,0,0,48,1,0,
        0,0,0,50,1,0,0,0,0,52,1,0,0,0,0,54,1,0,0,0,0,56,1,0,0,0,0,60,1,0,
        0,0,0,62,1,0,0,0,0,64,1,0,0,0,0,66,1,0,0,0,0,68,1,0,0,0,0,70,1,0,
        0,0,0,72,1,0,0,0,0,74,1,0,0,0,1,76,1,0,0,0,1,78,1,0,0,0,1,80,1,0,
        0,0,1,82,1,0,0,0,1,84,1,0,0,0,1,86,1,0,0,0,1,88,1,0,0,0,1,90,1,0,
        0,0,1,92,1,0,0,0,1,94,1,0,0,0,1,96,1,0,0,0,1,98,1,0,0,0,2,100,1,
        0,0,0,2,102,1,0,0,0,3,104,1,0,0,0,3,106,1,0,0,0,3,108,1,0,0,0,3,
        110,1,0,0,0,3,112,1,0,0,0,3,114,1,0,0,0,3,116,1,0,0,0,4,118,1,0,
        0,0,4,120,1,0,0,0,4,122,1,0,0,0,4,124,1,0,0,0,4,126,1,0,0,0,4,128,
        1,0,0,0,4,130,1,0,0,0,4,132,1,0,0,0,4,134,1,0,0,0,4,136,1,0,0,0,
        4,138,1,0,0,0,4,140,1,0,0,0,5,142,1,0,0,0,5,144,1,0,0,0,6,146,1,
        0,0,0,6,148,1,0,0,0,6,150,1,0,0,0,6,152,1,0,0,0,6,154,1,0,0,0,6,
        156,1,0,0,0,6,158,1,0,0,0,6,160,1,0,0,0,6,162,1,0,0,0,6,164,1,0,
        0,0,6,166,1,0,0,0,6,168,1,0,0,0,6,170,1,0,0,0,6,172,1,0,0,0,6,174,
        1,0,0,0,7,176,1,0,0,0,7,178,1,0,0,0,7,180,1,0,0,0,7,182,1,0,0,0,
        7,184,1,0,0,0,7,186,1,0,0,0,7,188,1,0,0,0,8,191,1,0,0,0,10,200,1,
        0,0,0,12,210,1,0,0,0,14,214,1,0,0,0,16,227,1,0,0,0,18,238,1,0,0,
        0,20,250,1,0,0,0,22,260,1,0,0,0,24,273,1,0,0,0,26,282,1,0,0,0,28,
        295,1,0,0,0,30,297,1,0,0,0,32,305,1,0,0,0,34,307,1,0,0,0,36,309,
        1,0,0,0,38,313,1,0,0,0,40,322,1,0,0,0,42,354,1,0,0,0,44,359,1,0,
        0,0,46,368,1,0,0,0,48,382,1,0,0,0,50,387,1,0,0,0,52,396,1,0,0,0,
        54,405,1,0,0,0,56,409,1,0,0,0,58,414,1,0,0,0,60,418,1,0,0,0,62,420,
        1,0,0,0,64,422,1,0,0,0,66,424,1,0,0,0,68,433,1,0,0,0,70,437,1,0,
        0,0,72,447,1,0,0,0,74,453,1,0,0,0,76,455,1,0,0,0,78,459,1,0,0,0,
        80,461,1,0,0,0,82,465,1,0,0,0,84,467,1,0,0,0,86,469,1,0,0,0,88,471,
        1,0,0,0,90,480,1,0,0,0,92,494,1,0,0,0,94,496,1,0,0,0,96,498,1,0,
        0,0,98,500,1,0,0,0,100,507,1,0,0,0,102,511,1,0,0,0,104,520,1,0,0,
        0,106,536,1,0,0,0,108,545,1,0,0,0,110,554,1,0,0,0,112,557,1,0,0,
        0,114,572,1,0,0,0,116,576,1,0,0,0,118,581,1,0,0,0,120,588,1,0,0,
        0,122,600,1,0,0,0,124,602,1,0,0,0,126,604,1,0,0,0,128,606,1,0,0,
        0,130,608,1,0,0,0,132,610,1,0,0,0,134,613,1,0,0,0,136,622,1,0,0,
        0,138,627,1,0,0,0,140,631,1,0,0,0,142,635,1,0,0,0,144,639,1,0,0,
        0,146,653,1,0,0,0,148,657,1,0,0,0,150,669,1,0,0,0,152,671,1,0,0,
        0,154,679,1,0,0,0,156,681,1,0,0,0,158,683,1,0,0,0,160,685,1,0,0,
        0,162,687,1,0,0,0,164,689,1,0,0,0,166,691,1,0,0,0,168,693,1,0,0,
        0,170,724,1,0,0,0,172,753,1,0,0,0,174,755,1,0,0,0,176,760,1,0,0,
        0,178,764,1,0,0,0,180,766,1,0,0,0,182,774,1,0,0,0,184,776,1,0,0,
        0,186,778,1,0,0,0,188,781,1,0,0,0,190,192,7,0,0,0,191,190,1,0,0,
        0,192,193,1,0,0,0,193,191,1,0,0,0,193,194,1,0,0,0,194,9,1,0,0,0,
        195,197,5,13,0,0,196,195,1,0,0,0,196,197,1,0,0,0,197,198,1,0,0,0,
        198,201,5,10,0,0,199,201,5,13,0,0,200,196,1,0,0,0,200,199,1,0,0,
        0,201,11,1,0,0,0,202,211,7,0,0,0,203,205,5,13,0,0,204,203,1,0,0,
        0,204,205,1,0,0,0,205,206,1,0,0,0,206,209,5,10,0,0,207,209,5,13,
        0,0,208,204,1,0,0,0,208,207,1,0,0,0,209,211,1,0,0,0,210,202,1,0,
        0,0,210,208,1,0,0,0,211,212,1,0,0,0,212,210,1,0,0,0,212,213,1,0,
        0,0,213,13,1,0,0,0,214,215,5,47,0,0,215,216,5,42,0,0,216,220,1,0,
        0,0,217,219,9,0,0,0,218,217,1,0,0,0,219,222,1,0,0,0,220,221,1,0,
        0,0,220,218,1,0,0,0,221,223,1,0,0,0,222,220,1,0,0,0,223,224,5,42,
        0,0,224,225,5,47,0,0,225,15,1,0,0,0,226,228,7,0,0,0,227,226,1,0,
        0,0,228,229,1,0,0,0,229,227,1,0,0,0,229,230,1,0,0,0,230,231,1,0,
        0,0,231,232,6,4,0,0,232,17,1,0,0,0,233,235,5,13,0,0,234,233,1,0,
        0,0,234,235,1,0,0,0,235,236,1,0,0,0,236,239,5,10,0,0,237,239,5,13,
        0,0,238,234,1,0,0,0,238,237,1,0,0,0,239,240,1,0,0,0,240,241,6,5,
        0,0,241,19,1,0,0,0,242,251,5,48,0,0,243,247,7,1,0,0,244,246,7,2,
        0,0,245,244,1,0,0,0,246,249,1,0,0,0,247,245,1,0,0,0,247,248,1,0,
        0,0,248,251,1,0,0,0,249,247,1,0,0,0,250,242,1,0,0,0,250,243,1,0,
        0,0,251,21,1,0,0,0,252,261,5,48,0,0,253,257,7,1,0,0,254,256,7,2,
        0,0,255,254,1,0,0,0,256,259,1,0,0,0,257,255,1,0,0,0,257,258,1,0,
        0,0,258,261,1,0,0,0,259,257,1,0,0,0,260,252,1,0,0,0,260,253,1,0,
        0,0,261,268,1,0,0,0,262,264,5,46,0,0,263,265,7,2,0,0,264,263,1,0,
        0,0,265,266,1,0,0,0,266,264,1,0,0,0,266,267,1,0,0,0,267,269,1,0,
        0,0,268,262,1,0,0,0,268,269,1,0,0,0,269,23,1,0,0,0,270,272,7,2,0,
        0,271,270,1,0,0,0,272,275,1,0,0,0,273,271,1,0,0,0,273,274,1,0,0,
        0,274,276,1,0,0,0,275,273,1,0,0,0,276,277,5,58,0,0,277,279,7,2,0,
        0,278,280,7,2,0,0,279,278,1,0,0,0,279,280,1,0,0,0,280,25,1,0,0,0,
        281,283,7,2,0,0,282,281,1,0,0,0,283,284,1,0,0,0,284,282,1,0,0,0,
        284,285,1,0,0,0,285,287,1,0,0,0,286,288,3,8,0,0,287,286,1,0,0,0,
        287,288,1,0,0,0,288,292,1,0,0,0,289,291,7,3,0,0,290,289,1,0,0,0,
        291,294,1,0,0,0,292,290,1,0,0,0,292,293,1,0,0,0,293,27,1,0,0,0,294,
        292,1,0,0,0,295,296,5,47,0,0,296,29,1,0,0,0,297,299,3,28,10,0,298,
        300,9,0,0,0,299,298,1,0,0,0,300,301,1,0,0,0,301,302,1,0,0,0,301,
        299,1,0,0,0,302,303,1,0,0,0,303,304,3,28,10,0,304,31,1,0,0,0,305,
        306,9,0,0,0,306,33,1,0,0,0,307,308,5,43,0,0,308,35,1,0,0,0,309,310,
        7,4,0,0,310,311,1,0,0,0,311,312,6,14,1,0,312,37,1,0,0,0,313,314,
        7,5,0,0,314,315,5,104,0,0,315,316,5,111,0,0,316,317,5,105,0,0,317,
        318,5,99,0,0,318,319,5,101,0,0,319,320,1,0,0,0,320,321,6,15,2,0,
        321,39,1,0,0,0,322,323,7,6,0,0,323,324,5,112,0,0,324,325,5,116,0,
        0,325,326,5,105,0,0,326,327,5,111,0,0,327,328,5,110,0,0,328,329,
        1,0,0,0,329,330,6,16,2,0,330,41,1,0,0,0,331,332,7,7,0,0,332,355,
        7,8,0,0,333,334,7,8,0,0,334,335,5,111,0,0,335,336,5,114,0,0,336,
        337,5,110,0,0,337,338,5,105,0,0,338,339,5,110,0,0,339,355,5,103,
        0,0,340,341,7,9,0,0,341,355,7,8,0,0,342,343,7,7,0,0,343,344,5,102,
        0,0,344,345,5,116,0,0,345,346,5,101,0,0,346,347,5,114,0,0,347,348,
        5,110,0,0,348,349,5,111,0,0,349,350,5,111,0,0,350,355,5,110,0,0,
        351,352,7,10,0,0,352,353,7,6,0,0,353,355,7,11,0,0,354,331,1,0,0,
        0,354,333,1,0,0,0,354,340,1,0,0,0,354,342,1,0,0,0,354,351,1,0,0,
        0,355,357,1,0,0,0,356,358,5,58,0,0,357,356,1,0,0,0,357,358,1,0,0,
        0,358,43,1,0,0,0,359,360,5,100,0,0,360,361,5,101,0,0,361,362,5,102,
        0,0,362,363,5,105,0,0,363,364,5,110,0,0,364,365,5,101,0,0,365,366,
        1,0,0,0,366,367,6,18,3,0,367,45,1,0,0,0,368,369,5,110,0,0,369,370,
        5,101,0,0,370,371,5,119,0,0,371,372,5,77,0,0,372,373,5,111,0,0,373,
        374,5,118,0,0,374,375,5,101,0,0,375,376,5,109,0,0,376,377,5,101,
        0,0,377,378,5,110,0,0,378,379,5,116,0,0,379,380,1,0,0,0,380,381,
        6,19,4,0,381,47,1,0,0,0,382,383,7,12,0,0,383,384,5,101,0,0,384,385,
        5,115,0,0,385,386,5,116,0,0,386,49,1,0,0,0,387,391,5,34,0,0,388,
        390,9,0,0,0,389,388,1,0,0,0,390,393,1,0,0,0,391,392,1,0,0,0,391,
        389,1,0,0,0,392,394,1,0,0,0,393,391,1,0,0,0,394,395,5,34,0,0,395,
        51,1,0,0,0,396,400,5,96,0,0,397,399,9,0,0,0,398,397,1,0,0,0,399,
        402,1,0,0,0,400,401,1,0,0,0,400,398,1,0,0,0,401,403,1,0,0,0,402,
        400,1,0,0,0,403,404,5,96,0,0,404,53,1,0,0,0,405,406,5,40,0,0,406,
        407,1,0,0,0,407,408,6,23,5,0,408,55,1,0,0,0,409,410,7,7,0,0,410,
        411,5,108,0,0,411,412,5,116,0,0,412,57,1,0,0,0,413,415,7,13,0,0,
        414,413,1,0,0,0,415,416,1,0,0,0,416,414,1,0,0,0,416,417,1,0,0,0,
        417,59,1,0,0,0,418,419,5,123,0,0,419,61,1,0,0,0,420,421,5,125,0,
        0,421,63,1,0,0,0,422,423,5,58,0,0,423,65,1,0,0,0,424,428,5,42,0,
        0,425,427,8,14,0,0,426,425,1,0,0,0,427,430,1,0,0,0,428,426,1,0,0,
        0,428,429,1,0,0,0,429,431,1,0,0,0,430,428,1,0,0,0,431,432,3,10,1,
        0,432,67,1,0,0,0,433,434,3,14,3,0,434,435,1,0,0,0,435,436,6,30,6,
        0,436,69,1,0,0,0,437,441,7,15,0,0,438,440,7,16,0,0,439,438,1,0,0,
        0,440,443,1,0,0,0,441,439,1,0,0,0,441,442,1,0,0,0,442,444,1,0,0,
        0,443,441,1,0,0,0,444,445,6,31,5,0,445,71,1,0,0,0,446,448,7,2,0,
        0,447,446,1,0,0,0,448,449,1,0,0,0,449,447,1,0,0,0,449,450,1,0,0,
        0,450,451,1,0,0,0,451,452,6,32,7,0,452,73,1,0,0,0,453,454,3,32,12,
        0,454,75,1,0,0,0,455,456,3,12,2,0,456,457,1,0,0,0,457,458,6,34,0,
        0,458,77,1,0,0,0,459,460,5,40,0,0,460,79,1,0,0,0,461,462,5,41,0,
        0,462,463,1,0,0,0,463,464,6,36,8,0,464,81,1,0,0,0,465,466,5,58,0,
        0,466,83,1,0,0,0,467,468,5,44,0,0,468,85,1,0,0,0,469,470,5,34,0,
        0,470,87,1,0,0,0,471,475,5,34,0,0,472,474,9,0,0,0,473,472,1,0,0,
        0,474,477,1,0,0,0,475,476,1,0,0,0,475,473,1,0,0,0,476,478,1,0,0,
        0,477,475,1,0,0,0,478,479,5,34,0,0,479,89,1,0,0,0,480,481,5,34,0,
        0,481,482,5,34,0,0,482,483,5,34,0,0,483,487,1,0,0,0,484,486,9,0,
        0,0,485,484,1,0,0,0,486,489,1,0,0,0,487,488,1,0,0,0,487,485,1,0,
        0,0,488,490,1,0,0,0,489,487,1,0,0,0,490,491,5,34,0,0,491,492,5,34,
        0,0,492,493,5,34,0,0,493,91,1,0,0,0,494,495,3,24,8,0,495,93,1,0,
        0,0,496,497,3,22,7,0,497,95,1,0,0,0,498,499,3,26,9,0,499,97,1,0,
        0,0,500,504,7,16,0,0,501,503,7,17,0,0,502,501,1,0,0,0,503,506,1,
        0,0,0,504,502,1,0,0,0,504,505,1,0,0,0,505,99,1,0,0,0,506,504,1,0,
        0,0,507,508,3,12,2,0,508,509,1,0,0,0,509,510,6,46,0,0,510,101,1,
        0,0,0,511,515,3,58,25,0,512,514,3,58,25,0,513,512,1,0,0,0,514,517,
        1,0,0,0,515,513,1,0,0,0,515,516,1,0,0,0,516,518,1,0,0,0,517,515,
        1,0,0,0,518,519,6,47,8,0,519,103,1,0,0,0,520,522,7,18,0,0,521,523,
        3,116,54,0,522,521,1,0,0,0,523,524,1,0,0,0,524,522,1,0,0,0,524,525,
        1,0,0,0,525,105,1,0,0,0,526,528,7,2,0,0,527,526,1,0,0,0,528,529,
        1,0,0,0,529,527,1,0,0,0,529,530,1,0,0,0,530,532,1,0,0,0,531,533,
        7,0,0,0,532,531,1,0,0,0,533,534,1,0,0,0,534,532,1,0,0,0,534,535,
        1,0,0,0,535,537,1,0,0,0,536,527,1,0,0,0,536,537,1,0,0,0,537,538,
        1,0,0,0,538,542,7,19,0,0,539,541,7,20,0,0,540,539,1,0,0,0,541,544,
        1,0,0,0,542,540,1,0,0,0,542,543,1,0,0,0,543,107,1,0,0,0,544,542,
        1,0,0,0,545,549,3,20,6,0,546,548,7,21,0,0,547,546,1,0,0,0,548,551,
        1,0,0,0,549,547,1,0,0,0,549,550,1,0,0,0,550,552,1,0,0,0,551,549,
        1,0,0,0,552,553,7,18,0,0,553,109,1,0,0,0,554,555,5,43,0,0,555,111,
        1,0,0,0,556,558,3,20,6,0,557,556,1,0,0,0,557,558,1,0,0,0,558,559,
        1,0,0,0,559,560,5,120,0,0,560,569,3,106,49,0,561,563,5,43,0,0,562,
        564,3,20,6,0,563,562,1,0,0,0,563,564,1,0,0,0,564,565,1,0,0,0,565,
        566,5,120,0,0,566,568,3,106,49,0,567,561,1,0,0,0,568,571,1,0,0,0,
        569,567,1,0,0,0,569,570,1,0,0,0,570,113,1,0,0,0,571,569,1,0,0,0,
        572,573,7,22,0,0,573,574,1,0,0,0,574,575,6,53,9,0,575,115,1,0,0,
        0,576,577,3,8,0,0,577,578,1,0,0,0,578,579,6,54,0,0,579,117,1,0,0,
        0,580,582,7,0,0,0,581,580,1,0,0,0,582,583,1,0,0,0,583,581,1,0,0,
        0,583,584,1,0,0,0,584,585,1,0,0,0,585,586,6,55,0,0,586,119,1,0,0,
        0,587,589,7,2,0,0,588,587,1,0,0,0,589,590,1,0,0,0,590,588,1,0,0,
        0,590,591,1,0,0,0,591,598,1,0,0,0,592,594,5,46,0,0,593,595,7,2,0,
        0,594,593,1,0,0,0,595,596,1,0,0,0,596,594,1,0,0,0,596,597,1,0,0,
        0,597,599,1,0,0,0,598,592,1,0,0,0,598,599,1,0,0,0,599,121,1,0,0,
        0,600,601,5,77,0,0,601,123,1,0,0,0,602,603,3,24,8,0,603,125,1,0,
        0,0,604,605,5,44,0,0,605,127,1,0,0,0,606,607,5,120,0,0,607,129,1,
        0,0,0,608,609,5,64,0,0,609,131,1,0,0,0,610,611,7,23,0,0,611,612,
        5,110,0,0,612,133,1,0,0,0,613,614,7,24,0,0,614,615,5,111,0,0,615,
        616,5,114,0,0,616,135,1,0,0,0,617,619,5,13,0,0,618,617,1,0,0,0,618,
        619,1,0,0,0,619,620,1,0,0,0,620,623,5,10,0,0,621,623,5,13,0,0,622,
        618,1,0,0,0,622,621,1,0,0,0,623,624,1,0,0,0,624,625,6,64,10,0,625,
        137,1,0,0,0,626,628,8,25,0,0,627,626,1,0,0,0,628,629,1,0,0,0,629,
        627,1,0,0,0,629,630,1,0,0,0,630,139,1,0,0,0,631,632,3,32,12,0,632,
        633,1,0,0,0,633,634,6,66,11,0,634,141,1,0,0,0,635,636,3,8,0,0,636,
        637,1,0,0,0,637,638,6,67,0,0,638,143,1,0,0,0,639,640,7,26,0,0,640,
        641,5,101,0,0,641,642,5,116,0,0,642,643,5,115,0,0,643,649,1,0,0,
        0,644,646,3,8,0,0,645,644,1,0,0,0,645,646,1,0,0,0,646,647,1,0,0,
        0,647,648,5,111,0,0,648,650,5,102,0,0,649,645,1,0,0,0,649,650,1,
        0,0,0,650,651,1,0,0,0,651,652,6,68,8,0,652,145,1,0,0,0,653,654,3,
        12,2,0,654,655,1,0,0,0,655,656,6,69,0,0,656,147,1,0,0,0,657,658,
        5,47,0,0,658,659,5,42,0,0,659,663,1,0,0,0,660,662,9,0,0,0,661,660,
        1,0,0,0,662,665,1,0,0,0,663,664,1,0,0,0,663,661,1,0,0,0,664,666,
        1,0,0,0,665,663,1,0,0,0,666,667,5,42,0,0,667,668,5,47,0,0,668,149,
        1,0,0,0,669,670,3,30,11,0,670,151,1,0,0,0,671,673,5,34,0,0,672,674,
        7,17,0,0,673,672,1,0,0,0,674,675,1,0,0,0,675,673,1,0,0,0,675,676,
        1,0,0,0,676,677,1,0,0,0,677,678,5,34,0,0,678,153,1,0,0,0,679,680,
        5,34,0,0,680,155,1,0,0,0,681,682,5,58,0,0,682,157,1,0,0,0,683,684,
        5,63,0,0,684,159,1,0,0,0,685,686,5,44,0,0,686,161,1,0,0,0,687,688,
        5,40,0,0,688,163,1,0,0,0,689,690,5,41,0,0,690,165,1,0,0,0,691,692,
        5,123,0,0,692,167,1,0,0,0,693,694,5,125,0,0,694,695,1,0,0,0,695,
        696,6,80,8,0,696,169,1,0,0,0,697,698,5,98,0,0,698,699,5,97,0,0,699,
        700,5,115,0,0,700,725,5,101,0,0,701,702,5,112,0,0,702,703,5,114,
        0,0,703,704,5,111,0,0,704,705,5,103,0,0,705,706,5,114,0,0,706,707,
        5,101,0,0,707,708,5,115,0,0,708,709,5,115,0,0,709,710,5,105,0,0,
        710,711,5,110,0,0,711,725,5,103,0,0,712,713,5,110,0,0,713,714,5,
        111,0,0,714,715,5,95,0,0,715,716,5,109,0,0,716,717,5,111,0,0,717,
        718,5,118,0,0,718,719,5,101,0,0,719,720,5,109,0,0,720,721,5,101,
        0,0,721,722,5,110,0,0,722,723,5,116,0,0,723,725,5,115,0,0,724,697,
        1,0,0,0,724,701,1,0,0,0,724,712,1,0,0,0,725,171,1,0,0,0,726,727,
        5,105,0,0,727,728,5,110,0,0,728,754,5,116,0,0,729,730,5,100,0,0,
        730,731,5,111,0,0,731,732,5,117,0,0,732,733,5,98,0,0,733,734,5,108,
        0,0,734,754,5,101,0,0,735,736,5,115,0,0,736,737,5,116,0,0,737,738,
        5,114,0,0,738,739,5,105,0,0,739,740,5,110,0,0,740,754,5,103,0,0,
        741,742,5,119,0,0,742,743,5,111,0,0,743,744,5,114,0,0,744,745,5,
        107,0,0,745,746,5,111,0,0,746,747,5,117,0,0,747,748,5,116,0,0,748,
        754,5,115,0,0,749,750,5,116,0,0,750,751,5,105,0,0,751,752,5,109,
        0,0,752,754,5,101,0,0,753,726,1,0,0,0,753,729,1,0,0,0,753,735,1,
        0,0,0,753,741,1,0,0,0,753,749,1,0,0,0,754,173,1,0,0,0,755,756,5,
        101,0,0,756,757,5,110,0,0,757,758,5,117,0,0,758,759,5,109,0,0,759,
        175,1,0,0,0,760,761,3,12,2,0,761,762,1,0,0,0,762,763,6,84,0,0,763,
        177,1,0,0,0,764,765,5,34,0,0,765,179,1,0,0,0,766,768,5,34,0,0,767,
        769,7,17,0,0,768,767,1,0,0,0,769,770,1,0,0,0,770,768,1,0,0,0,770,
        771,1,0,0,0,771,772,1,0,0,0,772,773,5,34,0,0,773,181,1,0,0,0,774,
        775,5,58,0,0,775,183,1,0,0,0,776,777,5,44,0,0,777,185,1,0,0,0,778,
        779,5,59,0,0,779,187,1,0,0,0,780,782,7,27,0,0,781,780,1,0,0,0,782,
        783,1,0,0,0,783,781,1,0,0,0,783,784,1,0,0,0,784,189,1,0,0,0,67,0,
        1,2,3,4,5,6,7,193,196,200,204,208,210,212,220,229,234,238,247,250,
        257,260,266,268,273,279,284,287,292,301,354,357,391,400,416,428,
        441,449,475,487,504,515,524,529,534,536,542,549,557,563,569,583,
        590,596,598,618,622,629,645,649,663,675,724,753,770,783,12,0,2,0,
        2,3,0,5,2,0,5,6,0,5,7,0,5,1,0,0,4,0,5,5,0,4,0,0,2,4,0,2,0,0,0,3,
        0
    ];

    private static __ATN: antlr.ATN;
    public static get _ATN(): antlr.ATN {
        if (!UniversalWorkoutLanguageLexer.__ATN) {
            UniversalWorkoutLanguageLexer.__ATN = new antlr.ATNDeserializer().deserialize(UniversalWorkoutLanguageLexer._serializedATN);
        }

        return UniversalWorkoutLanguageLexer.__ATN;
    }


    private static readonly vocabulary = new antlr.Vocabulary(UniversalWorkoutLanguageLexer.literalNames, UniversalWorkoutLanguageLexer.symbolicNames, []);

    public override get vocabulary(): antlr.Vocabulary {
        return UniversalWorkoutLanguageLexer.vocabulary;
    }

    private static readonly decisionsToDFA = UniversalWorkoutLanguageLexer._ATN.decisionToState.map( (ds: antlr.DecisionState, index: number) => new antlr.DFA(ds, index) );
}
export const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      height: '80vh',
      width: '80vw',
      maxWidth: 'none !important',
      maxHeight: 'none',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    height: 'calc(100% - 32px)',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    overflow: 'hidden',
    marginTop: '20px',
  },
  editPaper: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: '4px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  previewPaper: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: '4px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  editorBox: {
    flex: 1,
    overflow: 'hidden',
    marginTop: '10px',
  },
  previewBox: {
    width: '100%',
    flex: 1,
    overflow: 'auto',
    marginTop: '10px',
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  divider: {
    height: 'auto',
    margin: '0 20px',
  },
  dialogTitle: {
    padding: '15px 20px 0px 20px',
  },
  debugButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  debugWindow: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '300px',
    height: '100%',
    backgroundColor: '#f5f5f5',
    padding: '20px',
    boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
    overflowY: 'auto',
    zIndex: 1000,
  },
  debugOutput: {
    whiteSpace: 'pre-wrap',
    fontFamily: 'monospace',
    fontSize: '12px',
  },
};

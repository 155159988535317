import { Navigate, Outlet } from 'react-router-dom';

import { tokenService } from '../../services/tokenService';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = () => {
  const { token } = useAuth();
  if (!token || tokenService.isTokenExpired(token)) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};

export default PrivateRoute;

import { Movement } from '../../../../@monaco-uwl/UWLVisitor/Movement';
import { RestType } from '../../../../wodupParser/wodup.types';
import { MovementDefaults } from '../../WodUpPreview.types';
import { formatSecondsToTime } from '../utils/timeUtils';

export const areArrayValuesEqual = (arr: (string | number)[]): boolean =>
  arr.length > 0 && arr.every((val) => val === arr[0]);

export const areAllSetsEqual = (attributes: Record<string, (string | number)[]>): boolean => {
  const nonEmptyArrays = Object.values(attributes).filter((arr) => arr && arr.length > 0);
  if (nonEmptyArrays.length === 0) return true;
  return nonEmptyArrays.every((arr) => areArrayValuesEqual(arr));
};

export const getMovementName = (
  movement: Movement,
  hasUniformTempo: boolean,
  tempo: string[],
): string => {
  if (!movement?.names?.length) return MovementDefaults.UNNAMED_MOVEMENT;
  const baseName = movement.names.join(' + ');
  return hasUniformTempo ? `${baseName} (${tempo[0]})` : baseName;
};

export const formatRestType = (type: RestType, duration?: number): string => {
  switch (type) {
    case RestType.AsNeeded:
      return 'As needed';
    case RestType.Fixed:
      return duration ? formatSecondsToTime(duration) : '00:00';
    case RestType.Superset:
      return 'Superset (no rest)';
    case RestType.Ratio:
      return `Rest ${duration || 1} times the set's duration`;
    case RestType.Emom:
      return `On a running clock, start a set every ${duration ? formatSecondsToTime(duration) : '00:00'}`;
    default:
      return 'Not specified';
  }
};

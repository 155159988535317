import { Monaco } from '@monaco-editor/react';
import { Typography, Box, Paper, Grid } from '@mui/material';
import { editor, IPosition } from 'monaco-editor';
import React, { useState, useCallback, useEffect, useRef } from 'react';

import MonacoEditor from '../../@monaco-uwl/MonacoEditor';
import { validate } from '../../@monaco-uwl/UWLErrorHandler';
import { Workout } from '../../@monaco-uwl/UWLVisitor/Workout';
import { logger } from '../../Logger';
import ExpandedViewDialog from '../ExpandedViewDialog/ExpandedViewDialog';

import { headerStyles, contentStyles, monacoMimicStyles } from './Day.styles';
import { DayProps, MonacoDayContentProps } from './Day.types';

const DayHeader: React.FC<DayProps> = ({ weekNumber, dayNumber, isFirstInRow }) => (
  <Box sx={headerStyles.wrapper}>
    <Typography variant="subtitle2" sx={headerStyles.text}>
      {isFirstInRow ? `W ${weekNumber}` : ''}
    </Typography>
    <Typography variant="subtitle2" sx={headerStyles.dayText}>
      Day {dayNumber}
    </Typography>
  </Box>
);

const MonacoDayContent: React.FC<MonacoDayContentProps> = ({
  content,
  isEditing,
  isFocused,
  onContentChange,
  onDoubleClick,
  onEditorDidMount,
}) => (
  <Box
    sx={{
      ...contentStyles.wrapper,
      height: '250px',
    }}
    onDoubleClick={onDoubleClick}
  >
    {isFocused ? (
      <MonacoEditor
        value={content}
        fontSize={12}
        onChange={(value: string | undefined) => onContentChange(value ?? '')}
        isEditing={isEditing}
        onMount={onEditorDidMount}
      />
    ) : (
      <pre style={monacoMimicStyles}>{content}</pre>
    )}
  </Box>
);

const Day: React.FC<DayProps> = ({ initialContent = '', onContentChange, ...props }) => {
  const [content, setContent] = useState(initialContent);
  const [uwlContent, setUwlContent] = useState<Workout[][]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(0);
  const DOUBLE_CLICK_THRESHOLD = 300;
  const dayRef = useRef<HTMLDivElement>(null);
  const instanceRef = useRef<Monaco | null>(null);
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
  const expandedInstanceRef = useRef<Monaco | null>(null);
  const expandedEditorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
  const [cursorPosition, setCursorPosition] = useState<IPosition | null>(null);

  useEffect(() => {
    setContent(initialContent);
  }, [initialContent]);

  const handleContentChange = useCallback(
    (newContent: string) => {
      setContent(newContent);
      setIsDialogOpen(true);

      const validationResult = validate(newContent);
      setUwlContent(validationResult.wods);

      instanceRef.current?.editor.setModelMarkers(
        editorRef.current!.getModel()!,
        'Owner',
        validationResult.errors,
      );

      onContentChange?.(newContent);
    },
    [onContentChange],
  );

  const handleEditorDidMount = (
    editor: editor.IStandaloneCodeEditor,
    monacoInstance: Monaco,
  ): void => {
    logger.debug(editor.getModel()?.uri || null);

    editorRef.current = editor;
    instanceRef.current = monacoInstance;

    logger.debug(editorRef.current);
    logger.debug(editorRef.current.getModel()?.uri || 'NOP');

    editor.onDidChangeCursorPosition((e) => {
      setCursorPosition(e.position);
    });

    editor.onDidContentSizeChange(() => {
      editor.layout();
    });
  };

  const handleClick = () => {
    const currentTime = new Date().getTime();
    if (currentTime - lastClickTime < DOUBLE_CLICK_THRESHOLD) {
      handleDoubleClick();
    } else {
      handleSingleClick();
    }
    setLastClickTime(currentTime);
  };

  const handleSingleClick = () => {
    setIsEditing(true);
    setIsFocused(true);
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
    setIsFocused(true);
    setIsDialogOpen(true);
  };

  const handleEscapeKey = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsEditing(false);
      setIsFocused(false);
    }
  }, []);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (dayRef.current && !dayRef.current.contains(event.target as Node)) {
      setIsEditing(false);
      setIsFocused(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handleClickOutside, handleEscapeKey]);

  const handleExpandedContentChange = useCallback(
    (newContent: string) => {
      setContent(newContent);
      newContent += '\n';
      const c = validate(newContent);
      setUwlContent(c.wods);

      logger.debug('===>> ', c.errors);

      expandedInstanceRef.current?.editor.setModelMarkers(
        expandedEditorRef.current!.getModel()!,
        'Owner',
        c.errors,
      );

      onContentChange?.(newContent);
    },
    [onContentChange],
  );

  const handleExpandedEditorDidMount = (
    editor: editor.IStandaloneCodeEditor,
    monacoInstance: Monaco,
  ): void => {
    expandedEditorRef.current = editor;
    expandedInstanceRef.current = monacoInstance;

    logger.debug(expandedEditorRef.current);

    editor.onDidContentSizeChange(() => {
      editor.layout();
    });
  };

  return (
    <Grid item xs={12} sm={6} md={1.7142} ref={dayRef}>
      <Paper
        elevation={0}
        sx={{
          bgcolor: 'background.paper',
          borderRight: 1,
          borderBottom: 1,
          borderColor: 'grey.300',
          borderRadius: 0,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
        onClick={handleClick}
      >
        <DayHeader {...props} />
        <MonacoDayContent
          content={content}
          isEditing={isEditing}
          isFocused={isFocused}
          onContentChange={handleContentChange}
          onDoubleClick={handleDoubleClick}
          onEditorDidMount={handleEditorDidMount}
        />
      </Paper>
      <ExpandedViewDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        content={content}
        onContentChange={handleExpandedContentChange}
        onEditorDidMount={handleExpandedEditorDidMount}
        weekNumber={props.weekNumber}
        dayNumber={props.dayNumber}
        UwlContent={uwlContent}
        initialCursorPosition={cursorPosition}
      />
    </Grid>
  );
};

export default Day;

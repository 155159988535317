import { Box, Typography } from '@mui/material';
import React from 'react';

import { GenericComponent, SessionType } from '../../../generalParser/parser.types';
import { styles } from '../WodUpPreview.styles';

import { formatSecondsToTime } from './utils/timeUtils';

const parseDescription = (description: string) => {
  const lines = description.split('\n');
  return lines.map((line, index) => {
    const cleanedLine = line.replace(/ /g, '\u00A0');

    if (cleanedLine.trim() === '') {
      return <br key={index} />;
    }
    return <span key={index}>{cleanedLine}<br /></span>;
  });
};

export const GenericPreview: React.FC<{
  component: GenericComponent;
  sessionType: SessionType;
}> = ({ component, sessionType }) => {
  return (
    <Box
      sx={{
        ...styles.genericComponent,
        ...(sessionType === SessionType.AM ? styles.amComponent : styles.pmComponent),
      }}
    >
      <Box sx={styles.genericHeaderWrapper}>
        <Box sx={sessionType === SessionType.AM ? styles.headerLineAM : styles.headerLinePM} />
        <Typography sx={styles.genericHeader}>
          {`${component.prefix || ''}${component.prefix ? '. ' : ''}${
            component.name || 'Unnamed Component'
          }`}
        </Typography>
      </Box>

      <Box sx={styles.contentWrapper}>
        <Typography sx={styles.sectionTitle}>
          Description <span style={styles.requiredIndicator}>*</span>
        </Typography>
        <Typography sx={{ ...styles.sectionContent, marginTop: '-10px', marginLeft: '-40px'}}>
          {component.description ? (
            <Box component="ul" sx={styles.sectionContent}>
              {parseDescription(component.description)}
            </Box>
          ) : (
            <Box component="ul" sx={styles.sectionContent }>
              {'No description provided'}
            </Box>
          )}
        </Typography>

        <Typography sx={styles.sectionTitle}>
          Score <span style={styles.requiredIndicator}>*</span>
        </Typography>
        <Typography sx={styles.sectionContent}>{component.score_by || 'Not specified'}</Typography>

        <Typography sx={styles.sectionTitle}>Movements:</Typography>
        <Box sx={styles.sectionContent}>
          {component.movements && component.movements.length > 0 ? (
            component.movements.map((movement: string, index: number) => (
              <Box key={index} sx={styles.movementItem}>
                <Box
                  sx={
                    sessionType === SessionType.AM
                      ? styles.movementBulletAM
                      : styles.movementBulletPM
                  }
                />
                <Typography sx={{ fontSize: '0.8rem' }}>{movement}</Typography>
              </Box>
            ))
          ) : (
            <Typography sx={{ fontSize: '0.8rem' }}>No movements specified</Typography>
          )}
        </Box>

        <Typography sx={styles.sectionTitle}>Time cap</Typography>
        <Typography sx={styles.sectionContent}>
          {formatSecondsToTime(component.time_cap)}
        </Typography>

        <Typography sx={styles.sectionTitle}>Notes</Typography>
        <Typography sx={styles.sectionContent}>
          {component.notes || 'No additional notes'}
        </Typography>
      </Box>
    </Box>
  );
};

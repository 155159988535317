import { Dict, WorkoutAttrTypes } from './types';

export const PATTERNS = {
  INT: /[0-9]+/,
  MAX: /[Mm]/,
  TEMPO: /^[0-9AX]{1,4}$/,
  FLOAT: /[0-9]+|([0-9]*\.[0-9]*)/,
  TIME: /[0-9]*:[0-9][0-9]?/,
  TIME_EXT: /[0-9]+(\.[0-9]+)?[^@ ,\t\n\r]+/,
};

export const workoutDefs: Dict<Dict<WorkoutAttrTypes>> = {
  generic: {
    score_by: WorkoutAttrTypes.String,
    description: WorkoutAttrTypes.String,
    time_cap: WorkoutAttrTypes.Time | WorkoutAttrTypes.Optional,
  },
  strength: {
    rest: WorkoutAttrTypes.String,
    time_cap: WorkoutAttrTypes.Time | WorkoutAttrTypes.Optional,
  },
  warmup: {
    description: WorkoutAttrTypes.String,
  },
};

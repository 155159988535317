import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Paper,
  Grid,
} from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import React, { useState } from 'react';

const workoutTypeData = {
  2022: [{ numberOfWodsAnalyzed: 1966, numberOfComponentsAnalyzed: 8091 }],
  2023: [{ numberOfWodsAnalyzed: 1562, numberOfComponentsAnalyzed: 6465 }],
  2024: [{ numberOfWodsAnalyzed: 1470, numberOfComponentsAnalyzed: 6223 }],
  ALL: [{ numberOfWodsAnalyzed: 4998, numberOfComponentsAnalyzed: 20779 }],
};

const restTypeData = {
  2022: [{ numberOfComponentsAnalyzed: 3424 }],
  2023: [{ numberOfComponentsAnalyzed: 2706 }],
  2024: [{ numberOfComponentsAnalyzed: 2487 }],
  ALL: [{ numberOfComponentsAnalyzed: 8617 }],
};

const scoreByData = {
  2022: [{ numberOfGenericWorkoutsAnalyzed: 2462 }],
  2023: [{ numberOfGenericWorkoutsAnalyzed: 2449 }],
  2024: [{ numberOfGenericWorkoutsAnalyzed: 2780 }],
  ALL: [{ numberOfGenericWorkoutsAnalyzed: 7691 }],
};

const requestFieldsData = {
  2022: [{ numberOfWodsAnalyzed: 1966, numberOfComponentsAnalyzed: 8091 }],
  2023: [{ numberOfWodsAnalyzed: 1562, numberOfComponentsAnalyzed: 6465 }],
  2024: [{ numberOfWodsAnalyzed: 1470, numberOfComponentsAnalyzed: 6223 }],
  ALL: [{ numberOfWodsAnalyzed: 4998, numberOfComponentsAnalyzed: 20779 }],
};

// Mock data for each graph type
const hardcodedData = {
  'Request fields graph': {
    2022: [
      { value: 998, label: 'preInstructions' },
      { value: 330, label: 'postInstructions' },
      { value: 0, label: 'partners' },
      { value: 0, label: 'notesForCoaches' },
      { value: 3446, label: 'notes' },
      { value: 199, label: 'name' },
    ],
    2023: [
      { value: 407, label: 'preInstructions' },
      { value: 216, label: 'postInstructions' },
      { value: 0, label: 'partners' },
      { value: 20, label: 'notesForCoaches' },
      { value: 3123, label: 'notes' },
      { value: 487, label: 'name' },
    ],
    2024: [
      { value: 444, label: 'preInstructions' },
      { value: 125, label: 'postInstructions' },
      { value: 0, label: 'partners' },
      { value: 308, label: 'notesForCoaches' },
      { value: 2952, label: 'notes' },
      { value: 1477, label: 'name' },
    ],
  },
  'Rest types graph': {
    2022: [
      { value: 0, label: 'superset' },
      { value: 1, label: 'ratio' },
      { value: 245, label: 'emom' },
      { value: 1359, label: 'as_needed' },
      { value: 1819, label: 'fixed' },
    ],
    2023: [
      { value: 0, label: 'superset' },
      { value: 0, label: 'ratio' },
      { value: 197, label: 'emom' },
      { value: 720, label: 'as_needed' },
      { value: 1789, label: 'fixed' },
    ],
    2024: [
      { value: 0, label: 'superset' },
      { value: 0, label: 'ratio' },
      { value: 236, label: 'emom' },
      { value: 588, label: 'as_needed' },
      { value: 1663, label: 'fixed' },
    ],
  },
  'Score by graph': {
    2022: [
      { value: 32, label: 'points_desc' },
      { value: 1, label: 'points_asc' },
      { value: 0, label: 'max_speed_desc' },
      { value: 0, label: 'peak_watts_desc' },
      { value: 65, label: 'weight_desc' },
      { value: 0, label: 'weight_asc' },
      { value: 18, label: 'distance_desc' },
      { value: 0, label: 'distance_asc' },
      { value: 34, label: 'rounds_reps_desc' },
      { value: 0, label: 'rounds_reps_asc' },
      { value: 125, label: 'reps_desc' },
      { value: 0, label: 'reps_asc' },
      { value: 12, label: 'time_desc' },
      { value: 204, label: 'time_asc' },
      { value: 1971, label: 'none' },
    ],
    2023: [
      { value: 34, label: 'points_desc' },
      { value: 0, label: 'points_asc' },
      { value: 0, label: 'max_speed_desc' },
      { value: 6, label: 'peak_watts_desc' },
      { value: 28, label: 'weight_desc' },
      { value: 0, label: 'weight_asc' },
      { value: 34, label: 'distance_desc' },
      { value: 0, label: 'distance_asc' },
      { value: 31, label: 'rounds_reps_desc' },
      { value: 0, label: 'rounds_reps_asc' },
      { value: 190, label: 'reps_desc' },
      { value: 0, label: 'reps_asc' },
      { value: 12, label: 'time_desc' },
      { value: 269, label: 'time_asc' },
      { value: 1845, label: 'none' },
    ],
    2024: [
      { value: 64, label: 'points_desc' },
      { value: 0, label: 'points_asc' },
      { value: 0, label: 'max_speed_desc' },
      { value: 2, label: 'peak_watts_desc' },
      { value: 35, label: 'weight_desc' },
      { value: 0, label: 'weight_asc' },
      { value: 46, label: 'distance_desc' },
      { value: 0, label: 'distance_asc' },
      { value: 48, label: 'rounds_reps_desc' },
      { value: 0, label: 'rounds_reps_asc' },
      { value: 325, label: 'reps_desc' },
      { value: 0, label: 'reps_asc' },
      { value: 2, label: 'time_desc' },
      { value: 294, label: 'time_asc' },
      { value: 1964, label: 'none' },
    ],
  },
  'Workout types graph': {
    2022: [
      { value: 936, label: 'WarmUp' },
      { value: 0, label: 'TwelveDays' },
      { value: 5, label: 'Tabata' },
      { value: 3381, label: 'Strength' },
      { value: 207, label: 'RoundsForTime' },
      { value: 6, label: 'MaxReps' },
      { value: 2462, label: 'Generic' },
      { value: 67, label: 'FranStyle' },
      { value: 259, label: 'ForTime' },
      { value: 9, label: 'Fgb' },
      { value: 361, label: 'Emom' },
      { value: 0, label: 'DeathBy' },
      { value: 8, label: 'Cardio' },
      { value: 43, label: 'CardioIntervals' },
      { value: 347, label: 'Amrap' },
    ],
    2023: [
      { value: 347, label: 'WarmUp' },
      { value: 0, label: 'TwelveDays' },
      { value: 2, label: 'Tabata' },
      { value: 2703, label: 'Strength' },
      { value: 124, label: 'RoundsForTime' },
      { value: 6, label: 'MaxReps' },
      { value: 2449, label: 'Generic' },
      { value: 32, label: 'FranStyle' },
      { value: 196, label: 'ForTime' },
      { value: 0, label: 'Fgb' },
      { value: 367, label: 'Emom' },
      { value: 2, label: 'DeathBy' },
      { value: 4, label: 'Cardio' },
      { value: 3, label: 'CardioIntervals' },
      { value: 230, label: 'Amrap' },
    ],
    2024: [
      { value: 30, label: 'WarmUp' },
      { value: 0, label: 'TwelveDays' },
      { value: 0, label: 'Tabata' },
      { value: 2485, label: 'Strength' },
      { value: 218, label: 'RoundsForTime' },
      { value: 0, label: 'MaxReps' },
      { value: 2780, label: 'Generic' },
      { value: 32, label: 'FranStyle' },
      { value: 188, label: 'ForTime' },
      { value: 0, label: 'Fgb' },
      { value: 332, label: 'Emom' },
      { value: 0, label: 'DeathBy' },
      { value: 0, label: 'Cardio' },
      { value: 2, label: 'CardioIntervals' },
      { value: 156, label: 'Amrap' },
    ],
  },
  'Generic distribution graph': {
    2024: [
      { value: 2780, label: 'Number of Generic Workouts' },
      { value: 1964, label: 'Number of Generic Workouts with scoreBy: none' },
      { value: 816, label: 'Number of Generic Workouts with (set) scoreBy' },
    ],
  },
  'Generic distribution after': {
    2024: [
      { value: 776, label: 'WarmUp' },
      { value: 27, label: 'EMOM' },
      { value: 59, label: 'AMRAP' },
      { value: 58, label: 'FOR TIME' },
      { value: 26, label: 'ROUNDS FOR TIME' },
      { value: 6, label: 'CARDIO' },
      { value: 6, label: 'TABATA' },
      { value: 490, label: 'CANDIDATES' },
      { value: 516, label: 'GENERIC' },
    ],
  },
};

const graphTypes = [
  'Request fields graph',
  'Rest types graph',
  'Score by graph',
  'Workout types graph',
  'Generic distribution',
];
const years = ['2022', '2023', '2024', 'ALL'];

const Graphs: React.FC = () => {
  const [selectedGraphType, setSelectedGraphType] = useState(graphTypes[0]);
  const [viewMode, setViewMode] = useState<'all' | 'single'>('all');
  const [selectedYear, setSelectedYear] = useState('2024');

  const handleGraphTypeChange = (event: SelectChangeEvent) => {
    setSelectedGraphType(event.target.value);
  };

  const handleViewModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: 'all' | 'single',
  ) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const handleYearChange = (event: SelectChangeEvent) => {
    setSelectedYear(event.target.value);
  };

  const getDataForGraph = (year: string) => {
    if (year === 'ALL') {
      const allYearsData = Object.values(
        hardcodedData[selectedGraphType as keyof typeof hardcodedData],
      );
      const combinedData: { [key: string]: number } = {};

      allYearsData.forEach((yearData) => {
        yearData.forEach((item) => {
          if (combinedData[item.label]) {
            combinedData[item.label] += item.value;
          } else {
            combinedData[item.label] = item.value;
          }
        });
      });

      return Object.entries(combinedData).map(([label, value]) => ({
        label: `(${value}) ${label}`,
        value,
      }));
    }
    return (
      hardcodedData[selectedGraphType as keyof typeof hardcodedData] as Record<
        string,
        { value: number; label: string }[]
      >
    )[year].map((item: { value: number; label: string }) => ({
      ...item,
      label: `(${item.value}) ${item.label}`,
    }));
  };

  const renderAdditionalInfo = (year: string) => {
    let data;
    switch (selectedGraphType) {
      case 'Request fields graph':
        data = requestFieldsData[year as '2022' | '2023' | '2024'][0];
        return (
          <Paper elevation={3} sx={{ p: 2, ml: 2, width: 340 }}>
            <Typography variant="h6">Additional Info</Typography>
            <Typography>Number of WODs Analyzed: {data.numberOfWodsAnalyzed}</Typography>
            <Typography>
              Number of Components Analyzed: {data.numberOfComponentsAnalyzed}
            </Typography>
          </Paper>
        );
      case 'Rest types graph':
        data = restTypeData[year as '2022' | '2023' | '2024'][0];
        return (
          <Paper elevation={3} sx={{ p: 2, ml: 2, width: 340 }}>
            <Typography variant="h6">Additional Info</Typography>
            <Typography>
              Number of Components Analyzed: {data.numberOfComponentsAnalyzed}
            </Typography>
          </Paper>
        );
      case 'Score by graph':
        data = scoreByData[year as '2022' | '2023' | '2024'][0];
        return (
          <Paper elevation={3} sx={{ p: 2, ml: 2, width: 340 }}>
            <Typography variant="h6">Additional Info</Typography>
            <Typography>
              Number of Generic Workouts Analyzed: {data.numberOfGenericWorkoutsAnalyzed}
            </Typography>
          </Paper>
        );
      case 'Workout types graph':
        data = workoutTypeData[year as '2022' | '2023' | '2024'][0];
        return (
          <Paper elevation={3} sx={{ p: 2, ml: 2, width: 340 }}>
            <Typography variant="h6">Additional Info</Typography>
            <Typography>Number of WODs Analyzed: {data.numberOfWodsAnalyzed}</Typography>
            <Typography>
              Number of Components Analyzed: {data.numberOfComponentsAnalyzed}
            </Typography>
          </Paper>
        );
      default:
        return null;
    }
  };

  const renderGenericDistribution = () => {
    if (selectedGraphType !== 'Generic distribution') return null;

    return (
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6">Generic distribution graph (2024)</Typography>
        <BarChart
          dataset={hardcodedData['Generic distribution graph']['2024']}
          yAxis={[{ scaleType: 'band', dataKey: 'label' }]}
          series={[
            {
              dataKey: 'value',
              label: 'Generic distribution graph (2024)',
              valueFormatter: (value: number | null) => value?.toString() ?? '',
            },
          ]}
          layout="horizontal"
          width={1000}
          height={300}
          margin={{ left: 300, right: 50 }}
          xAxis={[{ label: 'Count' }]}
        />
        <Typography sx={{ mt: 2, color: 'red' }}>
          After our analysis, we can estimate that generic types with 'none' scoring can be divided
          into the following types, reducing the number of generic workouts with 'none' type by
          approximately 70%.
        </Typography>
        <BarChart
          dataset={hardcodedData['Generic distribution after']['2024']}
          yAxis={[{ scaleType: 'band', dataKey: 'label' }]}
          series={[
            {
              dataKey: 'value',
              label: 'Generic distribution after analysis (2024)',
              valueFormatter: (value: number | null) => value?.toString() ?? '',
            },
          ]}
          layout="horizontal"
          width={1000}
          height={300}
          margin={{ left: 300, right: 50 }}
          xAxis={[{ label: 'Count' }]}
        />
        <Paper elevation={3} sx={{ mt: 2, p: 2, backgroundColor: '#f5f5f5' }}>
          <Typography variant="h6" gutterBottom>
            Additional information
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 2 }}>
            <Box>
              <Typography variant="subtitle1" fontWeight="bold" color="primary">
                CANDIDATES
              </Typography>
              <Typography variant="body2">
                Components that could potentially be successfully categorized into other types.
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" fontWeight="bold" color="secondary">
                GENERIC
              </Typography>
              <Typography variant="body2">
                Components that should remain as generic workouts.
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2, flexWrap: 'wrap' }}>
        <FormControl sx={{ minWidth: 200, mb: 1 }}>
          <InputLabel id="select-data-label">Select Data</InputLabel>
          <Select
            labelId="select-data-label"
            value={selectedGraphType}
            onChange={handleGraphTypeChange}
            label="Select Data"
          >
            {graphTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedGraphType !== 'Generic distribution' && (
          <>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={handleViewModeChange}
              aria-label="view mode"
              sx={{ height: '56px', mb: 1 }}
            >
              <ToggleButton value="all" aria-label="all years" sx={{ px: 2 }}>
                ALL YEARS
              </ToggleButton>
              <ToggleButton value="single" aria-label="single year" sx={{ px: 2 }}>
                SINGLE YEAR
              </ToggleButton>
            </ToggleButtonGroup>
            {viewMode === 'single' && (
              <FormControl sx={{ minWidth: 120, mb: 1 }}>
                <InputLabel id="year-select-label">Year</InputLabel>
                <Select
                  labelId="year-select-label"
                  value={selectedYear}
                  onChange={handleYearChange}
                  label="Year"
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        )}
      </Box>

      {selectedGraphType === 'Generic distribution' ? (
        renderGenericDistribution()
      ) : viewMode === 'all' ? (
        ['ALL', ...years.slice(0, -1).reverse()].map((year) => (
          <Box key={year} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <BarChart
                  dataset={getDataForGraph(year)}
                  yAxis={[{ scaleType: 'band', dataKey: 'label' }]}
                  series={[
                    {
                      dataKey: 'value',
                      label: `${selectedGraphType} - ${year}`,
                      valueFormatter: (value: number | null) => value?.toString() ?? '',
                    },
                  ]}
                  layout="horizontal"
                  width={800}
                  height={600}
                  margin={{ left: 150, right: 50 }}
                  xAxis={[{ label: 'Count' }]}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {renderAdditionalInfo(year === 'ALL' ? '2024' : year)}
              </Grid>
            </Grid>
          </Box>
        ))
      ) : (
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <BarChart
                dataset={getDataForGraph(selectedYear)}
                yAxis={[{ scaleType: 'band', dataKey: 'label' }]}
                series={[
                  {
                    dataKey: 'value',
                    label: `${selectedGraphType} - ${selectedYear}`,
                    valueFormatter: (value: number | null) => value?.toString() ?? '',
                  },
                ]}
                layout="horizontal"
                width={800}
                height={600}
                margin={{ left: 150, right: 50 }}
                xAxis={[{ label: 'Count' }]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {renderAdditionalInfo(selectedYear)}
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default Graphs;

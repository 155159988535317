import {
  Component,
  GenericComponent,
  Session,
  StrengthComponent,
} from '../generalParser/parser.types';

import { mapGenericComponentToWodUp } from './genericWodupParser';
import { mapStrengthComponentToWodUp } from './strengthWodupParser';
import { getProgramIdByName } from './utils';
import { WOD } from './wodup.types';

export function parseSession(session: Session): WOD {
  const wod: WOD = {
    components: [],
    name: '',
    occurs_on: new Date().toISOString(),
    order: session.type === 'AM' ? 0 : 1,
    program_id: getProgramIdByName('test'),
    publish_at: new Date().toISOString(),
  };

  session.components.forEach((component, index) => {
    let parsedComponent;

    if (isStrengthComponent(component)) {
      parsedComponent = mapStrengthComponentToWodUp(component);
    }

    if (isGenericComponent(component)) {
      parsedComponent = mapGenericComponentToWodUp(component);
    }

    if (parsedComponent) {
      parsedComponent.order = index;
      wod.components.push(parsedComponent);
    }
  });

  return wod;
}

function isStrengthComponent(component: Component): component is StrengthComponent {
  return component.type === 'Strength';
}

function isGenericComponent(component: Component): component is GenericComponent {
  return component.type === 'Generic';
}

import { ParserRuleContext } from 'antlr4ng';
import { MarkerSeverity } from 'monaco-editor';

import { logger } from '../../../Logger';
import { MovementContext } from '../../antlr/.antlr/UniversalWorkoutLanguageParser';
import { UWLError } from '../../UWLErrorHandler';
import { PATTERNS } from '../constants';
import { Movement } from '../Movement';
import { MovementAttrs } from '../types';

import { UNIT_RULES, isValidUnit } from './../../rules';

export class MovementParser {
  constructor(private errors: UWLError[]) {}

  parseMovement(m: MovementContext): Movement {
    const [names, mults] = this.parseMovementName(m);
    const attributes = this.parseMovementAttributes(m);
    return new Movement(names, mults, attributes);
  }

  private parseMovementName(movementCtx: MovementContext): [string[], number[]] {
    const names: string[] = [];
    const mults: number[] = [];

    const movementName = movementCtx.movement_name();
    const simpleMovement = movementName.SIMPLE_MOVEMENT();

    if (simpleMovement) {
      names.push(simpleMovement.getText().trim());
    } else {
      const complex = movementName.complex_movement();
      if (complex && complex._mov_name && complex._mov_reps) {
        for (let i = 0; i < complex._mov_name.length; i++) {
          const name = complex._mov_name[i].text;
          const reps = complex._mov_reps[i].text;
          if (name && reps) {
            names.push(name.trim());
            mults.push(Number.parseInt(reps));
          }
        }
      }
    }
    return [names, mults];
  }

  private parseMovementUnits(movement: MovementContext): {
    load_unit?: string;
    distance_unit?: string;
  } {
    const loadUnit =
      movement.load().length > 0 && movement.load()[0].MC_CONTENT()
        ? movement.load()[0].MC_CONTENT().getText()
        : undefined;

    const distanceUnit =
      movement.distance().length > 0 && movement.distance()[0].MC_CONTENT()
        ? movement.distance()[0].MC_CONTENT().getText()
        : undefined;

    if (loadUnit !== undefined) {
      const loads = movement.load()[0]._loads;
      for (const load of loads) {
        if (load.text && !(load.text.match(PATTERNS.FLOAT) || load.text.match(PATTERNS.MAX))) {
          this.errors.push({
            startLineNumber: load.line,
            endLineNumber: load.line,
            startColumn: load.column + 1,
            endColumn: load.column + load.text.length + 1,
            message: `"Load" should be either a number or 'M' for maximum`,
            severity: MarkerSeverity.Error,
          });
        }
      }

      if (!isValidUnit(loadUnit, 'LOAD')) {
        this.errors.push({
          startLineNumber: movement.load()[0].MC_CONTENT().symbol.line,
          endLineNumber: movement.load()[0].MC_CONTENT().symbol.line,
          startColumn: movement.load()[0].MC_CONTENT().symbol.column + 1,
          endColumn:
            movement.load()[0].MC_CONTENT().symbol.column +
            movement.load()[0].MC_CONTENT().getText().length +
            1,
          message: `"${movement.load()[0].MC_CONTENT().getText().trim()}" is not a valid load unit\nShould be one of ${UNIT_RULES.LOAD.accepted.join(', ')}`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    if (distanceUnit !== undefined) {
      if (!isValidUnit(distanceUnit, 'DISTANCE')) {
        this.errors.push({
          startLineNumber: movement.distance()[0].MC_CONTENT().symbol.line,
          endLineNumber: movement.distance()[0].MC_CONTENT().symbol.line,
          startColumn: movement.distance()[0].MC_CONTENT().symbol.column + 1,
          endColumn:
            movement.distance()[0].MC_CONTENT().symbol.column +
            movement.distance()[0].MC_CONTENT().getText().length +
            1,
          message: `"${movement.distance()[0].MC_CONTENT().getText().trim()}" is not a valid distance unit\nShould be one of ${UNIT_RULES.DISTANCE.accepted.join(', ')}`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    return {
      load_unit: loadUnit,
      distance_unit: distanceUnit,
    };
  }

  private checkForDuplicateAttribute(name: string, attributes: ParserRuleContext[]): UWLError[] {
    const errors: UWLError[] = [];

    for (let i = 1; i < attributes.length; i++) {
      const offender = attributes[i];
      if (offender.start === null || offender.stop === null) {
        continue;
      }
      errors.push({
        startLineNumber: offender.start.line,
        endLineNumber: offender.stop.line,
        startColumn: offender.start.column + 1,
        endColumn: offender.stop.column + (offender.stop.text?.length ?? 0) + 1,
        message: `Duplicate movement attribute "${name}".\nOnly the first value will be used.`,
        severity: MarkerSeverity.Warning,
      });
    }

    return errors;
  }

  private parseMovementReps(movement: MovementContext): string[] | undefined {
    const errors: UWLError[] = [];
    if (movement.reps().length === 0) {
      return undefined;
    }
    errors.push(...this.checkForDuplicateAttribute('Reps', movement.reps()));

    for (const rep of movement.reps()[0]._reps_a) {
      if (
        rep.text !== undefined &&
        !(rep.text.match(PATTERNS.INT) || rep.text.match(PATTERNS.MAX))
      ) {
        errors.push({
          startLineNumber: rep.line,
          endLineNumber: rep.line,
          startColumn: rep.column + 1,
          endColumn: rep.column + rep.text.length + 1,
          message: `"Reps" should be either a number or 'M' for maximum`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    this.errors.push(...errors);

    return movement.reps().length === 0
      ? undefined
      : movement.reps()[0]._reps_a.map((item) => item.text ?? '');
  }

  private parseMovementLoad(movement: MovementContext): string[] | undefined {
    this.errors.push(...this.checkForDuplicateAttribute('Load', movement.load()));
    return movement.load().length === 0
      ? undefined
      : movement.load()[0]._loads.map((item) => item.text ?? '');
  }

  private parseMovementTempo(movement: MovementContext): string[] | undefined {
    const errors: UWLError[] = [];
    if (movement.tempo().length === 0) {
      return undefined;
    }
    errors.push(...this.checkForDuplicateAttribute('Tempo', movement.tempo()));
    for (const tempo of movement.tempo()[0]._tempos) {
      if (!tempo.text?.match(PATTERNS.TEMPO)) {
        errors.push({
          startLineNumber: tempo.line,
          endLineNumber: tempo.line,
          startColumn: tempo.column + 1,
          endColumn: tempo.column + (tempo.text?.length ?? 0) + 1,
          message: `Tempo should be between 1 and 4 characters long and should be composed of digits 0-9 and the letters 'A' and 'X'`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    this.errors.push(...errors);

    return movement.tempo().length === 0
      ? undefined
      : movement.tempo()[0]._tempos?.map((item) => item.text ?? '');
  }

  private parseMovementDistance(movement: MovementContext): string[] | undefined {
    const errors: UWLError[] = [];
    errors.push(...this.checkForDuplicateAttribute('Distance', movement.distance()));

    if (movement.distance().length === 0) {
      return undefined;
    }

    for (const dist of movement.distance()[0]._distances) {
      if (!(dist.text?.match(PATTERNS.FLOAT) || dist.text?.match(PATTERNS.MAX))) {
        errors.push({
          startLineNumber: dist.line,
          endLineNumber: dist.line,
          startColumn: dist.column + 1,
          endColumn: dist.column + (dist.text?.length ?? 0) + 1,
          message: `"Distance" should be either an Number or 'M' for maximize`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    this.errors.push(...errors);

    return movement.distance().length === 0
      ? undefined
      : movement.distance()[0]._distances.map((item) => item.text ?? '');
  }

  private parseMovementDuration(movement: MovementContext): string[] | undefined {
    const errors: UWLError[] = [];
    errors.push(...this.checkForDuplicateAttribute('Duration', movement.duration()));

    if (movement.duration().length === 0) {
      return undefined;
    }
    for (const duration of movement.duration()[0]._durations) {
      const time = duration.MC_TIME()?.getText() ?? null;
      const max = duration.MC_MAX()?.getText();
      const time_ext = {
        number: duration.MC_NUMBER()?.getText(),
        unit: duration.MC_CONTENT()?.getText(),
      };
      if (
        duration.start !== null &&
        duration.stop !== null &&
        !(
          time?.match(PATTERNS.TIME) ||
          max?.match(PATTERNS.MAX) ||
          time_ext.number?.match(PATTERNS.INT)
        )
      ) {
        errors.push({
          startLineNumber: duration.start.line,
          endLineNumber: duration.stop.line,
          startColumn: duration.start.column + 1,
          endColumn: duration.stop.column + (duration.stop.text?.length ?? 0) + 1,
          message: `"Duration" should be either an Number or 'M' for maximize`,
          severity: MarkerSeverity.Error,
        });
      }
      if (
        time_ext.unit &&
        !isValidUnit(time_ext.unit, 'TIME') &&
        duration.start !== null &&
        duration.stop !== null
      ) {
        errors.push({
          startLineNumber: duration.start.line,
          endLineNumber: duration.stop.line,
          startColumn: duration.start.column + 1,
          endColumn: duration.stop.column + (duration.stop.text?.length ?? 0) + 1,
          message: `"${time_ext.unit?.trim()}" is not a valid time unit\nShould be one of ${UNIT_RULES.TIME.accepted.join(', ')}`,
          severity: MarkerSeverity.Error,
        });
      }
    }

    this.errors.push(...errors);

    return movement.duration().length === 0
      ? undefined
      : movement.duration()[0]._durations.map((item) => item.getText());
  }

  private parseMovementAttributes(movement: MovementContext): MovementAttrs {
    const units = this.parseMovementUnits(movement);
    return {
      reps: this.parseMovementReps(movement),
      load: this.parseMovementLoad(movement),
      tempo: this.parseMovementTempo(movement),
      distance: this.parseMovementDistance(movement),
      duration: this.parseMovementDuration(movement),
      load_unit: units.load_unit,
      distance_unit: units.distance_unit,
    };
  }
}

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Typography, TextField, Button, Paper, Link } from '@mui/material';
import React, { useState } from 'react';

import api from '../api/base';
import { Program } from '../pages/ProgramList';

interface CreateProgramFormProps {
  onSubmit: (program: Program) => void;
  onCancel: () => void;
  initialProgram?: Program | null;
}

const CreateProgramForm: React.FC<CreateProgramFormProps> = ({
  onSubmit,
  onCancel,
  initialProgram,
}) => {
  const [name, setName] = useState(initialProgram?.name || '');
  const [description, setDescription] = useState(initialProgram?.description || '');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const programData = { name, description };
      let response;
      if (initialProgram) {
        response = await api.put(`/api/programs/${initialProgram.id}`, programData);
      } else {
        response = await api.post('/api/programs', programData);
      }
      onSubmit(response.data);
    } catch (error) {
      setError('Failed to save program. Please try again.');
      console.error('Error saving program:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 4, maxWidth: 600, mx: 'auto', mt: 4, borderRadius: 2 }}>
      <Box display="flex" alignItems="center" mb={4}>
        <Link
          component="button"
          variant="body1"
          onClick={onCancel}
          sx={{ display: 'flex', alignItems: 'center', color: '#1976d2', textDecoration: 'none' }}
        >
          <ArrowBackIcon sx={{ mr: 1, fontSize: 20 }} />
          Back to Programs
        </Link>
      </Box>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        Create new program
      </Typography>
      <form onSubmit={handleSubmit}>
        <Typography variant="body1" mb={1}>
          First, let's name this program.
        </Typography>
        <TextField
          fullWidth
          placeholder="Program name*"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          sx={{ mb: 3 }}
        />
        <TextField
          fullWidth
          placeholder="Description (Optional)"
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={4}
          sx={{ mb: 4 }}
        />
        <Box display="flex" justifyContent="flex-start">
          <Button
            type="submit"
            variant="contained"
            sx={{
              mr: 2,
              bgcolor: '#1976d2',
              '&:hover': { bgcolor: '#1565c0' },
              textTransform: 'none',
              fontWeight: 'bold',
            }}
          >
            Save & add workouts
          </Button>
          <Button
            variant="text"
            onClick={onCancel}
            sx={{ color: '#1976d2', textTransform: 'none' }}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default CreateProgramForm;

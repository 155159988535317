import { Workout } from '../@monaco-uwl/UWLVisitor/Workout';

import { parseUWLComponents } from './componentsParser';
import { Session, SessionType } from './parser.types';

// Workout[session][workout] - workout[1][0] is the first workout in the third session
export function parseUWLContent(day: Workout[][]): Session[] {
  const parsedSessions: Session[] = [];

  if (day.length === 0) {
    return [];
  }
  const c = parseUWLComponents(day[0]);
  parsedSessions.push({
    type: SessionType.AM,
    components: c,
  });

  if (day.length === 1) {
    return parsedSessions;
  }

  parsedSessions.push({
    type: SessionType.PM,
    components: parseUWLComponents(day[1]),
  });

  return parsedSessions;
}

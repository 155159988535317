import { Box } from '@mui/material';
import React from 'react';

import { parseUWLContent } from '../../generalParser/sessionParser';
import { logger } from '../../Logger';

import { SessionPreview } from './components/SessionPreview';
import { styles } from './WodUpPreview.styles';
import { WodUpPreviewProps } from './WodUpPreview.types';

const WodUpPreview: React.FC<WodUpPreviewProps> = ({ Uwlcontent }) => {
  logger.info('=>> Uwlcontent', Uwlcontent);
  const parsedUWL = parseUWLContent(Uwlcontent);
  logger.info('=>> parsedUWL', parsedUWL);
  return (
    <Box sx={styles.container}>
      {parsedUWL.map((session, index) => (
        <SessionPreview key={index} session={session} />
      ))}
    </Box>
  );
};

export default WodUpPreview;

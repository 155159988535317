import { useCallback, useEffect, useRef, useState } from 'react';

import { logger } from '../Logger';
import { Movement } from '../services/movementsService';

interface UseMovementSearchProps {
  onSearch: (query: string) => Promise<Movement[]>;
  onEmpty: () => void;
  onSearchResults: (results: Movement[]) => void;
  debounceMs?: number;
}

export const useMovementSearch = ({
  onSearch,
  onEmpty,
  onSearchResults,
  debounceMs = 300,
}: UseMovementSearchProps) => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const searchTimeoutRef = useRef<NodeJS.Timeout>();
  const currentSearchRef = useRef<string>('');

  const performSearch = useCallback(
    async (query: string) => {
      if (query !== currentSearchRef.current) return;

      try {
        setIsSearching(true);
        const results = await onSearch(query);
        if (query === currentSearchRef.current) {
          onSearchResults(results);
        }
      } catch (error) {
        logger.error('Search failed:', error);
      } finally {
        if (query === currentSearchRef.current) {
          setIsSearching(false);
        }
      }
    },
    [onSearch, onSearchResults],
  );

  const handleSearch = useCallback(
    (query: string) => {
      setSearchQuery(query);
      currentSearchRef.current = query;

      if (!query.trim()) {
        setIsSearching(false);
        onEmpty();
        return;
      }

      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }

      searchTimeoutRef.current = setTimeout(() => {
        requestAnimationFrame(() => {
          performSearch(query);
        });
      }, debounceMs);
    },
    [debounceMs, onEmpty, performSearch],
  );

  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, []);

  return {
    isSearching,
    searchQuery,
    handleSearch,
  };
};

import { CancellationToken, editor, IRange, languages, Position } from 'monaco-editor';

import { logger } from '../Logger';
import { movementCache } from '../services/MovementCache';
import { Movement } from '../services/movementsService';

type Range = IRange;

const PATTERNS = {
  LOAD: /.*x( )*(([0-9]+)(,( )*[0-9]+)*)|load( )?/,
  REST: /^\s*[Rr]est:?\s*/,
  PARAMETER: /.*\([^)]*$/,
  PARAMETERS: {
    SCORE_BY: /^\s*score_by\s*:.*/,
  },
  EMOM_ADD: /^Min-([0-9]+)[ \t]*:.*\n/,
  MOVEMENT: /^\s*(>(.* \+ )*|Min-[0-9]+:\s+)/,
  DISTANCE_UNIT: /for [0-9]+( )?$/,
} as const;

export const UWLAutocompletionProvider: languages.CompletionItemProvider = {
  triggerCharacters: ['%', '*'],
  provideCompletionItems: function (
    model: editor.ITextModel,
    position: Position,
    _context: languages.CompletionContext,
    _token: CancellationToken,
  ): languages.ProviderResult<languages.CompletionList> {
    //console.log(`DEBUG :: provideCompletionItems:: ${position} -- ${context.triggerKind} : ${context.triggerCharacter || "None"} -- ${token.isCancellationRequested}`);
    const word = model.getWordUntilPosition(position);
    const textUntilPosition = model.getValueInRange({
      startLineNumber: position.lineNumber,
      startColumn: 1,
      endLineNumber: position.lineNumber,
      endColumn: word.startColumn,
    });
    // let currentLine = model.getLineContent(position.lineNumber)
    const range: Range = {
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
      startColumn: word.startColumn,
      endColumn: word.endColumn,
    };

    let match = textUntilPosition.match(PATTERNS.DISTANCE_UNIT);

    if (match) {
      return { suggestions: createDistanceUnitProposals(range) };
    }

    match = textUntilPosition.match(PATTERNS.LOAD);
    if (match) {
      return { suggestions: createLoadProposals(range) };
    }

    match = textUntilPosition.match(PATTERNS.REST);
    if (match) {
      return { suggestions: createRestProposals(range) };
    }
    match = textUntilPosition.match(PATTERNS.MOVEMENT);
    if (match) {
      const movementProposals = createMovementProposals(range);
      return { suggestions: movementProposals };
    }

    const preceding_text = model.getValueInRange({
      startLineNumber: 1,
      endLineNumber: position.lineNumber,
      startColumn: 1,
      endColumn: word.endColumn,
    });

    match = preceding_text.match(PATTERNS.PARAMETER);

    if (match) {
      return { suggestions: createParameterProposals(textUntilPosition, range) };
    }

    const ret: languages.ProviderResult<languages.CompletionList> = {
      suggestions: createWorkoutProposals(range),
    };
    const withPrevLine = model.getValueInRange({
      startLineNumber: Math.max(1, position.lineNumber - 1),
      startColumn: 1,
      endLineNumber: position.lineNumber,
      endColumn: word.startColumn,
    });
    // #region Emom minute addition
    match = withPrevLine.match(
      // adding minutes to Emom
      PATTERNS.EMOM_ADD,
    );
    if (match) {
      const next_num: number = parseInt(match[1]!) + 1;
      ret.suggestions.push({
        label: `Min-${next_num}`,
        kind: languages.CompletionItemKind.Keyword,
        insertText: `Min-${next_num}: \${1:movement_name}`,
        range: range,
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        preselect: true,
        sortText: '_',
      });
    }

    // ret.suggestions.push(...createCommentProposals(range));
    // #endregion
    return ret;
  },
};

function generateMovementInsertText(movement: Movement): string {
  const baseText = `${movement.name}: `;

  if (movement.has_reps && movement.has_load) {
    return `${baseText}\${1:reps} x \${2:load} \${3|%RM,%Bodyweight,kg,lbs,pood|} @ \${4:tempo}`;
  }
  if (movement.has_reps) {
    return `${baseText}\${1:reps}`;
  }
  if (movement.has_distance) {
    return `${baseText}for \${1:distance} \${2|m,km,ft,yards,miles|}`;
  }
  if (movement.has_duration) {
    return `${baseText}in \${1:duration} \${2|s,sec,min,minutes|}`;
  }
  if (movement.has_calories) {
    return `${baseText}\${1:calories} cal`;
  }
  if (movement.has_power) {
    return `${baseText}\${1:watts} W`;
  }
  if (movement.has_height) {
    return `${baseText}\${1:height} \${2|cm,inches|}`;
  }

  return `${baseText}\${1:reps}`;
}

function generateMovementDocumentation(movement: Movement): string {
  const parts: string[] = [];

  const capabilities = [
    movement.has_reps && 'repetitions',
    movement.has_load && 'load/weight',
    movement.has_duration && 'duration (time)',
    movement.has_distance && 'distance',
    movement.has_calories && 'calories',
    movement.has_power && 'power (watts)',
    movement.has_height && 'height',
  ].filter(Boolean);

  if (capabilities.length > 0) {
    parts.push(`Supports: ${capabilities.join(', ')}`);
  }

  if (movement.is_complex) {
    parts.push(`Complex movement: ${movement.complex_details || 'Multiple movements combined'}`);
  }

  const examples = [];
  if (movement.has_reps && movement.has_load) {
    examples.push('Examples:');
    if (movement.category === 'weightlifting') {
      examples.push('- 5 x 185 lbs @ 3110');
      examples.push('- 3,3,3 x 70,75,80 %RM @ 31X0');
    } else {
      examples.push('- 10 x 1.5 %BW @ 2011');
      examples.push('- 8,8,8 x 135 lbs @ 20X0');
    }
  } else if (movement.has_reps) {
    examples.push('Examples:');
    examples.push('- 21,15,9');
    examples.push('- 10 each side');
  } else if (movement.has_distance) {
    examples.push('Examples:');
    examples.push('- for 400 m');
    examples.push('- for 1 mile');
  } else if (movement.has_duration) {
    examples.push('Examples:');
    examples.push('- in 2:00');
    examples.push('- in 0:30');
  } else if (movement.has_calories) {
    examples.push('Example: 15 cal');
  }

  if (examples.length > 0) {
    parts.push(examples.join('\n'));
  }

  if (movement.videos && movement.videos.length > 0) {
    const video = movement.videos[0];
    if (video.service === 'youtube') {
      parts.push(`\nWatch demonstration video: https://www.youtube.com/watch?v=${video.video_id}`);
    }
  }

  return parts.join('\n');
}

function createMovementProposals(range: Range): languages.CompletionItem[] {
  const movements = movementCache.getMovements();

  return movements.map((movement) => {
    const FIRST_LETTER_ASCII = 97; // 'a'
    const MAX_SORTABLE_LENGTH = 25; // 'z' - 'a'

    const sortableLength = Math.min(movement.name.length, MAX_SORTABLE_LENGTH);
    const lengthAsLetter = String.fromCharCode(FIRST_LETTER_ASCII + sortableLength);
    const sortPriority = `${lengthAsLetter}_${movement.name}`;

    return {
      label: movement.name,
      kind: languages.CompletionItemKind.Text,
      insertText: generateMovementInsertText(movement),
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
      documentation: generateMovementDocumentation(movement),
      sortText: sortPriority,
    };
  });
}

function createWorkoutProposals(range: Range): languages.CompletionItem[] {
  return [
    {
      label: 'Strength',
      kind: languages.CompletionItemKind.Struct,
      insertText: 'Strength (\nrest: "${1}",\n)\n>${2:movementName:}\n*${3:notes}',
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
      preselect: true,
      documentation:
        'Creates a strength-focused workout block\n\nTwo valid formats:\n\n1. Long form:\nStrength (\n  rest: "Fixed 2:00",\n)\n>Back Squat: 5 x 185 lbs @ 3110\n\n2. Short form:\n>Back Squat: 5 x 185 lbs @ 3110\n*notes\nrest "120 sec"',
    },
    {
      label: 'Short Strength',
      kind: languages.CompletionItemKind.Struct,
      insertText: '>${1:movementName:}\n*${2:notes}\nrest "${3}"',
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'Creates a simplified strength block without parameters\n\nExample:\n>Back Squat: 5 x 185 lbs @ 3110\n*Keep core tight throughout movement\nrest "120 sec"',
    },
    {
      label: 'Generic',
      kind: languages.CompletionItemKind.Struct,
      insertText:
        'Generic "${1:component name}" (\nscore_by: "${2|Not Scored,Time (lower is better),Time (higher is better),Reps (higher is better),Reps (lower is better),Rounds and Reps (higher is better),Rounds and Reps (lower is better),Distance (higher is better),Distance (lower is better),Weight (higher is better),Weight (lower is better),Points / anything else (higher is better),Points / anything else (lower is better),Peak Watts,Max Speed|}",\ntime_cap: ${3}:${4:00},\ndescription: "${5:Workout Description}"\n)\n$0\n',
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
      preselect: true,
      documentation:
        'Creates `Something Else` workout block with scoring and time cap\nParameters:\n- component name: Name of the workout section\n- score_by: How the workout is scored\n- time_cap: Time limit for the workout\n- description: Detailed workout instructions\n\nExample:\nGeneric "Pacing Test" (\n  score_by: "Rounds and Reps (higher is better)",\n  time_cap: 20:00,\n  description: "15/11 Echo Bike Cals\n15 Wallball\n15 Power Snatch @ 75/55#\n15 TTB\n15/11 Cal Echo Bike Cals\nRest 90sec")',
    },
    {
      label: 'Alternating',
      kind: languages.CompletionItemKind.Struct,
      insertText:
        '${1:3} alt sets {\n>${2:movementName:}\n*${3:notes}\nrest "${4}"\n+\n>${5:movementName:}\n*${6:notes}\nrest "${7}"\n}',
      range: range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      preselect: true,
      documentation:
        'Creates alternating sets between two movements\nExample:\n3 alt sets {\n>Back Squat: 5 x 185 lbs @ 3110\n*notes\nrest "120 sec"\n+\n>Front Squat: 5 x 165 lbs @ 3110\n*notes\nrest "120 sec"\n}',
    },
  ];
}

function createRestProposals(range: Range): languages.CompletionItem[] {
  return [
    {
      label: 'None',
      kind: languages.CompletionItemKind.TypeParameter,
      insertText: 'None',
      insertTextRules: languages.CompletionItemInsertTextRule.None,
      range: range,
      documentation: 'No rest period between sets\nExample: rest: "None"',
    },
    {
      label: 'As Needed',
      kind: languages.CompletionItemKind.TypeParameter,
      insertText: 'As Needed',
      insertTextRules: languages.CompletionItemInsertTextRule.None,
      range: range,
      documentation:
        'Athlete determines rest period based on readiness\nExample: rest: "As Needed"',
    },
    {
      label: 'Fixed',
      kind: languages.CompletionItemKind.TypeParameter,
      insertText: 'Fixed ${1:mm}:${2:ss}',
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
      documentation: 'Set rest period in minutes and seconds\nExample: rest: "Fixed 2:00"',
    },
    {
      label: 'Every X seconds',
      kind: languages.CompletionItemKind.TypeParameter,
      insertText: 'On a running clock, start a set every ${1:mm}:${2:ss}',
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
      documentation:
        'EMOM-style timing with fixed intervals\nExample: rest: "On a running clock, start a set every 1:30"',
    },
    {
      label: 'Time taken to complete set',
      kind: languages.CompletionItemKind.TypeParameter,
      insertText: "Rest ${1:1.5} X the set's duration",
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
      documentation:
        'Rest period relative to work period\nExample: rest: "Rest 1.5 X the set\'s duration"',
    },
  ];
}

function createLoadProposals(range: Range): languages.CompletionItem[] {
  return [
    {
      label: '%RM',
      kind: languages.CompletionItemKind.Keyword,
      insertText: '%RM',
      range: range,
      documentation: 'Percentage of 1 Rep Max\nExample: Back Squat: 5 x 75 %RM',
    },
    {
      label: '%Bodyweight',
      kind: languages.CompletionItemKind.Keyword,
      insertText: '%Bodyweight',
      range: range,
      documentation: "Percentage of athlete's bodyweight\nExample: Deadlift: 3 x 150 %Bodyweight",
    },
    {
      label: 'kg',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'kg',
      range: range,
      documentation: 'Weight in kilograms\nExample: Clean: 3 x 60 kg',
    },
    {
      label: 'lbs',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'lbs',
      range: range,
      documentation: 'Weight in pounds\nExample: Snatch: 2 x 135 lbs',
    },
    {
      label: 'pood',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'pood',
      range: range,
      documentation:
        'Traditional kettlebell weight measurement (1 pood ≈ 16kg/35lbs)\nExample: Kettlebell Swing: 20 x 2 pood',
    },
  ];
}

function createParameterProposals(current_line: string, range: Range): languages.CompletionItem[] {
  const match = current_line.toLowerCase().match(PATTERNS.PARAMETERS.SCORE_BY);
  logger.debug(current_line);
  if (match) {
    return [
      {
        label: 'score_by',
        kind: languages.CompletionItemKind.EnumMember,
        insertText:
          '"${1|Not Scored,Time (lower is better),Time (higher is better),Reps (higher is better),Reps (lower is better),Rounds and Reps (higher is better),Rounds and Reps (lower is better),Distance (higher is better),Distance (lower is better),Weight (higher is better),Weight (lower is better),Points / anything else (higher is better),Points / anything else (lower is better),Peak Watts,Max Speed|}"${0}',
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range,
      },
    ];
  }

  return [];
}

function createDistanceUnitProposals(range: Range): languages.CompletionItem[] {
  return [
    {
      label: 'ft',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'ft',
      range: range,
      documentation: 'Distance in feet\nExample: Walking Lunge: 10 x BW for 100 ft',
    },
    {
      label: 'yards',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'yards',
      range: range,
      documentation: 'Distance in yards\nExample: Running: for 400 yards',
    },
    {
      label: 'miles',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'miles',
      range: range,
      documentation: 'Distance in miles\nExample: Running: for 1 miles',
    },
    {
      label: 'm',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'm',
      range: range,
      documentation: 'Distance in meters\nExample: Running: for 400 m',
    },
    {
      label: 'km',
      kind: languages.CompletionItemKind.Keyword,
      insertText: 'km',
      range: range,
      documentation: 'Distance in kilometers\nExample: Running: for 5 km',
    },
  ];
}

/*
function createCommentProposals(range: Range): languages.CompletionItem[] {
	return [
		{
			label: 'Note for Coaches',
			kind: languages.CompletionItemKind.File,
			insertText: ' Coaches: ',
			range: range,
			insertTextRules: languages.CompletionItemInsertTextRule.None,
			filterText: "* Coaches"
		},
		{
			label: 'Pre Exercise Instruction',
			kind: languages.CompletionItemKind.File,
			insertText: ' >>] Pre Exercise: ',
			range: range,
			insertTextRules: languages.CompletionItemInsertTextRule.None,
			filterText: "* Pre exercise",
		},
		{
			label: 'Post Exercise Instruction',
			kind: languages.CompletionItemKind.File,
			insertText: ' [>> Post Exercise: ',
			range: range,
			insertTextRules: languages.CompletionItemInsertTextRule.None,
			filterText: "* Post exercise",
		}
	]
}
*/

import api from '../api/base';
import { logger } from '../Logger';

export interface Movement {
  id: number;
  name: string;
  has_reps: boolean;
  has_load: boolean;
  has_duration: boolean;
  has_distance: boolean;
  has_height: boolean;
  has_power: boolean;
  has_calories: boolean;
  is_complex: boolean;
  complex_details?: string;
  category?: string;
  videos?: any;
  gym_id?: number;
  created_at?: string;
  updated_at?: string;
  video_url?: string;
}

class MovementsService {
  private subscribers: Set<() => void> = new Set();

  public subscribe(callback: () => void): () => void {
    this.subscribers.add(callback);
    return () => this.subscribers.delete(callback);
  }

  private notifyChange(): void {
    this.subscribers.forEach((callback) => callback());
  }

  async getAll(page: number, per_page: number): Promise<Movement[]> {
    const response = await api.get('/api/movements', {
      params: { page, per_page },
    });
    return response.data;
  }

  async getTotalCount(): Promise<number> {
    const response = await api.get('/api/movements/count');
    return response.data.count;
  }

  async getAllMovements(): Promise<Movement[]> {
    try {
      const count = await this.getTotalCount();
      return await this.getAll(1, count);
    } catch (error) {
      logger.error('Failed to fetch all movements:', error);
      return [];
    }
  }

  async create(movement: Omit<Movement, 'id'>): Promise<Movement> {
    const response = await api.post('/api/movements', movement);
    this.notifyChange();
    return response.data;
  }

  async update(id: number, movement: Partial<Movement>): Promise<Movement> {
    const response = await api.put(`/api/movements/${id}`, movement);
    this.notifyChange();
    return response.data;
  }

  async delete(id: number): Promise<void> {
    await api.delete(`/api/movements/${id}`);
    this.notifyChange();
  }

  async search(query: string, signal?: AbortSignal): Promise<Movement[]> {
    const response = await api.get('/api/movements/search', {
      params: { query },
      signal,
    });
    return response.data;
  }

  async getById(id: number): Promise<Movement> {
    const response = await api.get(`/api/movements/${id}`);
    return response.data;
  }
}

const movementsServiceInstance = new MovementsService();
export const createMovementsService = () => movementsServiceInstance;

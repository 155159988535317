// Generated from UniversalWorkoutLanguageParser.g4 by ANTLR 4.13.1

import * as antlr from "antlr4ng";
import { Token } from "antlr4ng";

import { UniversalWorkoutLanguageParserVisitor } from "./UniversalWorkoutLanguageParserVisitor.js";

// for running tests with parameters, TODO: discuss strategy for typed parameters in CI
// eslint-disable-next-line no-unused-vars
type int = number;


export class UniversalWorkoutLanguageParser extends antlr.Parser {
    public static readonly WS = 1;
    public static readonly NL = 2;
    public static readonly WORKOUT_SEPARATOR = 3;
    public static readonly MOVEMENT_MARKER = 4;
    public static readonly CHOICE_KW = 5;
    public static readonly OPTION_KW = 6;
    public static readonly WOD_KW = 7;
    public static readonly DEF_KW = 8;
    public static readonly NEW_MOV_KW = 9;
    public static readonly REST_KW = 10;
    public static readonly STRING = 11;
    public static readonly GENERIC_STRING = 12;
    public static readonly PAREN_L = 13;
    public static readonly ALTERNATE_KW = 14;
    public static readonly LBRACE = 15;
    public static readonly RBRACE = 16;
    public static readonly COLON = 17;
    public static readonly INLINE_COMMENT = 18;
    public static readonly MULTILINE_COMMENT = 19;
    public static readonly WORKOUT_IDENTIFIER = 20;
    public static readonly ALTERNATION_NUMBER = 21;
    public static readonly ERROR_CHAR = 22;
    public static readonly WORKOUT_WS = 23;
    public static readonly WORKOUT_PAREN_L = 24;
    public static readonly WORKOUT_PAREN_R = 25;
    public static readonly WORKOUT_COLON = 26;
    public static readonly WORKOUT_COMMA = 27;
    public static readonly WORKOUT_QUOTE = 28;
    public static readonly WORKOUT_STRING = 29;
    public static readonly WORKOUT_ML_STRING = 30;
    public static readonly WORKOUT_TIME = 31;
    public static readonly WORKOUT_NUMBER = 32;
    public static readonly WORKOUT_UNIT_TIME = 33;
    public static readonly WORKOUT_IDEN = 34;
    public static readonly CHOICE_WS = 35;
    public static readonly CHOICE_NAME = 36;
    public static readonly MOVEMENT_NAME_REP_MARKER = 37;
    public static readonly SIMPLE_MOVEMENT = 38;
    public static readonly MOVEMENT_NAME_REPS = 39;
    public static readonly MOVEMENT_NAME_COMPOENENT_SEPARATOR = 40;
    public static readonly COMPLEX_MOVEMENT = 41;
    public static readonly MOVEMENT_NAME_TERMINATOR = 42;
    public static readonly MOVEMENT_NAME_WS = 43;
    public static readonly MC_WS = 44;
    public static readonly MC_NUMBER = 45;
    public static readonly MC_MAX = 46;
    public static readonly MC_TIME = 47;
    public static readonly MC_SET_SEPARATOR = 48;
    public static readonly LOAD_MARKER = 49;
    public static readonly TEMPO_MARKER = 50;
    public static readonly DURATION_MARKER = 51;
    public static readonly DISTANCE_MARKER = 52;
    public static readonly MOVEMENT_TERM = 53;
    public static readonly MC_CONTENT = 54;
    public static readonly MOV_ERR = 55;
    public static readonly ALT_WS = 56;
    public static readonly ALT_KW = 57;
    public static readonly DEF_WS = 58;
    public static readonly DEF_COMMENT = 59;
    public static readonly DEF_REGEX = 60;
    public static readonly DEF_IDENTIFIER = 61;
    public static readonly DEF_QUOTE = 62;
    public static readonly DEF_COLON = 63;
    public static readonly DEF_OPTIONAL = 64;
    public static readonly DEF_COMMA = 65;
    public static readonly DEF_PAREN_L = 66;
    public static readonly DEF_PAREN_R = 67;
    public static readonly DEF_BRACE_L = 68;
    public static readonly DEF_BRACE_R = 69;
    public static readonly DEF_SUPER_KW = 70;
    public static readonly DEF_TYPES = 71;
    public static readonly DEF_ENUM_KW = 72;
    public static readonly NEW_MOV_WS = 73;
    public static readonly NEW_MOV_QUOTE = 74;
    public static readonly NEW_MOV_IDENTIFIER = 75;
    public static readonly NEW_MOV_COLON = 76;
    public static readonly NEW_MOV_COMMA = 77;
    public static readonly NEW_MOV_SEMI = 78;
    public static readonly NEW_MOV_PARAM = 79;
    public static readonly RULE_error = 0;
    public static readonly RULE_mov_time = 1;
    public static readonly RULE_reps = 2;
    public static readonly RULE_load = 3;
    public static readonly RULE_tempo = 4;
    public static readonly RULE_duration = 5;
    public static readonly RULE_distance = 6;
    public static readonly RULE_complex_movement = 7;
    public static readonly RULE_movement_name = 8;
    public static readonly RULE_movement = 9;
    public static readonly RULE_comment = 10;
    public static readonly RULE_workout_iden = 11;
    public static readonly RULE_workout_attr_id = 12;
    public static readonly RULE_workout_attr_val_str = 13;
    public static readonly RULE_workout_attr_val_number = 14;
    public static readonly RULE_workout_attr_val_time = 15;
    public static readonly RULE_workout_attr_val = 16;
    public static readonly RULE_workout_attribute = 17;
    public static readonly RULE_workout_comment = 18;
    public static readonly RULE_workout = 19;
    public static readonly RULE_shorthand_strength = 20;
    public static readonly RULE_shorthand_generic = 21;
    public static readonly RULE_any_workout = 22;
    public static readonly RULE_alternation = 23;
    public static readonly RULE_option_block_title = 24;
    public static readonly RULE_option_title = 25;
    public static readonly RULE_option = 26;
    public static readonly RULE_option_block = 27;
    public static readonly RULE_wod = 28;
    public static readonly RULE_def_id = 29;
    public static readonly RULE_def_super_kw = 30;
    public static readonly RULE_def_super_param = 31;
    public static readonly RULE_def_super = 32;
    public static readonly RULE_def_param_name = 33;
    public static readonly RULE_def_param_enum_entry = 34;
    public static readonly RULE_def_param_enum = 35;
    public static readonly RULE_def_param_type = 36;
    public static readonly RULE_def_param = 37;
    public static readonly RULE_definition = 38;
    public static readonly RULE_defs = 39;
    public static readonly RULE_new_movement_name = 40;
    public static readonly RULE_new_movement_params = 41;
    public static readonly RULE_new_movement = 42;
    public static readonly RULE_day = 43;

    public static readonly literalNames = [
        null, null, null, null, null, null, null, null, "'define'", "'newMovement'", 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, null, 
        null, null, null, "'M'", null, null, null, null, null, null, null, 
        null, null, null, null, null, null, null, null, null, null, "'?'", 
        null, null, null, null, null, null, null, "'enum'", null, null, 
        null, null, null, "';'"
    ];

    public static readonly symbolicNames = [
        null, "WS", "NL", "WORKOUT_SEPARATOR", "MOVEMENT_MARKER", "CHOICE_KW", 
        "OPTION_KW", "WOD_KW", "DEF_KW", "NEW_MOV_KW", "REST_KW", "STRING", 
        "GENERIC_STRING", "PAREN_L", "ALTERNATE_KW", "LBRACE", "RBRACE", 
        "COLON", "INLINE_COMMENT", "MULTILINE_COMMENT", "WORKOUT_IDENTIFIER", 
        "ALTERNATION_NUMBER", "ERROR_CHAR", "WORKOUT_WS", "WORKOUT_PAREN_L", 
        "WORKOUT_PAREN_R", "WORKOUT_COLON", "WORKOUT_COMMA", "WORKOUT_QUOTE", 
        "WORKOUT_STRING", "WORKOUT_ML_STRING", "WORKOUT_TIME", "WORKOUT_NUMBER", 
        "WORKOUT_UNIT_TIME", "WORKOUT_IDEN", "CHOICE_WS", "CHOICE_NAME", 
        "MOVEMENT_NAME_REP_MARKER", "SIMPLE_MOVEMENT", "MOVEMENT_NAME_REPS", 
        "MOVEMENT_NAME_COMPOENENT_SEPARATOR", "COMPLEX_MOVEMENT", "MOVEMENT_NAME_TERMINATOR", 
        "MOVEMENT_NAME_WS", "MC_WS", "MC_NUMBER", "MC_MAX", "MC_TIME", "MC_SET_SEPARATOR", 
        "LOAD_MARKER", "TEMPO_MARKER", "DURATION_MARKER", "DISTANCE_MARKER", 
        "MOVEMENT_TERM", "MC_CONTENT", "MOV_ERR", "ALT_WS", "ALT_KW", "DEF_WS", 
        "DEF_COMMENT", "DEF_REGEX", "DEF_IDENTIFIER", "DEF_QUOTE", "DEF_COLON", 
        "DEF_OPTIONAL", "DEF_COMMA", "DEF_PAREN_L", "DEF_PAREN_R", "DEF_BRACE_L", 
        "DEF_BRACE_R", "DEF_SUPER_KW", "DEF_TYPES", "DEF_ENUM_KW", "NEW_MOV_WS", 
        "NEW_MOV_QUOTE", "NEW_MOV_IDENTIFIER", "NEW_MOV_COLON", "NEW_MOV_COMMA", 
        "NEW_MOV_SEMI", "NEW_MOV_PARAM"
    ];
    public static readonly ruleNames = [
        "error", "mov_time", "reps", "load", "tempo", "duration", "distance", 
        "complex_movement", "movement_name", "movement", "comment", "workout_iden", 
        "workout_attr_id", "workout_attr_val_str", "workout_attr_val_number", 
        "workout_attr_val_time", "workout_attr_val", "workout_attribute", 
        "workout_comment", "workout", "shorthand_strength", "shorthand_generic", 
        "any_workout", "alternation", "option_block_title", "option_title", 
        "option", "option_block", "wod", "def_id", "def_super_kw", "def_super_param", 
        "def_super", "def_param_name", "def_param_enum_entry", "def_param_enum", 
        "def_param_type", "def_param", "definition", "defs", "new_movement_name", 
        "new_movement_params", "new_movement", "day",
    ];

    public get grammarFileName(): string { return "UniversalWorkoutLanguageParser.g4"; }
    public get literalNames(): (string | null)[] { return UniversalWorkoutLanguageParser.literalNames; }
    public get symbolicNames(): (string | null)[] { return UniversalWorkoutLanguageParser.symbolicNames; }
    public get ruleNames(): string[] { return UniversalWorkoutLanguageParser.ruleNames; }
    public get serializedATN(): number[] { return UniversalWorkoutLanguageParser._serializedATN; }

    protected createFailedPredicateException(predicate?: string, message?: string): antlr.FailedPredicateException {
        return new antlr.FailedPredicateException(this, predicate, message);
    }

    public constructor(input: antlr.TokenStream) {
        super(input);
        this.interpreter = new antlr.ParserATNSimulator(this, UniversalWorkoutLanguageParser._ATN, UniversalWorkoutLanguageParser.decisionsToDFA, new antlr.PredictionContextCache());
    }
    public error(): ErrorContext {
        let localContext = new ErrorContext(this.context, this.state);
        this.enterRule(localContext, 0, UniversalWorkoutLanguageParser.RULE_error);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 88;
            this.match(UniversalWorkoutLanguageParser.ERROR_CHAR);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public mov_time(): Mov_timeContext {
        let localContext = new Mov_timeContext(this.context, this.state);
        this.enterRule(localContext, 2, UniversalWorkoutLanguageParser.RULE_mov_time);
        try {
            this.state = 94;
            this.errorHandler.sync(this);
            switch (this.tokenStream.LA(1)) {
            case UniversalWorkoutLanguageParser.MC_TIME:
                this.enterOuterAlt(localContext, 1);
                {
                this.state = 90;
                this.match(UniversalWorkoutLanguageParser.MC_TIME);
                }
                break;
            case UniversalWorkoutLanguageParser.MC_MAX:
                this.enterOuterAlt(localContext, 2);
                {
                this.state = 91;
                this.match(UniversalWorkoutLanguageParser.MC_MAX);
                }
                break;
            case UniversalWorkoutLanguageParser.MC_NUMBER:
                this.enterOuterAlt(localContext, 3);
                {
                {
                this.state = 92;
                this.match(UniversalWorkoutLanguageParser.MC_NUMBER);
                this.state = 93;
                this.match(UniversalWorkoutLanguageParser.MC_CONTENT);
                }
                }
                break;
            default:
                throw new antlr.NoViableAltException(this);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public reps(): RepsContext {
        let localContext = new RepsContext(this.context, this.state);
        this.enterRule(localContext, 4, UniversalWorkoutLanguageParser.RULE_reps);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 96;
            localContext.__tset47 = this.tokenStream.LT(1);
            _la = this.tokenStream.LA(1);
            if(!(_la === 45 || _la === 46)) {
                localContext.__tset47 = this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            localContext._reps_a.push(localContext.__tset47!);
            this.state = 101;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 48) {
                {
                {
                this.state = 97;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 98;
                localContext.__tset58 = this.tokenStream.LT(1);
                _la = this.tokenStream.LA(1);
                if(!(_la === 45 || _la === 46)) {
                    localContext.__tset58 = this.errorHandler.recoverInline(this);
                }
                else {
                    this.errorHandler.reportMatch(this);
                    this.consume();
                }
                localContext._reps_a.push(localContext.__tset58!);
                }
                }
                this.state = 103;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public load(): LoadContext {
        let localContext = new LoadContext(this.context, this.state);
        this.enterRule(localContext, 6, UniversalWorkoutLanguageParser.RULE_load);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 104;
            this.match(UniversalWorkoutLanguageParser.LOAD_MARKER);
            this.state = 105;
            localContext.__tset74 = this.tokenStream.LT(1);
            _la = this.tokenStream.LA(1);
            if(!(_la === 45 || _la === 46)) {
                localContext.__tset74 = this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            localContext._loads.push(localContext.__tset74!);
            this.state = 110;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 48) {
                {
                {
                this.state = 106;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 107;
                localContext.__tset87 = this.tokenStream.LT(1);
                _la = this.tokenStream.LA(1);
                if(!(_la === 45 || _la === 46)) {
                    localContext.__tset87 = this.errorHandler.recoverInline(this);
                }
                else {
                    this.errorHandler.reportMatch(this);
                    this.consume();
                }
                localContext._loads.push(localContext.__tset87!);
                }
                }
                this.state = 112;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 113;
            localContext._load_unit = this.match(UniversalWorkoutLanguageParser.MC_CONTENT);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public tempo(): TempoContext {
        let localContext = new TempoContext(this.context, this.state);
        this.enterRule(localContext, 8, UniversalWorkoutLanguageParser.RULE_tempo);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 115;
            this.match(UniversalWorkoutLanguageParser.TEMPO_MARKER);
            this.state = 116;
            localContext.__tset107 = this.tokenStream.LT(1);
            _la = this.tokenStream.LA(1);
            if(!(_la === 45 || _la === 54)) {
                localContext.__tset107 = this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            localContext._tempos.push(localContext.__tset107!);
            this.state = 121;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 48) {
                {
                {
                this.state = 117;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 118;
                localContext.__tset120 = this.tokenStream.LT(1);
                _la = this.tokenStream.LA(1);
                if(!(_la === 45 || _la === 54)) {
                    localContext.__tset120 = this.errorHandler.recoverInline(this);
                }
                else {
                    this.errorHandler.reportMatch(this);
                    this.consume();
                }
                localContext._tempos.push(localContext.__tset120!);
                }
                }
                this.state = 123;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public duration(): DurationContext {
        let localContext = new DurationContext(this.context, this.state);
        this.enterRule(localContext, 10, UniversalWorkoutLanguageParser.RULE_duration);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 124;
            this.match(UniversalWorkoutLanguageParser.DURATION_MARKER);
            this.state = 125;
            localContext._mov_time = this.mov_time();
            localContext._durations.push(localContext._mov_time!);
            this.state = 130;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 48) {
                {
                {
                this.state = 126;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 127;
                localContext._mov_time = this.mov_time();
                localContext._durations.push(localContext._mov_time!);
                }
                }
                this.state = 132;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public distance(): DistanceContext {
        let localContext = new DistanceContext(this.context, this.state);
        this.enterRule(localContext, 12, UniversalWorkoutLanguageParser.RULE_distance);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 133;
            this.match(UniversalWorkoutLanguageParser.DISTANCE_MARKER);
            this.state = 134;
            localContext.__tset157 = this.tokenStream.LT(1);
            _la = this.tokenStream.LA(1);
            if(!(_la === 45 || _la === 46)) {
                localContext.__tset157 = this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            localContext._distances.push(localContext.__tset157!);
            this.state = 139;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 48) {
                {
                {
                this.state = 135;
                this.match(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
                this.state = 136;
                localContext.__tset168 = this.tokenStream.LT(1);
                _la = this.tokenStream.LA(1);
                if(!(_la === 45 || _la === 46)) {
                    localContext.__tset168 = this.errorHandler.recoverInline(this);
                }
                else {
                    this.errorHandler.reportMatch(this);
                    this.consume();
                }
                localContext._distances.push(localContext.__tset168!);
                }
                }
                this.state = 141;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 142;
            localContext._distance_unit = this.match(UniversalWorkoutLanguageParser.MC_CONTENT);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public complex_movement(): Complex_movementContext {
        let localContext = new Complex_movementContext(this.context, this.state);
        this.enterRule(localContext, 14, UniversalWorkoutLanguageParser.RULE_complex_movement);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 144;
            localContext._MOVEMENT_NAME_REPS = this.match(UniversalWorkoutLanguageParser.MOVEMENT_NAME_REPS);
            localContext._mov_reps.push(localContext._MOVEMENT_NAME_REPS!);
            this.state = 145;
            localContext._SIMPLE_MOVEMENT = this.match(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT);
            localContext._mov_name.push(localContext._SIMPLE_MOVEMENT!);
            this.state = 151;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 40) {
                {
                {
                this.state = 146;
                this.match(UniversalWorkoutLanguageParser.MOVEMENT_NAME_COMPOENENT_SEPARATOR);
                this.state = 147;
                localContext._MOVEMENT_NAME_REPS = this.match(UniversalWorkoutLanguageParser.MOVEMENT_NAME_REPS);
                localContext._mov_reps.push(localContext._MOVEMENT_NAME_REPS!);
                this.state = 148;
                localContext._SIMPLE_MOVEMENT = this.match(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT);
                localContext._mov_name.push(localContext._SIMPLE_MOVEMENT!);
                }
                }
                this.state = 153;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public movement_name(): Movement_nameContext {
        let localContext = new Movement_nameContext(this.context, this.state);
        this.enterRule(localContext, 16, UniversalWorkoutLanguageParser.RULE_movement_name);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 156;
            this.errorHandler.sync(this);
            switch (this.tokenStream.LA(1)) {
            case UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT:
                {
                this.state = 154;
                this.match(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT);
                }
                break;
            case UniversalWorkoutLanguageParser.MOVEMENT_NAME_REPS:
                {
                this.state = 155;
                this.complex_movement();
                }
                break;
            default:
                throw new antlr.NoViableAltException(this);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public movement(): MovementContext {
        let localContext = new MovementContext(this.context, this.state);
        this.enterRule(localContext, 18, UniversalWorkoutLanguageParser.RULE_movement);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 158;
            this.match(UniversalWorkoutLanguageParser.MOVEMENT_MARKER);
            this.state = 159;
            this.movement_name();
            this.state = 160;
            this.match(UniversalWorkoutLanguageParser.MOVEMENT_NAME_TERMINATOR);
            this.state = 172;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (((((_la - 45)) & ~0x1F) === 0 && ((1 << (_la - 45)) & 243) !== 0)) {
                {
                this.state = 168;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                do {
                    {
                    {
                    this.state = 166;
                    this.errorHandler.sync(this);
                    switch (this.tokenStream.LA(1)) {
                    case UniversalWorkoutLanguageParser.MC_NUMBER:
                    case UniversalWorkoutLanguageParser.MC_MAX:
                        {
                        this.state = 161;
                        this.reps();
                        }
                        break;
                    case UniversalWorkoutLanguageParser.LOAD_MARKER:
                        {
                        this.state = 162;
                        this.load();
                        }
                        break;
                    case UniversalWorkoutLanguageParser.TEMPO_MARKER:
                        {
                        this.state = 163;
                        this.tempo();
                        }
                        break;
                    case UniversalWorkoutLanguageParser.DURATION_MARKER:
                        {
                        this.state = 164;
                        this.duration();
                        }
                        break;
                    case UniversalWorkoutLanguageParser.DISTANCE_MARKER:
                        {
                        this.state = 165;
                        this.distance();
                        }
                        break;
                    default:
                        throw new antlr.NoViableAltException(this);
                    }
                    }
                    }
                    this.state = 170;
                    this.errorHandler.sync(this);
                    _la = this.tokenStream.LA(1);
                } while (((((_la - 45)) & ~0x1F) === 0 && ((1 << (_la - 45)) & 243) !== 0));
                }
            }

            this.state = 174;
            this.match(UniversalWorkoutLanguageParser.MOVEMENT_TERM);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public comment(): CommentContext {
        let localContext = new CommentContext(this.context, this.state);
        this.enterRule(localContext, 20, UniversalWorkoutLanguageParser.RULE_comment);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 176;
            _la = this.tokenStream.LA(1);
            if(!(_la === 18 || _la === 19)) {
            this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_iden(): Workout_idenContext {
        let localContext = new Workout_idenContext(this.context, this.state);
        this.enterRule(localContext, 22, UniversalWorkoutLanguageParser.RULE_workout_iden);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 178;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_IDENTIFIER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attr_id(): Workout_attr_idContext {
        let localContext = new Workout_attr_idContext(this.context, this.state);
        this.enterRule(localContext, 24, UniversalWorkoutLanguageParser.RULE_workout_attr_id);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 180;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_IDEN);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attr_val_str(): Workout_attr_val_strContext {
        let localContext = new Workout_attr_val_strContext(this.context, this.state);
        this.enterRule(localContext, 26, UniversalWorkoutLanguageParser.RULE_workout_attr_val_str);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 182;
            _la = this.tokenStream.LA(1);
            if(!(_la === 29 || _la === 30)) {
            this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attr_val_number(): Workout_attr_val_numberContext {
        let localContext = new Workout_attr_val_numberContext(this.context, this.state);
        this.enterRule(localContext, 28, UniversalWorkoutLanguageParser.RULE_workout_attr_val_number);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 184;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_NUMBER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attr_val_time(): Workout_attr_val_timeContext {
        let localContext = new Workout_attr_val_timeContext(this.context, this.state);
        this.enterRule(localContext, 30, UniversalWorkoutLanguageParser.RULE_workout_attr_val_time);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 186;
            _la = this.tokenStream.LA(1);
            if(!(_la === 31 || _la === 33)) {
            this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attr_val(): Workout_attr_valContext {
        let localContext = new Workout_attr_valContext(this.context, this.state);
        this.enterRule(localContext, 32, UniversalWorkoutLanguageParser.RULE_workout_attr_val);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 188;
            _la = this.tokenStream.LA(1);
            if(!(((((_la - 29)) & ~0x1F) === 0 && ((1 << (_la - 29)) & 31) !== 0))) {
            this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_attribute(): Workout_attributeContext {
        let localContext = new Workout_attributeContext(this.context, this.state);
        this.enterRule(localContext, 34, UniversalWorkoutLanguageParser.RULE_workout_attribute);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 190;
            this.workout_attr_id();
            this.state = 191;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_COLON);
            this.state = 192;
            this.workout_attr_val();
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout_comment(): Workout_commentContext {
        let localContext = new Workout_commentContext(this.context, this.state);
        this.enterRule(localContext, 36, UniversalWorkoutLanguageParser.RULE_workout_comment);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 194;
            _la = this.tokenStream.LA(1);
            if(!(_la === 18 || _la === 19)) {
            this.errorHandler.recoverInline(this);
            }
            else {
                this.errorHandler.reportMatch(this);
                this.consume();
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public workout(): WorkoutContext {
        let localContext = new WorkoutContext(this.context, this.state);
        this.enterRule(localContext, 38, UniversalWorkoutLanguageParser.RULE_workout);
        let _la: number;
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 196;
            this.workout_iden();
            this.state = 198;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 29) {
                {
                this.state = 197;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_STRING);
                }
            }

            this.state = 200;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_PAREN_L);
            this.state = 202;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 34) {
                {
                this.state = 201;
                localContext._workout_attribute = this.workout_attribute();
                localContext._attrs.push(localContext._workout_attribute!);
                }
            }

            this.state = 208;
            this.errorHandler.sync(this);
            alternative = this.interpreter.adaptivePredict(this.tokenStream, 13, this.context);
            while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                if (alternative === 1) {
                    {
                    {
                    this.state = 204;
                    this.match(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
                    this.state = 205;
                    localContext._workout_attribute = this.workout_attribute();
                    localContext._attrs.push(localContext._workout_attribute!);
                    }
                    }
                }
                this.state = 210;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 13, this.context);
            }
            this.state = 212;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 27) {
                {
                this.state = 211;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
                }
            }

            this.state = 214;
            this.match(UniversalWorkoutLanguageParser.WORKOUT_PAREN_R);
            this.state = 219;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 786448) !== 0)) {
                {
                this.state = 217;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
                    {
                    this.state = 215;
                    localContext._movement = this.movement();
                    localContext._movements.push(localContext._movement!);
                    }
                    break;
                case UniversalWorkoutLanguageParser.INLINE_COMMENT:
                case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                    {
                    this.state = 216;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                }
                this.state = 221;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public shorthand_strength(): Shorthand_strengthContext {
        let localContext = new Shorthand_strengthContext(this.context, this.state);
        this.enterRule(localContext, 40, UniversalWorkoutLanguageParser.RULE_shorthand_strength);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 223;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 11) {
                {
                this.state = 222;
                localContext._name = this.match(UniversalWorkoutLanguageParser.STRING);
                }
            }

            this.state = 227;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 10) {
                {
                this.state = 225;
                this.match(UniversalWorkoutLanguageParser.REST_KW);
                this.state = 226;
                localContext._STRING = this.match(UniversalWorkoutLanguageParser.STRING);
                localContext._rest.push(localContext._STRING!);
                }
            }

            this.state = 231;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            do {
                {
                this.state = 231;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
                    {
                    this.state = 229;
                    localContext._movement = this.movement();
                    localContext._movements.push(localContext._movement!);
                    }
                    break;
                case UniversalWorkoutLanguageParser.INLINE_COMMENT:
                case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                    {
                    this.state = 230;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                }
                this.state = 233;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            } while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 786448) !== 0));
            this.state = 237;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 10) {
                {
                this.state = 235;
                this.match(UniversalWorkoutLanguageParser.REST_KW);
                this.state = 236;
                localContext._STRING = this.match(UniversalWorkoutLanguageParser.STRING);
                localContext._rest.push(localContext._STRING!);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public shorthand_generic(): Shorthand_genericContext {
        let localContext = new Shorthand_genericContext(this.context, this.state);
        this.enterRule(localContext, 42, UniversalWorkoutLanguageParser.RULE_shorthand_generic);
        let _la: number;
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 240;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 11) {
                {
                this.state = 239;
                this.match(UniversalWorkoutLanguageParser.STRING);
                }
            }

            this.state = 242;
            this.match(UniversalWorkoutLanguageParser.GENERIC_STRING);
            this.state = 258;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 13) {
                {
                this.state = 243;
                this.match(UniversalWorkoutLanguageParser.PAREN_L);
                this.state = 245;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                if (_la === 34) {
                    {
                    this.state = 244;
                    localContext._workout_attribute = this.workout_attribute();
                    localContext._attrs.push(localContext._workout_attribute!);
                    }
                }

                this.state = 251;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 24, this.context);
                while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                    if (alternative === 1) {
                        {
                        {
                        this.state = 247;
                        this.match(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
                        this.state = 248;
                        localContext._workout_attribute = this.workout_attribute();
                        localContext._attrs.push(localContext._workout_attribute!);
                        }
                        }
                    }
                    this.state = 253;
                    this.errorHandler.sync(this);
                    alternative = this.interpreter.adaptivePredict(this.tokenStream, 24, this.context);
                }
                this.state = 255;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
                if (_la === 27) {
                    {
                    this.state = 254;
                    this.match(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
                    }
                }

                this.state = 257;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_PAREN_R);
                }
            }

            this.state = 264;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 786448) !== 0)) {
                {
                this.state = 262;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
                    {
                    this.state = 260;
                    localContext._movement = this.movement();
                    localContext._movements.push(localContext._movement!);
                    }
                    break;
                case UniversalWorkoutLanguageParser.INLINE_COMMENT:
                case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                    {
                    this.state = 261;
                    localContext._workout_comment = this.workout_comment();
                    localContext._comments.push(localContext._workout_comment!);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                }
                this.state = 266;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public any_workout(): Any_workoutContext {
        let localContext = new Any_workoutContext(this.context, this.state);
        this.enterRule(localContext, 44, UniversalWorkoutLanguageParser.RULE_any_workout);
        try {
            this.state = 270;
            this.errorHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this.tokenStream, 29, this.context) ) {
            case 1:
                this.enterOuterAlt(localContext, 1);
                {
                this.state = 267;
                this.workout();
                }
                break;
            case 2:
                this.enterOuterAlt(localContext, 2);
                {
                this.state = 268;
                this.shorthand_strength();
                }
                break;
            case 3:
                this.enterOuterAlt(localContext, 3);
                {
                this.state = 269;
                this.shorthand_generic();
                }
                break;
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public alternation(): AlternationContext {
        let localContext = new AlternationContext(this.context, this.state);
        this.enterRule(localContext, 46, UniversalWorkoutLanguageParser.RULE_alternation);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 272;
            this.match(UniversalWorkoutLanguageParser.ALTERNATION_NUMBER);
            this.state = 273;
            this.match(UniversalWorkoutLanguageParser.ALT_KW);
            this.state = 274;
            this.match(UniversalWorkoutLanguageParser.LBRACE);
            this.state = 276;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if ((((_la) & ~0x1F) === 0 && ((1 << _la) & 1842192) !== 0)) {
                {
                this.state = 275;
                localContext._any_workout = this.any_workout();
                localContext._workouts.push(localContext._any_workout!);
                }
            }

            this.state = 282;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 3) {
                {
                {
                this.state = 278;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                this.state = 279;
                localContext._any_workout = this.any_workout();
                localContext._workouts.push(localContext._any_workout!);
                }
                }
                this.state = 284;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 285;
            this.match(UniversalWorkoutLanguageParser.RBRACE);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public option_block_title(): Option_block_titleContext {
        let localContext = new Option_block_titleContext(this.context, this.state);
        this.enterRule(localContext, 48, UniversalWorkoutLanguageParser.RULE_option_block_title);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 287;
            this.match(UniversalWorkoutLanguageParser.CHOICE_KW);
            this.state = 289;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 36) {
                {
                this.state = 288;
                this.match(UniversalWorkoutLanguageParser.CHOICE_NAME);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public option_title(): Option_titleContext {
        let localContext = new Option_titleContext(this.context, this.state);
        this.enterRule(localContext, 50, UniversalWorkoutLanguageParser.RULE_option_title);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 291;
            this.match(UniversalWorkoutLanguageParser.OPTION_KW);
            this.state = 292;
            this.match(UniversalWorkoutLanguageParser.CHOICE_NAME);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public option(): OptionContext {
        let localContext = new OptionContext(this.context, this.state);
        this.enterRule(localContext, 52, UniversalWorkoutLanguageParser.RULE_option);
        let _la: number;
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 294;
            this.match(UniversalWorkoutLanguageParser.OPTION_KW);
            this.state = 295;
            this.match(UniversalWorkoutLanguageParser.CHOICE_NAME);
            this.state = 296;
            this.match(UniversalWorkoutLanguageParser.COLON);
            this.state = 299;
            this.errorHandler.sync(this);
            switch (this.tokenStream.LA(1)) {
            case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
            case UniversalWorkoutLanguageParser.REST_KW:
            case UniversalWorkoutLanguageParser.STRING:
            case UniversalWorkoutLanguageParser.GENERIC_STRING:
            case UniversalWorkoutLanguageParser.INLINE_COMMENT:
            case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
            case UniversalWorkoutLanguageParser.WORKOUT_IDENTIFIER:
                {
                this.state = 297;
                this.any_workout();
                }
                break;
            case UniversalWorkoutLanguageParser.ALTERNATION_NUMBER:
                {
                this.state = 298;
                this.alternation();
                }
                break;
            default:
                throw new antlr.NoViableAltException(this);
            }
            this.state = 308;
            this.errorHandler.sync(this);
            alternative = this.interpreter.adaptivePredict(this.tokenStream, 35, this.context);
            while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                if (alternative === 1) {
                    {
                    {
                    this.state = 301;
                    this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                    this.state = 304;
                    this.errorHandler.sync(this);
                    switch (this.tokenStream.LA(1)) {
                    case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
                    case UniversalWorkoutLanguageParser.REST_KW:
                    case UniversalWorkoutLanguageParser.STRING:
                    case UniversalWorkoutLanguageParser.GENERIC_STRING:
                    case UniversalWorkoutLanguageParser.INLINE_COMMENT:
                    case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                    case UniversalWorkoutLanguageParser.WORKOUT_IDENTIFIER:
                        {
                        this.state = 302;
                        this.any_workout();
                        }
                        break;
                    case UniversalWorkoutLanguageParser.ALTERNATION_NUMBER:
                        {
                        this.state = 303;
                        this.alternation();
                        }
                        break;
                    default:
                        throw new antlr.NoViableAltException(this);
                    }
                    }
                    }
                }
                this.state = 310;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 35, this.context);
            }
            this.state = 312;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 3) {
                {
                this.state = 311;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public option_block(): Option_blockContext {
        let localContext = new Option_blockContext(this.context, this.state);
        this.enterRule(localContext, 54, UniversalWorkoutLanguageParser.RULE_option_block);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 314;
            this.match(UniversalWorkoutLanguageParser.CHOICE_KW);
            this.state = 316;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 36) {
                {
                this.state = 315;
                this.match(UniversalWorkoutLanguageParser.CHOICE_NAME);
                }
            }

            this.state = 318;
            this.match(UniversalWorkoutLanguageParser.LBRACE);
            this.state = 320;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            do {
                {
                {
                this.state = 319;
                localContext._option = this.option();
                localContext._options.push(localContext._option!);
                }
                }
                this.state = 322;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            } while (_la === 6);
            this.state = 324;
            this.match(UniversalWorkoutLanguageParser.RBRACE);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public wod(): WodContext {
        let localContext = new WodContext(this.context, this.state);
        this.enterRule(localContext, 56, UniversalWorkoutLanguageParser.RULE_wod);
        let _la: number;
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 335;
            this.errorHandler.sync(this);
            alternative = this.interpreter.adaptivePredict(this.tokenStream, 40, this.context);
            while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                if (alternative === 1) {
                    {
                    {
                    this.state = 329;
                    this.errorHandler.sync(this);
                    switch (this.tokenStream.LA(1)) {
                    case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
                    case UniversalWorkoutLanguageParser.REST_KW:
                    case UniversalWorkoutLanguageParser.STRING:
                    case UniversalWorkoutLanguageParser.GENERIC_STRING:
                    case UniversalWorkoutLanguageParser.INLINE_COMMENT:
                    case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
                    case UniversalWorkoutLanguageParser.WORKOUT_IDENTIFIER:
                        {
                        this.state = 326;
                        this.any_workout();
                        }
                        break;
                    case UniversalWorkoutLanguageParser.CHOICE_KW:
                        {
                        this.state = 327;
                        this.option_block();
                        }
                        break;
                    case UniversalWorkoutLanguageParser.ALTERNATION_NUMBER:
                        {
                        this.state = 328;
                        this.alternation();
                        }
                        break;
                    default:
                        throw new antlr.NoViableAltException(this);
                    }
                    this.state = 331;
                    this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                    }
                    }
                }
                this.state = 337;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 40, this.context);
            }
            this.state = 341;
            this.errorHandler.sync(this);
            switch (this.tokenStream.LA(1)) {
            case UniversalWorkoutLanguageParser.MOVEMENT_MARKER:
            case UniversalWorkoutLanguageParser.REST_KW:
            case UniversalWorkoutLanguageParser.STRING:
            case UniversalWorkoutLanguageParser.GENERIC_STRING:
            case UniversalWorkoutLanguageParser.INLINE_COMMENT:
            case UniversalWorkoutLanguageParser.MULTILINE_COMMENT:
            case UniversalWorkoutLanguageParser.WORKOUT_IDENTIFIER:
                {
                this.state = 338;
                this.any_workout();
                }
                break;
            case UniversalWorkoutLanguageParser.CHOICE_KW:
                {
                this.state = 339;
                this.option_block();
                }
                break;
            case UniversalWorkoutLanguageParser.ALTERNATION_NUMBER:
                {
                this.state = 340;
                this.alternation();
                }
                break;
            default:
                throw new antlr.NoViableAltException(this);
            }
            this.state = 344;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 3) {
                {
                this.state = 343;
                this.match(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_id(): Def_idContext {
        let localContext = new Def_idContext(this.context, this.state);
        this.enterRule(localContext, 58, UniversalWorkoutLanguageParser.RULE_def_id);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 346;
            this.match(UniversalWorkoutLanguageParser.DEF_IDENTIFIER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_super_kw(): Def_super_kwContext {
        let localContext = new Def_super_kwContext(this.context, this.state);
        this.enterRule(localContext, 60, UniversalWorkoutLanguageParser.RULE_def_super_kw);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 348;
            this.match(UniversalWorkoutLanguageParser.DEF_SUPER_KW);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_super_param(): Def_super_paramContext {
        let localContext = new Def_super_paramContext(this.context, this.state);
        this.enterRule(localContext, 62, UniversalWorkoutLanguageParser.RULE_def_super_param);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 350;
            this.match(UniversalWorkoutLanguageParser.DEF_REGEX);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_super(): Def_superContext {
        let localContext = new Def_superContext(this.context, this.state);
        this.enterRule(localContext, 64, UniversalWorkoutLanguageParser.RULE_def_super);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 352;
            this.def_super_kw();
            this.state = 353;
            this.match(UniversalWorkoutLanguageParser.DEF_PAREN_L);
            this.state = 355;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 60) {
                {
                this.state = 354;
                this.def_super_param();
                }
            }

            this.state = 357;
            this.match(UniversalWorkoutLanguageParser.DEF_PAREN_R);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_param_name(): Def_param_nameContext {
        let localContext = new Def_param_nameContext(this.context, this.state);
        this.enterRule(localContext, 66, UniversalWorkoutLanguageParser.RULE_def_param_name);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 359;
            this.match(UniversalWorkoutLanguageParser.DEF_REGEX);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_param_enum_entry(): Def_param_enum_entryContext {
        let localContext = new Def_param_enum_entryContext(this.context, this.state);
        this.enterRule(localContext, 68, UniversalWorkoutLanguageParser.RULE_def_param_enum_entry);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 361;
            this.match(UniversalWorkoutLanguageParser.DEF_IDENTIFIER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_param_enum(): Def_param_enumContext {
        let localContext = new Def_param_enumContext(this.context, this.state);
        this.enterRule(localContext, 70, UniversalWorkoutLanguageParser.RULE_def_param_enum);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 363;
            localContext._def_param_enum_entry = this.def_param_enum_entry();
            localContext._def_param_enum_entries.push(localContext._def_param_enum_entry!);
            this.state = 368;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 65) {
                {
                {
                this.state = 364;
                this.match(UniversalWorkoutLanguageParser.DEF_COMMA);
                this.state = 365;
                localContext._def_param_enum_entry = this.def_param_enum_entry();
                localContext._def_param_enum_entries.push(localContext._def_param_enum_entry!);
                }
                }
                this.state = 370;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_param_type(): Def_param_typeContext {
        let localContext = new Def_param_typeContext(this.context, this.state);
        this.enterRule(localContext, 72, UniversalWorkoutLanguageParser.RULE_def_param_type);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 377;
            this.errorHandler.sync(this);
            switch (this.tokenStream.LA(1)) {
            case UniversalWorkoutLanguageParser.DEF_TYPES:
                {
                this.state = 371;
                this.match(UniversalWorkoutLanguageParser.DEF_TYPES);
                }
                break;
            case UniversalWorkoutLanguageParser.DEF_ENUM_KW:
                {
                {
                this.state = 372;
                this.match(UniversalWorkoutLanguageParser.DEF_ENUM_KW);
                this.state = 373;
                this.match(UniversalWorkoutLanguageParser.DEF_PAREN_L);
                this.state = 374;
                this.def_param_enum();
                this.state = 375;
                this.match(UniversalWorkoutLanguageParser.DEF_PAREN_R);
                }
                }
                break;
            default:
                throw new antlr.NoViableAltException(this);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public def_param(): Def_paramContext {
        let localContext = new Def_paramContext(this.context, this.state);
        this.enterRule(localContext, 74, UniversalWorkoutLanguageParser.RULE_def_param);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 379;
            this.def_param_name();
            this.state = 380;
            this.match(UniversalWorkoutLanguageParser.DEF_COLON);
            {
            this.state = 381;
            this.def_param_type();
            }
            this.state = 383;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 64) {
                {
                this.state = 382;
                this.match(UniversalWorkoutLanguageParser.DEF_OPTIONAL);
                }
            }

            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public definition(): DefinitionContext {
        let localContext = new DefinitionContext(this.context, this.state);
        this.enterRule(localContext, 76, UniversalWorkoutLanguageParser.RULE_definition);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 385;
            this.match(UniversalWorkoutLanguageParser.DEF_KW);
            this.state = 386;
            this.def_id();
            this.state = 389;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 63) {
                {
                this.state = 387;
                this.match(UniversalWorkoutLanguageParser.DEF_COLON);
                this.state = 388;
                this.def_super();
                }
            }

            this.state = 391;
            this.match(UniversalWorkoutLanguageParser.DEF_BRACE_L);
            this.state = 392;
            this.def_param();
            this.state = 397;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 65) {
                {
                {
                this.state = 393;
                this.match(UniversalWorkoutLanguageParser.DEF_COMMA);
                this.state = 394;
                this.def_param();
                }
                }
                this.state = 399;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 400;
            this.match(UniversalWorkoutLanguageParser.DEF_BRACE_R);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public defs(): DefsContext {
        let localContext = new DefsContext(this.context, this.state);
        this.enterRule(localContext, 78, UniversalWorkoutLanguageParser.RULE_defs);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 408;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while ((((_la) & ~0x1F) === 0 && ((1 << _la) & 774) !== 0)) {
                {
                this.state = 406;
                this.errorHandler.sync(this);
                switch (this.tokenStream.LA(1)) {
                case UniversalWorkoutLanguageParser.DEF_KW:
                    {
                    this.state = 402;
                    this.definition();
                    }
                    break;
                case UniversalWorkoutLanguageParser.NEW_MOV_KW:
                    {
                    this.state = 403;
                    this.new_movement();
                    }
                    break;
                case UniversalWorkoutLanguageParser.WS:
                    {
                    this.state = 404;
                    this.match(UniversalWorkoutLanguageParser.WS);
                    }
                    break;
                case UniversalWorkoutLanguageParser.NL:
                    {
                    this.state = 405;
                    this.match(UniversalWorkoutLanguageParser.NL);
                    }
                    break;
                default:
                    throw new antlr.NoViableAltException(this);
                }
                }
                this.state = 410;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 411;
            this.match(UniversalWorkoutLanguageParser.EOF);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public new_movement_name(): New_movement_nameContext {
        let localContext = new New_movement_nameContext(this.context, this.state);
        this.enterRule(localContext, 80, UniversalWorkoutLanguageParser.RULE_new_movement_name);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 413;
            this.match(UniversalWorkoutLanguageParser.NEW_MOV_IDENTIFIER);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public new_movement_params(): New_movement_paramsContext {
        let localContext = new New_movement_paramsContext(this.context, this.state);
        this.enterRule(localContext, 82, UniversalWorkoutLanguageParser.RULE_new_movement_params);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 415;
            localContext._NEW_MOV_PARAM = this.match(UniversalWorkoutLanguageParser.NEW_MOV_PARAM);
            localContext._nmp.push(localContext._NEW_MOV_PARAM!);
            {
            this.state = 416;
            this.match(UniversalWorkoutLanguageParser.NEW_MOV_COMMA);
            this.state = 417;
            localContext._NEW_MOV_PARAM = this.match(UniversalWorkoutLanguageParser.NEW_MOV_PARAM);
            localContext._nmp.push(localContext._NEW_MOV_PARAM!);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public new_movement(): New_movementContext {
        let localContext = new New_movementContext(this.context, this.state);
        this.enterRule(localContext, 84, UniversalWorkoutLanguageParser.RULE_new_movement);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 419;
            this.match(UniversalWorkoutLanguageParser.NEW_MOV_KW);
            this.state = 420;
            this.new_movement_name();
            this.state = 421;
            this.match(UniversalWorkoutLanguageParser.NEW_MOV_COLON);
            this.state = 422;
            this.new_movement_params();
            this.state = 423;
            this.match(UniversalWorkoutLanguageParser.NEW_MOV_SEMI);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public day(): DayContext {
        let localContext = new DayContext(this.context, this.state);
        this.enterRule(localContext, 86, UniversalWorkoutLanguageParser.RULE_day);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 426;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if (_la === 7) {
                {
                this.state = 425;
                this.match(UniversalWorkoutLanguageParser.WOD_KW);
                }
            }

            this.state = 428;
            localContext._wod = this.wod();
            localContext._wods.push(localContext._wod!);
            this.state = 433;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 7) {
                {
                {
                this.state = 429;
                this.match(UniversalWorkoutLanguageParser.WOD_KW);
                this.state = 430;
                localContext._wod = this.wod();
                localContext._wods.push(localContext._wod!);
                }
                }
                this.state = 435;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            this.state = 436;
            this.match(UniversalWorkoutLanguageParser.EOF);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }

    public static readonly _serializedATN: number[] = [
        4,1,79,439,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,2,5,7,5,2,6,7,
        6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,12,2,13,7,13,
        2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,19,2,20,
        7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,26,
        2,27,7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,33,
        7,33,2,34,7,34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,39,
        2,40,7,40,2,41,7,41,2,42,7,42,2,43,7,43,1,0,1,0,1,1,1,1,1,1,1,1,
        3,1,95,8,1,1,2,1,2,1,2,5,2,100,8,2,10,2,12,2,103,9,2,1,3,1,3,1,3,
        1,3,5,3,109,8,3,10,3,12,3,112,9,3,1,3,1,3,1,4,1,4,1,4,1,4,5,4,120,
        8,4,10,4,12,4,123,9,4,1,5,1,5,1,5,1,5,5,5,129,8,5,10,5,12,5,132,
        9,5,1,6,1,6,1,6,1,6,5,6,138,8,6,10,6,12,6,141,9,6,1,6,1,6,1,7,1,
        7,1,7,1,7,1,7,5,7,150,8,7,10,7,12,7,153,9,7,1,8,1,8,3,8,157,8,8,
        1,9,1,9,1,9,1,9,1,9,1,9,1,9,1,9,3,9,167,8,9,4,9,169,8,9,11,9,12,
        9,170,3,9,173,8,9,1,9,1,9,1,10,1,10,1,11,1,11,1,12,1,12,1,13,1,13,
        1,14,1,14,1,15,1,15,1,16,1,16,1,17,1,17,1,17,1,17,1,18,1,18,1,19,
        1,19,3,19,199,8,19,1,19,1,19,3,19,203,8,19,1,19,1,19,5,19,207,8,
        19,10,19,12,19,210,9,19,1,19,3,19,213,8,19,1,19,1,19,1,19,5,19,218,
        8,19,10,19,12,19,221,9,19,1,20,3,20,224,8,20,1,20,1,20,3,20,228,
        8,20,1,20,1,20,4,20,232,8,20,11,20,12,20,233,1,20,1,20,3,20,238,
        8,20,1,21,3,21,241,8,21,1,21,1,21,1,21,3,21,246,8,21,1,21,1,21,5,
        21,250,8,21,10,21,12,21,253,9,21,1,21,3,21,256,8,21,1,21,3,21,259,
        8,21,1,21,1,21,5,21,263,8,21,10,21,12,21,266,9,21,1,22,1,22,1,22,
        3,22,271,8,22,1,23,1,23,1,23,1,23,3,23,277,8,23,1,23,1,23,5,23,281,
        8,23,10,23,12,23,284,9,23,1,23,1,23,1,24,1,24,3,24,290,8,24,1,25,
        1,25,1,25,1,26,1,26,1,26,1,26,1,26,3,26,300,8,26,1,26,1,26,1,26,
        3,26,305,8,26,5,26,307,8,26,10,26,12,26,310,9,26,1,26,3,26,313,8,
        26,1,27,1,27,3,27,317,8,27,1,27,1,27,4,27,321,8,27,11,27,12,27,322,
        1,27,1,27,1,28,1,28,1,28,3,28,330,8,28,1,28,1,28,5,28,334,8,28,10,
        28,12,28,337,9,28,1,28,1,28,1,28,3,28,342,8,28,1,28,3,28,345,8,28,
        1,29,1,29,1,30,1,30,1,31,1,31,1,32,1,32,1,32,3,32,356,8,32,1,32,
        1,32,1,33,1,33,1,34,1,34,1,35,1,35,1,35,5,35,367,8,35,10,35,12,35,
        370,9,35,1,36,1,36,1,36,1,36,1,36,1,36,3,36,378,8,36,1,37,1,37,1,
        37,1,37,3,37,384,8,37,1,38,1,38,1,38,1,38,3,38,390,8,38,1,38,1,38,
        1,38,1,38,5,38,396,8,38,10,38,12,38,399,9,38,1,38,1,38,1,39,1,39,
        1,39,1,39,5,39,407,8,39,10,39,12,39,410,9,39,1,39,1,39,1,40,1,40,
        1,41,1,41,1,41,1,41,1,42,1,42,1,42,1,42,1,42,1,42,1,43,3,43,427,
        8,43,1,43,1,43,1,43,5,43,432,8,43,10,43,12,43,435,9,43,1,43,1,43,
        1,43,0,0,44,0,2,4,6,8,10,12,14,16,18,20,22,24,26,28,30,32,34,36,
        38,40,42,44,46,48,50,52,54,56,58,60,62,64,66,68,70,72,74,76,78,80,
        82,84,86,0,6,1,0,45,46,2,0,45,45,54,54,1,0,18,19,1,0,29,30,2,0,31,
        31,33,33,1,0,29,33,456,0,88,1,0,0,0,2,94,1,0,0,0,4,96,1,0,0,0,6,
        104,1,0,0,0,8,115,1,0,0,0,10,124,1,0,0,0,12,133,1,0,0,0,14,144,1,
        0,0,0,16,156,1,0,0,0,18,158,1,0,0,0,20,176,1,0,0,0,22,178,1,0,0,
        0,24,180,1,0,0,0,26,182,1,0,0,0,28,184,1,0,0,0,30,186,1,0,0,0,32,
        188,1,0,0,0,34,190,1,0,0,0,36,194,1,0,0,0,38,196,1,0,0,0,40,223,
        1,0,0,0,42,240,1,0,0,0,44,270,1,0,0,0,46,272,1,0,0,0,48,287,1,0,
        0,0,50,291,1,0,0,0,52,294,1,0,0,0,54,314,1,0,0,0,56,335,1,0,0,0,
        58,346,1,0,0,0,60,348,1,0,0,0,62,350,1,0,0,0,64,352,1,0,0,0,66,359,
        1,0,0,0,68,361,1,0,0,0,70,363,1,0,0,0,72,377,1,0,0,0,74,379,1,0,
        0,0,76,385,1,0,0,0,78,408,1,0,0,0,80,413,1,0,0,0,82,415,1,0,0,0,
        84,419,1,0,0,0,86,426,1,0,0,0,88,89,5,22,0,0,89,1,1,0,0,0,90,95,
        5,47,0,0,91,95,5,46,0,0,92,93,5,45,0,0,93,95,5,54,0,0,94,90,1,0,
        0,0,94,91,1,0,0,0,94,92,1,0,0,0,95,3,1,0,0,0,96,101,7,0,0,0,97,98,
        5,48,0,0,98,100,7,0,0,0,99,97,1,0,0,0,100,103,1,0,0,0,101,99,1,0,
        0,0,101,102,1,0,0,0,102,5,1,0,0,0,103,101,1,0,0,0,104,105,5,49,0,
        0,105,110,7,0,0,0,106,107,5,48,0,0,107,109,7,0,0,0,108,106,1,0,0,
        0,109,112,1,0,0,0,110,108,1,0,0,0,110,111,1,0,0,0,111,113,1,0,0,
        0,112,110,1,0,0,0,113,114,5,54,0,0,114,7,1,0,0,0,115,116,5,50,0,
        0,116,121,7,1,0,0,117,118,5,48,0,0,118,120,7,1,0,0,119,117,1,0,0,
        0,120,123,1,0,0,0,121,119,1,0,0,0,121,122,1,0,0,0,122,9,1,0,0,0,
        123,121,1,0,0,0,124,125,5,51,0,0,125,130,3,2,1,0,126,127,5,48,0,
        0,127,129,3,2,1,0,128,126,1,0,0,0,129,132,1,0,0,0,130,128,1,0,0,
        0,130,131,1,0,0,0,131,11,1,0,0,0,132,130,1,0,0,0,133,134,5,52,0,
        0,134,139,7,0,0,0,135,136,5,48,0,0,136,138,7,0,0,0,137,135,1,0,0,
        0,138,141,1,0,0,0,139,137,1,0,0,0,139,140,1,0,0,0,140,142,1,0,0,
        0,141,139,1,0,0,0,142,143,5,54,0,0,143,13,1,0,0,0,144,145,5,39,0,
        0,145,151,5,38,0,0,146,147,5,40,0,0,147,148,5,39,0,0,148,150,5,38,
        0,0,149,146,1,0,0,0,150,153,1,0,0,0,151,149,1,0,0,0,151,152,1,0,
        0,0,152,15,1,0,0,0,153,151,1,0,0,0,154,157,5,38,0,0,155,157,3,14,
        7,0,156,154,1,0,0,0,156,155,1,0,0,0,157,17,1,0,0,0,158,159,5,4,0,
        0,159,160,3,16,8,0,160,172,5,42,0,0,161,167,3,4,2,0,162,167,3,6,
        3,0,163,167,3,8,4,0,164,167,3,10,5,0,165,167,3,12,6,0,166,161,1,
        0,0,0,166,162,1,0,0,0,166,163,1,0,0,0,166,164,1,0,0,0,166,165,1,
        0,0,0,167,169,1,0,0,0,168,166,1,0,0,0,169,170,1,0,0,0,170,168,1,
        0,0,0,170,171,1,0,0,0,171,173,1,0,0,0,172,168,1,0,0,0,172,173,1,
        0,0,0,173,174,1,0,0,0,174,175,5,53,0,0,175,19,1,0,0,0,176,177,7,
        2,0,0,177,21,1,0,0,0,178,179,5,20,0,0,179,23,1,0,0,0,180,181,5,34,
        0,0,181,25,1,0,0,0,182,183,7,3,0,0,183,27,1,0,0,0,184,185,5,32,0,
        0,185,29,1,0,0,0,186,187,7,4,0,0,187,31,1,0,0,0,188,189,7,5,0,0,
        189,33,1,0,0,0,190,191,3,24,12,0,191,192,5,26,0,0,192,193,3,32,16,
        0,193,35,1,0,0,0,194,195,7,2,0,0,195,37,1,0,0,0,196,198,3,22,11,
        0,197,199,5,29,0,0,198,197,1,0,0,0,198,199,1,0,0,0,199,200,1,0,0,
        0,200,202,5,24,0,0,201,203,3,34,17,0,202,201,1,0,0,0,202,203,1,0,
        0,0,203,208,1,0,0,0,204,205,5,27,0,0,205,207,3,34,17,0,206,204,1,
        0,0,0,207,210,1,0,0,0,208,206,1,0,0,0,208,209,1,0,0,0,209,212,1,
        0,0,0,210,208,1,0,0,0,211,213,5,27,0,0,212,211,1,0,0,0,212,213,1,
        0,0,0,213,214,1,0,0,0,214,219,5,25,0,0,215,218,3,18,9,0,216,218,
        3,36,18,0,217,215,1,0,0,0,217,216,1,0,0,0,218,221,1,0,0,0,219,217,
        1,0,0,0,219,220,1,0,0,0,220,39,1,0,0,0,221,219,1,0,0,0,222,224,5,
        11,0,0,223,222,1,0,0,0,223,224,1,0,0,0,224,227,1,0,0,0,225,226,5,
        10,0,0,226,228,5,11,0,0,227,225,1,0,0,0,227,228,1,0,0,0,228,231,
        1,0,0,0,229,232,3,18,9,0,230,232,3,36,18,0,231,229,1,0,0,0,231,230,
        1,0,0,0,232,233,1,0,0,0,233,231,1,0,0,0,233,234,1,0,0,0,234,237,
        1,0,0,0,235,236,5,10,0,0,236,238,5,11,0,0,237,235,1,0,0,0,237,238,
        1,0,0,0,238,41,1,0,0,0,239,241,5,11,0,0,240,239,1,0,0,0,240,241,
        1,0,0,0,241,242,1,0,0,0,242,258,5,12,0,0,243,245,5,13,0,0,244,246,
        3,34,17,0,245,244,1,0,0,0,245,246,1,0,0,0,246,251,1,0,0,0,247,248,
        5,27,0,0,248,250,3,34,17,0,249,247,1,0,0,0,250,253,1,0,0,0,251,249,
        1,0,0,0,251,252,1,0,0,0,252,255,1,0,0,0,253,251,1,0,0,0,254,256,
        5,27,0,0,255,254,1,0,0,0,255,256,1,0,0,0,256,257,1,0,0,0,257,259,
        5,25,0,0,258,243,1,0,0,0,258,259,1,0,0,0,259,264,1,0,0,0,260,263,
        3,18,9,0,261,263,3,36,18,0,262,260,1,0,0,0,262,261,1,0,0,0,263,266,
        1,0,0,0,264,262,1,0,0,0,264,265,1,0,0,0,265,43,1,0,0,0,266,264,1,
        0,0,0,267,271,3,38,19,0,268,271,3,40,20,0,269,271,3,42,21,0,270,
        267,1,0,0,0,270,268,1,0,0,0,270,269,1,0,0,0,271,45,1,0,0,0,272,273,
        5,21,0,0,273,274,5,57,0,0,274,276,5,15,0,0,275,277,3,44,22,0,276,
        275,1,0,0,0,276,277,1,0,0,0,277,282,1,0,0,0,278,279,5,3,0,0,279,
        281,3,44,22,0,280,278,1,0,0,0,281,284,1,0,0,0,282,280,1,0,0,0,282,
        283,1,0,0,0,283,285,1,0,0,0,284,282,1,0,0,0,285,286,5,16,0,0,286,
        47,1,0,0,0,287,289,5,5,0,0,288,290,5,36,0,0,289,288,1,0,0,0,289,
        290,1,0,0,0,290,49,1,0,0,0,291,292,5,6,0,0,292,293,5,36,0,0,293,
        51,1,0,0,0,294,295,5,6,0,0,295,296,5,36,0,0,296,299,5,17,0,0,297,
        300,3,44,22,0,298,300,3,46,23,0,299,297,1,0,0,0,299,298,1,0,0,0,
        300,308,1,0,0,0,301,304,5,3,0,0,302,305,3,44,22,0,303,305,3,46,23,
        0,304,302,1,0,0,0,304,303,1,0,0,0,305,307,1,0,0,0,306,301,1,0,0,
        0,307,310,1,0,0,0,308,306,1,0,0,0,308,309,1,0,0,0,309,312,1,0,0,
        0,310,308,1,0,0,0,311,313,5,3,0,0,312,311,1,0,0,0,312,313,1,0,0,
        0,313,53,1,0,0,0,314,316,5,5,0,0,315,317,5,36,0,0,316,315,1,0,0,
        0,316,317,1,0,0,0,317,318,1,0,0,0,318,320,5,15,0,0,319,321,3,52,
        26,0,320,319,1,0,0,0,321,322,1,0,0,0,322,320,1,0,0,0,322,323,1,0,
        0,0,323,324,1,0,0,0,324,325,5,16,0,0,325,55,1,0,0,0,326,330,3,44,
        22,0,327,330,3,54,27,0,328,330,3,46,23,0,329,326,1,0,0,0,329,327,
        1,0,0,0,329,328,1,0,0,0,330,331,1,0,0,0,331,332,5,3,0,0,332,334,
        1,0,0,0,333,329,1,0,0,0,334,337,1,0,0,0,335,333,1,0,0,0,335,336,
        1,0,0,0,336,341,1,0,0,0,337,335,1,0,0,0,338,342,3,44,22,0,339,342,
        3,54,27,0,340,342,3,46,23,0,341,338,1,0,0,0,341,339,1,0,0,0,341,
        340,1,0,0,0,342,344,1,0,0,0,343,345,5,3,0,0,344,343,1,0,0,0,344,
        345,1,0,0,0,345,57,1,0,0,0,346,347,5,61,0,0,347,59,1,0,0,0,348,349,
        5,70,0,0,349,61,1,0,0,0,350,351,5,60,0,0,351,63,1,0,0,0,352,353,
        3,60,30,0,353,355,5,66,0,0,354,356,3,62,31,0,355,354,1,0,0,0,355,
        356,1,0,0,0,356,357,1,0,0,0,357,358,5,67,0,0,358,65,1,0,0,0,359,
        360,5,60,0,0,360,67,1,0,0,0,361,362,5,61,0,0,362,69,1,0,0,0,363,
        368,3,68,34,0,364,365,5,65,0,0,365,367,3,68,34,0,366,364,1,0,0,0,
        367,370,1,0,0,0,368,366,1,0,0,0,368,369,1,0,0,0,369,71,1,0,0,0,370,
        368,1,0,0,0,371,378,5,71,0,0,372,373,5,72,0,0,373,374,5,66,0,0,374,
        375,3,70,35,0,375,376,5,67,0,0,376,378,1,0,0,0,377,371,1,0,0,0,377,
        372,1,0,0,0,378,73,1,0,0,0,379,380,3,66,33,0,380,381,5,63,0,0,381,
        383,3,72,36,0,382,384,5,64,0,0,383,382,1,0,0,0,383,384,1,0,0,0,384,
        75,1,0,0,0,385,386,5,8,0,0,386,389,3,58,29,0,387,388,5,63,0,0,388,
        390,3,64,32,0,389,387,1,0,0,0,389,390,1,0,0,0,390,391,1,0,0,0,391,
        392,5,68,0,0,392,397,3,74,37,0,393,394,5,65,0,0,394,396,3,74,37,
        0,395,393,1,0,0,0,396,399,1,0,0,0,397,395,1,0,0,0,397,398,1,0,0,
        0,398,400,1,0,0,0,399,397,1,0,0,0,400,401,5,69,0,0,401,77,1,0,0,
        0,402,407,3,76,38,0,403,407,3,84,42,0,404,407,5,1,0,0,405,407,5,
        2,0,0,406,402,1,0,0,0,406,403,1,0,0,0,406,404,1,0,0,0,406,405,1,
        0,0,0,407,410,1,0,0,0,408,406,1,0,0,0,408,409,1,0,0,0,409,411,1,
        0,0,0,410,408,1,0,0,0,411,412,5,0,0,1,412,79,1,0,0,0,413,414,5,75,
        0,0,414,81,1,0,0,0,415,416,5,79,0,0,416,417,5,77,0,0,417,418,5,79,
        0,0,418,83,1,0,0,0,419,420,5,9,0,0,420,421,3,80,40,0,421,422,5,76,
        0,0,422,423,3,82,41,0,423,424,5,78,0,0,424,85,1,0,0,0,425,427,5,
        7,0,0,426,425,1,0,0,0,426,427,1,0,0,0,427,428,1,0,0,0,428,433,3,
        56,28,0,429,430,5,7,0,0,430,432,3,56,28,0,431,429,1,0,0,0,432,435,
        1,0,0,0,433,431,1,0,0,0,433,434,1,0,0,0,434,436,1,0,0,0,435,433,
        1,0,0,0,436,437,5,0,0,1,437,87,1,0,0,0,53,94,101,110,121,130,139,
        151,156,166,170,172,198,202,208,212,217,219,223,227,231,233,237,
        240,245,251,255,258,262,264,270,276,282,289,299,304,308,312,316,
        322,329,335,341,344,355,368,377,383,389,397,406,408,426,433
    ];

    private static __ATN: antlr.ATN;
    public static get _ATN(): antlr.ATN {
        if (!UniversalWorkoutLanguageParser.__ATN) {
            UniversalWorkoutLanguageParser.__ATN = new antlr.ATNDeserializer().deserialize(UniversalWorkoutLanguageParser._serializedATN);
        }

        return UniversalWorkoutLanguageParser.__ATN;
    }


    private static readonly vocabulary = new antlr.Vocabulary(UniversalWorkoutLanguageParser.literalNames, UniversalWorkoutLanguageParser.symbolicNames, []);

    public override get vocabulary(): antlr.Vocabulary {
        return UniversalWorkoutLanguageParser.vocabulary;
    }

    private static readonly decisionsToDFA = UniversalWorkoutLanguageParser._ATN.decisionToState.map( (ds: antlr.DecisionState, index: number) => new antlr.DFA(ds, index) );
}

export class ErrorContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public ERROR_CHAR(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.ERROR_CHAR, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_error;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitError) {
            return visitor.visitError(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Mov_timeContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MC_TIME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_TIME, 0);
    }
    public MC_MAX(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, 0);
    }
    public MC_NUMBER(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, 0);
    }
    public MC_CONTENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MC_CONTENT, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_mov_time;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitMov_time) {
            return visitor.visitMov_time(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class RepsContext extends antlr.ParserRuleContext {
    public _MC_NUMBER?: Token | null;
    public _reps_a: antlr.Token[] = [];
    public _MC_MAX?: Token | null;
    public __tset47?: Token | null;
    public __tset58?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MC_NUMBER(): antlr.TerminalNode[];
    public MC_NUMBER(i: number): antlr.TerminalNode | null;
    public MC_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, i);
    	}
    }
    public MC_MAX(): antlr.TerminalNode[];
    public MC_MAX(i: number): antlr.TerminalNode | null;
    public MC_MAX(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_MAX);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, i);
    	}
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_reps;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitReps) {
            return visitor.visitReps(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class LoadContext extends antlr.ParserRuleContext {
    public _MC_NUMBER?: Token | null;
    public _loads: antlr.Token[] = [];
    public _MC_MAX?: Token | null;
    public __tset74?: Token | null;
    public __tset87?: Token | null;
    public _load_unit?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public LOAD_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.LOAD_MARKER, 0)!;
    }
    public MC_CONTENT(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MC_CONTENT, 0)!;
    }
    public MC_NUMBER(): antlr.TerminalNode[];
    public MC_NUMBER(i: number): antlr.TerminalNode | null;
    public MC_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, i);
    	}
    }
    public MC_MAX(): antlr.TerminalNode[];
    public MC_MAX(i: number): antlr.TerminalNode | null;
    public MC_MAX(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_MAX);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, i);
    	}
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_load;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitLoad) {
            return visitor.visitLoad(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class TempoContext extends antlr.ParserRuleContext {
    public _MC_CONTENT?: Token | null;
    public _tempos: antlr.Token[] = [];
    public _MC_NUMBER?: Token | null;
    public __tset107?: Token | null;
    public __tset120?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public TEMPO_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.TEMPO_MARKER, 0)!;
    }
    public MC_CONTENT(): antlr.TerminalNode[];
    public MC_CONTENT(i: number): antlr.TerminalNode | null;
    public MC_CONTENT(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_CONTENT);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_CONTENT, i);
    	}
    }
    public MC_NUMBER(): antlr.TerminalNode[];
    public MC_NUMBER(i: number): antlr.TerminalNode | null;
    public MC_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, i);
    	}
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_tempo;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitTempo) {
            return visitor.visitTempo(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class DurationContext extends antlr.ParserRuleContext {
    public _mov_time?: Mov_timeContext;
    public _durations: Mov_timeContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DURATION_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DURATION_MARKER, 0)!;
    }
    public mov_time(): Mov_timeContext[];
    public mov_time(i: number): Mov_timeContext | null;
    public mov_time(i?: number): Mov_timeContext[] | Mov_timeContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Mov_timeContext);
        }

        return this.getRuleContext(i, Mov_timeContext);
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_duration;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDuration) {
            return visitor.visitDuration(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class DistanceContext extends antlr.ParserRuleContext {
    public _MC_NUMBER?: Token | null;
    public _distances: antlr.Token[] = [];
    public _MC_MAX?: Token | null;
    public __tset157?: Token | null;
    public __tset168?: Token | null;
    public _distance_unit?: Token | null;
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DISTANCE_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DISTANCE_MARKER, 0)!;
    }
    public MC_CONTENT(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MC_CONTENT, 0)!;
    }
    public MC_NUMBER(): antlr.TerminalNode[];
    public MC_NUMBER(i: number): antlr.TerminalNode | null;
    public MC_NUMBER(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_NUMBER);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_NUMBER, i);
    	}
    }
    public MC_MAX(): antlr.TerminalNode[];
    public MC_MAX(i: number): antlr.TerminalNode | null;
    public MC_MAX(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_MAX);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_MAX, i);
    	}
    }
    public MC_SET_SEPARATOR(): antlr.TerminalNode[];
    public MC_SET_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MC_SET_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MC_SET_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_distance;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDistance) {
            return visitor.visitDistance(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Complex_movementContext extends antlr.ParserRuleContext {
    public _MOVEMENT_NAME_REPS?: Token | null;
    public _mov_reps: antlr.Token[] = [];
    public _SIMPLE_MOVEMENT?: Token | null;
    public _mov_name: antlr.Token[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MOVEMENT_NAME_REPS(): antlr.TerminalNode[];
    public MOVEMENT_NAME_REPS(i: number): antlr.TerminalNode | null;
    public MOVEMENT_NAME_REPS(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MOVEMENT_NAME_REPS);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MOVEMENT_NAME_REPS, i);
    	}
    }
    public SIMPLE_MOVEMENT(): antlr.TerminalNode[];
    public SIMPLE_MOVEMENT(i: number): antlr.TerminalNode | null;
    public SIMPLE_MOVEMENT(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT, i);
    	}
    }
    public MOVEMENT_NAME_COMPOENENT_SEPARATOR(): antlr.TerminalNode[];
    public MOVEMENT_NAME_COMPOENENT_SEPARATOR(i: number): antlr.TerminalNode | null;
    public MOVEMENT_NAME_COMPOENENT_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.MOVEMENT_NAME_COMPOENENT_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.MOVEMENT_NAME_COMPOENENT_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_complex_movement;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitComplex_movement) {
            return visitor.visitComplex_movement(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Movement_nameContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public SIMPLE_MOVEMENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.SIMPLE_MOVEMENT, 0);
    }
    public complex_movement(): Complex_movementContext | null {
        return this.getRuleContext(0, Complex_movementContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_movement_name;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitMovement_name) {
            return visitor.visitMovement_name(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class MovementContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public MOVEMENT_MARKER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MOVEMENT_MARKER, 0)!;
    }
    public movement_name(): Movement_nameContext {
        return this.getRuleContext(0, Movement_nameContext)!;
    }
    public MOVEMENT_NAME_TERMINATOR(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MOVEMENT_NAME_TERMINATOR, 0)!;
    }
    public MOVEMENT_TERM(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.MOVEMENT_TERM, 0)!;
    }
    public reps(): RepsContext[];
    public reps(i: number): RepsContext | null;
    public reps(i?: number): RepsContext[] | RepsContext | null {
        if (i === undefined) {
            return this.getRuleContexts(RepsContext);
        }

        return this.getRuleContext(i, RepsContext);
    }
    public load(): LoadContext[];
    public load(i: number): LoadContext | null;
    public load(i?: number): LoadContext[] | LoadContext | null {
        if (i === undefined) {
            return this.getRuleContexts(LoadContext);
        }

        return this.getRuleContext(i, LoadContext);
    }
    public tempo(): TempoContext[];
    public tempo(i: number): TempoContext | null;
    public tempo(i?: number): TempoContext[] | TempoContext | null {
        if (i === undefined) {
            return this.getRuleContexts(TempoContext);
        }

        return this.getRuleContext(i, TempoContext);
    }
    public duration(): DurationContext[];
    public duration(i: number): DurationContext | null;
    public duration(i?: number): DurationContext[] | DurationContext | null {
        if (i === undefined) {
            return this.getRuleContexts(DurationContext);
        }

        return this.getRuleContext(i, DurationContext);
    }
    public distance(): DistanceContext[];
    public distance(i: number): DistanceContext | null;
    public distance(i?: number): DistanceContext[] | DistanceContext | null {
        if (i === undefined) {
            return this.getRuleContexts(DistanceContext);
        }

        return this.getRuleContext(i, DistanceContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_movement;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitMovement) {
            return visitor.visitMovement(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class CommentContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public INLINE_COMMENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.INLINE_COMMENT, 0);
    }
    public MULTILINE_COMMENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MULTILINE_COMMENT, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_comment;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitComment) {
            return visitor.visitComment(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_idenContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_IDENTIFIER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_IDENTIFIER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_iden;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_iden) {
            return visitor.visitWorkout_iden(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attr_idContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_IDEN(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_IDEN, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attr_id;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attr_id) {
            return visitor.visitWorkout_attr_id(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attr_val_strContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_ML_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_ML_STRING, 0);
    }
    public WORKOUT_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_STRING, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attr_val_str;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attr_val_str) {
            return visitor.visitWorkout_attr_val_str(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attr_val_numberContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_NUMBER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_NUMBER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attr_val_number;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attr_val_number) {
            return visitor.visitWorkout_attr_val_number(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attr_val_timeContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_TIME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_TIME, 0);
    }
    public WORKOUT_UNIT_TIME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_UNIT_TIME, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attr_val_time;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attr_val_time) {
            return visitor.visitWorkout_attr_val_time(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attr_valContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public WORKOUT_ML_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_ML_STRING, 0);
    }
    public WORKOUT_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_STRING, 0);
    }
    public WORKOUT_TIME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_TIME, 0);
    }
    public WORKOUT_UNIT_TIME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_UNIT_TIME, 0);
    }
    public WORKOUT_NUMBER(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_NUMBER, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attr_val;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attr_val) {
            return visitor.visitWorkout_attr_val(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_attributeContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public workout_attr_id(): Workout_attr_idContext {
        return this.getRuleContext(0, Workout_attr_idContext)!;
    }
    public WORKOUT_COLON(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_COLON, 0)!;
    }
    public workout_attr_val(): Workout_attr_valContext {
        return this.getRuleContext(0, Workout_attr_valContext)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_attribute;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_attribute) {
            return visitor.visitWorkout_attribute(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Workout_commentContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public INLINE_COMMENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.INLINE_COMMENT, 0);
    }
    public MULTILINE_COMMENT(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.MULTILINE_COMMENT, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout_comment;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout_comment) {
            return visitor.visitWorkout_comment(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class WorkoutContext extends antlr.ParserRuleContext {
    public _workout_attribute?: Workout_attributeContext;
    public _attrs: Workout_attributeContext[] = [];
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public workout_iden(): Workout_idenContext {
        return this.getRuleContext(0, Workout_idenContext)!;
    }
    public WORKOUT_PAREN_L(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_PAREN_L, 0)!;
    }
    public WORKOUT_PAREN_R(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_PAREN_R, 0)!;
    }
    public WORKOUT_STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_STRING, 0);
    }
    public WORKOUT_COMMA(): antlr.TerminalNode[];
    public WORKOUT_COMMA(i: number): antlr.TerminalNode | null;
    public WORKOUT_COMMA(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_COMMA, i);
    	}
    }
    public workout_attribute(): Workout_attributeContext[];
    public workout_attribute(i: number): Workout_attributeContext | null;
    public workout_attribute(i?: number): Workout_attributeContext[] | Workout_attributeContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_attributeContext);
        }

        return this.getRuleContext(i, Workout_attributeContext);
    }
    public movement(): MovementContext[];
    public movement(i: number): MovementContext | null;
    public movement(i?: number): MovementContext[] | MovementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(MovementContext);
        }

        return this.getRuleContext(i, MovementContext);
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_workout;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWorkout) {
            return visitor.visitWorkout(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Shorthand_strengthContext extends antlr.ParserRuleContext {
    public _name?: Token | null;
    public _STRING?: Token | null;
    public _rest: antlr.Token[] = [];
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public REST_KW(): antlr.TerminalNode[];
    public REST_KW(i: number): antlr.TerminalNode | null;
    public REST_KW(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.REST_KW);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.REST_KW, i);
    	}
    }
    public STRING(): antlr.TerminalNode[];
    public STRING(i: number): antlr.TerminalNode | null;
    public STRING(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.STRING);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.STRING, i);
    	}
    }
    public movement(): MovementContext[];
    public movement(i: number): MovementContext | null;
    public movement(i?: number): MovementContext[] | MovementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(MovementContext);
        }

        return this.getRuleContext(i, MovementContext);
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_shorthand_strength;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitShorthand_strength) {
            return visitor.visitShorthand_strength(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Shorthand_genericContext extends antlr.ParserRuleContext {
    public _workout_attribute?: Workout_attributeContext;
    public _attrs: Workout_attributeContext[] = [];
    public _movement?: MovementContext;
    public _movements: MovementContext[] = [];
    public _workout_comment?: Workout_commentContext;
    public _comments: Workout_commentContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public GENERIC_STRING(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.GENERIC_STRING, 0)!;
    }
    public STRING(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.STRING, 0);
    }
    public PAREN_L(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.PAREN_L, 0);
    }
    public WORKOUT_PAREN_R(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_PAREN_R, 0);
    }
    public movement(): MovementContext[];
    public movement(i: number): MovementContext | null;
    public movement(i?: number): MovementContext[] | MovementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(MovementContext);
        }

        return this.getRuleContext(i, MovementContext);
    }
    public workout_comment(): Workout_commentContext[];
    public workout_comment(i: number): Workout_commentContext | null;
    public workout_comment(i?: number): Workout_commentContext[] | Workout_commentContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_commentContext);
        }

        return this.getRuleContext(i, Workout_commentContext);
    }
    public WORKOUT_COMMA(): antlr.TerminalNode[];
    public WORKOUT_COMMA(i: number): antlr.TerminalNode | null;
    public WORKOUT_COMMA(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WORKOUT_COMMA);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_COMMA, i);
    	}
    }
    public workout_attribute(): Workout_attributeContext[];
    public workout_attribute(i: number): Workout_attributeContext | null;
    public workout_attribute(i?: number): Workout_attributeContext[] | Workout_attributeContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Workout_attributeContext);
        }

        return this.getRuleContext(i, Workout_attributeContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_shorthand_generic;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitShorthand_generic) {
            return visitor.visitShorthand_generic(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Any_workoutContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public workout(): WorkoutContext | null {
        return this.getRuleContext(0, WorkoutContext);
    }
    public shorthand_strength(): Shorthand_strengthContext | null {
        return this.getRuleContext(0, Shorthand_strengthContext);
    }
    public shorthand_generic(): Shorthand_genericContext | null {
        return this.getRuleContext(0, Shorthand_genericContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_any_workout;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitAny_workout) {
            return visitor.visitAny_workout(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class AlternationContext extends antlr.ParserRuleContext {
    public _any_workout?: Any_workoutContext;
    public _workouts: Any_workoutContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public ALTERNATION_NUMBER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.ALTERNATION_NUMBER, 0)!;
    }
    public ALT_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.ALT_KW, 0)!;
    }
    public LBRACE(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.LBRACE, 0)!;
    }
    public RBRACE(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.RBRACE, 0)!;
    }
    public WORKOUT_SEPARATOR(): antlr.TerminalNode[];
    public WORKOUT_SEPARATOR(i: number): antlr.TerminalNode | null;
    public WORKOUT_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR, i);
    	}
    }
    public any_workout(): Any_workoutContext[];
    public any_workout(i: number): Any_workoutContext | null;
    public any_workout(i?: number): Any_workoutContext[] | Any_workoutContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Any_workoutContext);
        }

        return this.getRuleContext(i, Any_workoutContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_alternation;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitAlternation) {
            return visitor.visitAlternation(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Option_block_titleContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public CHOICE_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_KW, 0)!;
    }
    public CHOICE_NAME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_NAME, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_option_block_title;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitOption_block_title) {
            return visitor.visitOption_block_title(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Option_titleContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public OPTION_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.OPTION_KW, 0)!;
    }
    public CHOICE_NAME(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_NAME, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_option_title;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitOption_title) {
            return visitor.visitOption_title(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class OptionContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public OPTION_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.OPTION_KW, 0)!;
    }
    public CHOICE_NAME(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_NAME, 0)!;
    }
    public COLON(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.COLON, 0)!;
    }
    public any_workout(): Any_workoutContext[];
    public any_workout(i: number): Any_workoutContext | null;
    public any_workout(i?: number): Any_workoutContext[] | Any_workoutContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Any_workoutContext);
        }

        return this.getRuleContext(i, Any_workoutContext);
    }
    public alternation(): AlternationContext[];
    public alternation(i: number): AlternationContext | null;
    public alternation(i?: number): AlternationContext[] | AlternationContext | null {
        if (i === undefined) {
            return this.getRuleContexts(AlternationContext);
        }

        return this.getRuleContext(i, AlternationContext);
    }
    public WORKOUT_SEPARATOR(): antlr.TerminalNode[];
    public WORKOUT_SEPARATOR(i: number): antlr.TerminalNode | null;
    public WORKOUT_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_option;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitOption) {
            return visitor.visitOption(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Option_blockContext extends antlr.ParserRuleContext {
    public _option?: OptionContext;
    public _options: OptionContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public CHOICE_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_KW, 0)!;
    }
    public LBRACE(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.LBRACE, 0)!;
    }
    public RBRACE(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.RBRACE, 0)!;
    }
    public CHOICE_NAME(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.CHOICE_NAME, 0);
    }
    public option(): OptionContext[];
    public option(i: number): OptionContext | null;
    public option(i?: number): OptionContext[] | OptionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(OptionContext);
        }

        return this.getRuleContext(i, OptionContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_option_block;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitOption_block) {
            return visitor.visitOption_block(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class WodContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public any_workout(): Any_workoutContext[];
    public any_workout(i: number): Any_workoutContext | null;
    public any_workout(i?: number): Any_workoutContext[] | Any_workoutContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Any_workoutContext);
        }

        return this.getRuleContext(i, Any_workoutContext);
    }
    public option_block(): Option_blockContext[];
    public option_block(i: number): Option_blockContext | null;
    public option_block(i?: number): Option_blockContext[] | Option_blockContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Option_blockContext);
        }

        return this.getRuleContext(i, Option_blockContext);
    }
    public alternation(): AlternationContext[];
    public alternation(i: number): AlternationContext | null;
    public alternation(i?: number): AlternationContext[] | AlternationContext | null {
        if (i === undefined) {
            return this.getRuleContexts(AlternationContext);
        }

        return this.getRuleContext(i, AlternationContext);
    }
    public WORKOUT_SEPARATOR(): antlr.TerminalNode[];
    public WORKOUT_SEPARATOR(i: number): antlr.TerminalNode | null;
    public WORKOUT_SEPARATOR(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WORKOUT_SEPARATOR, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_wod;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitWod) {
            return visitor.visitWod(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_idContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_IDENTIFIER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_IDENTIFIER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_id;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_id) {
            return visitor.visitDef_id(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_super_kwContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_SUPER_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_SUPER_KW, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_super_kw;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_super_kw) {
            return visitor.visitDef_super_kw(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_super_paramContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_REGEX(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_REGEX, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_super_param;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_super_param) {
            return visitor.visitDef_super_param(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_superContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public def_super_kw(): Def_super_kwContext {
        return this.getRuleContext(0, Def_super_kwContext)!;
    }
    public DEF_PAREN_L(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_PAREN_L, 0)!;
    }
    public DEF_PAREN_R(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_PAREN_R, 0)!;
    }
    public def_super_param(): Def_super_paramContext | null {
        return this.getRuleContext(0, Def_super_paramContext);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_super;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_super) {
            return visitor.visitDef_super(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_param_nameContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_REGEX(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_REGEX, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_param_name;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_param_name) {
            return visitor.visitDef_param_name(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_param_enum_entryContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_IDENTIFIER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_IDENTIFIER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_param_enum_entry;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_param_enum_entry) {
            return visitor.visitDef_param_enum_entry(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_param_enumContext extends antlr.ParserRuleContext {
    public _def_param_enum_entry?: Def_param_enum_entryContext;
    public _def_param_enum_entries: Def_param_enum_entryContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public def_param_enum_entry(): Def_param_enum_entryContext[];
    public def_param_enum_entry(i: number): Def_param_enum_entryContext | null;
    public def_param_enum_entry(i?: number): Def_param_enum_entryContext[] | Def_param_enum_entryContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Def_param_enum_entryContext);
        }

        return this.getRuleContext(i, Def_param_enum_entryContext);
    }
    public DEF_COMMA(): antlr.TerminalNode[];
    public DEF_COMMA(i: number): antlr.TerminalNode | null;
    public DEF_COMMA(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.DEF_COMMA);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.DEF_COMMA, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_param_enum;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_param_enum) {
            return visitor.visitDef_param_enum(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_param_typeContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_TYPES(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_TYPES, 0);
    }
    public DEF_ENUM_KW(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_ENUM_KW, 0);
    }
    public DEF_PAREN_L(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_PAREN_L, 0);
    }
    public def_param_enum(): Def_param_enumContext | null {
        return this.getRuleContext(0, Def_param_enumContext);
    }
    public DEF_PAREN_R(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_PAREN_R, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_param_type;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_param_type) {
            return visitor.visitDef_param_type(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class Def_paramContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public def_param_name(): Def_param_nameContext {
        return this.getRuleContext(0, Def_param_nameContext)!;
    }
    public DEF_COLON(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_COLON, 0)!;
    }
    public def_param_type(): Def_param_typeContext | null {
        return this.getRuleContext(0, Def_param_typeContext);
    }
    public DEF_OPTIONAL(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_OPTIONAL, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_def_param;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDef_param) {
            return visitor.visitDef_param(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class DefinitionContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public DEF_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_KW, 0)!;
    }
    public def_id(): Def_idContext {
        return this.getRuleContext(0, Def_idContext)!;
    }
    public DEF_BRACE_L(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_BRACE_L, 0)!;
    }
    public def_param(): Def_paramContext[];
    public def_param(i: number): Def_paramContext | null;
    public def_param(i?: number): Def_paramContext[] | Def_paramContext | null {
        if (i === undefined) {
            return this.getRuleContexts(Def_paramContext);
        }

        return this.getRuleContext(i, Def_paramContext);
    }
    public DEF_BRACE_R(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_BRACE_R, 0)!;
    }
    public DEF_COLON(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.DEF_COLON, 0);
    }
    public def_super(): Def_superContext | null {
        return this.getRuleContext(0, Def_superContext);
    }
    public DEF_COMMA(): antlr.TerminalNode[];
    public DEF_COMMA(i: number): antlr.TerminalNode | null;
    public DEF_COMMA(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.DEF_COMMA);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.DEF_COMMA, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_definition;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDefinition) {
            return visitor.visitDefinition(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class DefsContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public EOF(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.EOF, 0)!;
    }
    public definition(): DefinitionContext[];
    public definition(i: number): DefinitionContext | null;
    public definition(i?: number): DefinitionContext[] | DefinitionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(DefinitionContext);
        }

        return this.getRuleContext(i, DefinitionContext);
    }
    public new_movement(): New_movementContext[];
    public new_movement(i: number): New_movementContext | null;
    public new_movement(i?: number): New_movementContext[] | New_movementContext | null {
        if (i === undefined) {
            return this.getRuleContexts(New_movementContext);
        }

        return this.getRuleContext(i, New_movementContext);
    }
    public WS(): antlr.TerminalNode[];
    public WS(i: number): antlr.TerminalNode | null;
    public WS(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WS);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WS, i);
    	}
    }
    public NL(): antlr.TerminalNode[];
    public NL(i: number): antlr.TerminalNode | null;
    public NL(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.NL);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.NL, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_defs;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDefs) {
            return visitor.visitDefs(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class New_movement_nameContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public NEW_MOV_IDENTIFIER(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_IDENTIFIER, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_new_movement_name;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitNew_movement_name) {
            return visitor.visitNew_movement_name(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class New_movement_paramsContext extends antlr.ParserRuleContext {
    public _NEW_MOV_PARAM?: Token | null;
    public _nmp: antlr.Token[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public NEW_MOV_PARAM(): antlr.TerminalNode[];
    public NEW_MOV_PARAM(i: number): antlr.TerminalNode | null;
    public NEW_MOV_PARAM(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.NEW_MOV_PARAM);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_PARAM, i);
    	}
    }
    public NEW_MOV_COMMA(): antlr.TerminalNode | null {
        return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_COMMA, 0);
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_new_movement_params;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitNew_movement_params) {
            return visitor.visitNew_movement_params(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class New_movementContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public NEW_MOV_KW(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_KW, 0)!;
    }
    public new_movement_name(): New_movement_nameContext {
        return this.getRuleContext(0, New_movement_nameContext)!;
    }
    public NEW_MOV_COLON(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_COLON, 0)!;
    }
    public new_movement_params(): New_movement_paramsContext {
        return this.getRuleContext(0, New_movement_paramsContext)!;
    }
    public NEW_MOV_SEMI(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.NEW_MOV_SEMI, 0)!;
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_new_movement;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitNew_movement) {
            return visitor.visitNew_movement(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class DayContext extends antlr.ParserRuleContext {
    public _wod?: WodContext;
    public _wods: WodContext[] = [];
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public EOF(): antlr.TerminalNode {
        return this.getToken(UniversalWorkoutLanguageParser.EOF, 0)!;
    }
    public wod(): WodContext[];
    public wod(i: number): WodContext | null;
    public wod(i?: number): WodContext[] | WodContext | null {
        if (i === undefined) {
            return this.getRuleContexts(WodContext);
        }

        return this.getRuleContext(i, WodContext);
    }
    public WOD_KW(): antlr.TerminalNode[];
    public WOD_KW(i: number): antlr.TerminalNode | null;
    public WOD_KW(i?: number): antlr.TerminalNode | null | antlr.TerminalNode[] {
    	if (i === undefined) {
    		return this.getTokens(UniversalWorkoutLanguageParser.WOD_KW);
    	} else {
    		return this.getToken(UniversalWorkoutLanguageParser.WOD_KW, i);
    	}
    }
    public override get ruleIndex(): number {
        return UniversalWorkoutLanguageParser.RULE_day;
    }
    public override accept<Result>(visitor: UniversalWorkoutLanguageParserVisitor<Result>): Result | null {
        if (visitor.visitDay) {
            return visitor.visitDay(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}

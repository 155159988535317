// Generated from UniversalWorkoutLanguageParser.g4 by ANTLR 4.13.1

import { AbstractParseTreeVisitor } from "antlr4ng";


import { ErrorContext } from "./UniversalWorkoutLanguageParser.js";
import { Mov_timeContext } from "./UniversalWorkoutLanguageParser.js";
import { RepsContext } from "./UniversalWorkoutLanguageParser.js";
import { LoadContext } from "./UniversalWorkoutLanguageParser.js";
import { TempoContext } from "./UniversalWorkoutLanguageParser.js";
import { DurationContext } from "./UniversalWorkoutLanguageParser.js";
import { DistanceContext } from "./UniversalWorkoutLanguageParser.js";
import { Complex_movementContext } from "./UniversalWorkoutLanguageParser.js";
import { Movement_nameContext } from "./UniversalWorkoutLanguageParser.js";
import { MovementContext } from "./UniversalWorkoutLanguageParser.js";
import { CommentContext } from "./UniversalWorkoutLanguageParser.js";
import { Workout_idenContext } from "./UniversalWorkoutLanguageParser.js";
import { Workout_attr_idContext } from "./UniversalWorkoutLanguageParser.js";
import { Workout_attr_val_strContext } from "./UniversalWorkoutLanguageParser.js";
import { Workout_attr_val_numberContext } from "./UniversalWorkoutLanguageParser.js";
import { Workout_attr_val_timeContext } from "./UniversalWorkoutLanguageParser.js";
import { Workout_attr_valContext } from "./UniversalWorkoutLanguageParser.js";
import { Workout_attributeContext } from "./UniversalWorkoutLanguageParser.js";
import { Workout_commentContext } from "./UniversalWorkoutLanguageParser.js";
import { WorkoutContext } from "./UniversalWorkoutLanguageParser.js";
import { Shorthand_strengthContext } from "./UniversalWorkoutLanguageParser.js";
import { Shorthand_genericContext } from "./UniversalWorkoutLanguageParser.js";
import { Any_workoutContext } from "./UniversalWorkoutLanguageParser.js";
import { AlternationContext } from "./UniversalWorkoutLanguageParser.js";
import { Option_block_titleContext } from "./UniversalWorkoutLanguageParser.js";
import { Option_titleContext } from "./UniversalWorkoutLanguageParser.js";
import { OptionContext } from "./UniversalWorkoutLanguageParser.js";
import { Option_blockContext } from "./UniversalWorkoutLanguageParser.js";
import { WodContext } from "./UniversalWorkoutLanguageParser.js";
import { Def_idContext } from "./UniversalWorkoutLanguageParser.js";
import { Def_super_kwContext } from "./UniversalWorkoutLanguageParser.js";
import { Def_super_paramContext } from "./UniversalWorkoutLanguageParser.js";
import { Def_superContext } from "./UniversalWorkoutLanguageParser.js";
import { Def_param_nameContext } from "./UniversalWorkoutLanguageParser.js";
import { Def_param_enum_entryContext } from "./UniversalWorkoutLanguageParser.js";
import { Def_param_enumContext } from "./UniversalWorkoutLanguageParser.js";
import { Def_param_typeContext } from "./UniversalWorkoutLanguageParser.js";
import { Def_paramContext } from "./UniversalWorkoutLanguageParser.js";
import { DefinitionContext } from "./UniversalWorkoutLanguageParser.js";
import { DefsContext } from "./UniversalWorkoutLanguageParser.js";
import { New_movement_nameContext } from "./UniversalWorkoutLanguageParser.js";
import { New_movement_paramsContext } from "./UniversalWorkoutLanguageParser.js";
import { New_movementContext } from "./UniversalWorkoutLanguageParser.js";
import { DayContext } from "./UniversalWorkoutLanguageParser.js";


/**
 * This interface defines a complete generic visitor for a parse tree produced
 * by `UniversalWorkoutLanguageParser`.
 *
 * @param <Result> The return type of the visit operation. Use `void` for
 * operations with no return type.
 */
export class UniversalWorkoutLanguageParserVisitor<Result> extends AbstractParseTreeVisitor<Result> {
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.error`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitError?: (ctx: ErrorContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.mov_time`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitMov_time?: (ctx: Mov_timeContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.reps`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitReps?: (ctx: RepsContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.load`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitLoad?: (ctx: LoadContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.tempo`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitTempo?: (ctx: TempoContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.duration`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitDuration?: (ctx: DurationContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.distance`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitDistance?: (ctx: DistanceContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.complex_movement`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitComplex_movement?: (ctx: Complex_movementContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.movement_name`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitMovement_name?: (ctx: Movement_nameContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.movement`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitMovement?: (ctx: MovementContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.comment`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitComment?: (ctx: CommentContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.workout_iden`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitWorkout_iden?: (ctx: Workout_idenContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.workout_attr_id`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitWorkout_attr_id?: (ctx: Workout_attr_idContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.workout_attr_val_str`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitWorkout_attr_val_str?: (ctx: Workout_attr_val_strContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.workout_attr_val_number`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitWorkout_attr_val_number?: (ctx: Workout_attr_val_numberContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.workout_attr_val_time`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitWorkout_attr_val_time?: (ctx: Workout_attr_val_timeContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.workout_attr_val`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitWorkout_attr_val?: (ctx: Workout_attr_valContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.workout_attribute`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitWorkout_attribute?: (ctx: Workout_attributeContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.workout_comment`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitWorkout_comment?: (ctx: Workout_commentContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.workout`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitWorkout?: (ctx: WorkoutContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.shorthand_strength`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitShorthand_strength?: (ctx: Shorthand_strengthContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.shorthand_generic`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitShorthand_generic?: (ctx: Shorthand_genericContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.any_workout`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitAny_workout?: (ctx: Any_workoutContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.alternation`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitAlternation?: (ctx: AlternationContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.option_block_title`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitOption_block_title?: (ctx: Option_block_titleContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.option_title`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitOption_title?: (ctx: Option_titleContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.option`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitOption?: (ctx: OptionContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.option_block`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitOption_block?: (ctx: Option_blockContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.wod`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitWod?: (ctx: WodContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.def_id`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitDef_id?: (ctx: Def_idContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.def_super_kw`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitDef_super_kw?: (ctx: Def_super_kwContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.def_super_param`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitDef_super_param?: (ctx: Def_super_paramContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.def_super`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitDef_super?: (ctx: Def_superContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.def_param_name`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitDef_param_name?: (ctx: Def_param_nameContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.def_param_enum_entry`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitDef_param_enum_entry?: (ctx: Def_param_enum_entryContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.def_param_enum`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitDef_param_enum?: (ctx: Def_param_enumContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.def_param_type`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitDef_param_type?: (ctx: Def_param_typeContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.def_param`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitDef_param?: (ctx: Def_paramContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.definition`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitDefinition?: (ctx: DefinitionContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.defs`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitDefs?: (ctx: DefsContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.new_movement_name`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitNew_movement_name?: (ctx: New_movement_nameContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.new_movement_params`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitNew_movement_params?: (ctx: New_movement_paramsContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.new_movement`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitNew_movement?: (ctx: New_movementContext) => Result;
    /**
     * Visit a parse tree produced by `UniversalWorkoutLanguageParser.day`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitDay?: (ctx: DayContext) => Result;
}


import AddIcon from '@mui/icons-material/Add';
import { Box, Typography, Button, TextField, Paper, CircularProgress } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

import MovementDialog from '../components/MovementLibraryDialog/MovementLibraryDialog';
import { paginationStyles } from '../components/MovementLibraryDialog/MovementLibraryDialog.styles';
import { useMovementSearch } from '../hooks/useMovementsSearch';
import { logger } from '../Logger';
import { createMovementsService, Movement } from '../services/movementsService';

const MovementLibrary: React.FC = () => {
  const [movements, setMovements] = React.useState<Movement[]>([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedMovement, setSelectedMovement] = React.useState<Movement | undefined>();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalMovements, setTotalMovements] = React.useState(0);
  const limit = 30;

  const movementsService = createMovementsService();

  const loadMovements = async () => {
    try {
      const data = await movementsService.getAll(currentPage, limit);
      setMovements(data);
      const totalCount = await movementsService.getTotalCount();
      setTotalMovements(totalCount);
    } catch (error) {
      logger.error('Failed to load movements:', error);
    }
  };

  const handleSearchResults = useCallback((results: Movement[]) => {
    setMovements(results);
    setTotalMovements(results.length);
    setCurrentPage(1);
  }, []);

  const handleEmptySearch = useCallback(() => {
    loadMovements();
  }, []);

  const { isSearching, searchQuery, handleSearch } = useMovementSearch({
    onSearch: useCallback((query: string) => movementsService.search(query), []),
    onSearchResults: handleSearchResults,
    onEmpty: handleEmptySearch,
  });

  useEffect(() => {
    if (!searchQuery) {
      loadMovements();
    }
  }, [currentPage, searchQuery]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedMovement(undefined);
  };

  const handleSave = async (movementData: Movement) => {
    try {
      if (selectedMovement) {
        await movementsService.update(selectedMovement.id, movementData);
      } else {
        await movementsService.create(movementData);
      }
      handleCloseDialog();
    } catch (error) {
      logger.error('Failed to save movement:', error);
    }
  };

  const handleDelete = async () => {
    if (selectedMovement) {
      try {
        await movementsService.delete(selectedMovement.id);
        loadMovements();
        handleCloseDialog();
      } catch (error) {
        logger.error('Failed to delete movement:', error);
      }
    }
  };

  const handleMovementClick = (movement: Movement) => {
    setSelectedMovement(movement);
    setDialogOpen(true);
  };

  const handleNextPage = () => {
    if (currentPage * limit < totalMovements) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const start = (currentPage - 1) * limit + 1;
  const end = Math.min(currentPage * limit, totalMovements);

  return (
    <Box sx={{ p: 3, width: '100%', bgcolor: 'background.default' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
          Movement Library
        </Typography>
        <Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ mr: 2, textTransform: 'none' }}
            onClick={() => setDialogOpen(true)}
          >
            Add movement
          </Button>
        </Box>
      </Box>

      <Box sx={{ position: 'relative' }}>
        <TextField
          fullWidth
          placeholder="Find a movement..."
          variant="outlined"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          sx={{ mb: 3 }}
        />
        {isSearching && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              right: '16px',
              top: '50%',
              transform: 'translateY(-50%)',
              marginTop: '-12px',
              color: 'primary.main',
              opacity: 0.8,
              animation: 'fadeIn 0.2s ease-in',
              '@keyframes fadeIn': {
                '0%': { opacity: 0 },
                '100%': { opacity: 0.8 },
              },
            }}
          />
        )}
      </Box>

      <Paper sx={{ mb: 2 }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '2fr 1fr',
            p: 2,
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            bgcolor: '#f5f5f5',
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Name
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Available attributes
          </Typography>
        </Box>

        {movements.length === 0 ? (
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <Typography color="text.secondary">You have no saved movements.</Typography>
          </Box>
        ) : (
          movements.map((movement) => (
            <Box
              key={movement.id}
              sx={{
                display: 'grid',
                gridTemplateColumns: '2fr 1fr',
                p: 2,
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                cursor: 'pointer',
                '&:hover': { bgcolor: '#f5f5f5' },
              }}
              onClick={() => handleMovementClick(movement)}
            >
              <Typography>{movement.name}</Typography>
              <Typography>
                {Object.entries(movement)
                  .filter(([key, value]) => key.startsWith('has_') && value)
                  .map(([key]) => key.replace('has_', ''))
                  .join(', ')}
              </Typography>
            </Box>
          ))
        )}
      </Paper>

      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
        <Typography variant="body1">
          Showing <strong>{start}</strong> to <strong>{end}</strong> of{' '}
          <strong>{totalMovements}</strong> results
        </Typography>
        <Box>
          <Button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            variant="outlined"
            sx={paginationStyles.button}
          >
            Previous
          </Button>
          <Button
            onClick={handleNextPage}
            disabled={currentPage * limit >= totalMovements}
            variant="outlined"
            sx={paginationStyles.button}
          >
            Next
          </Button>
        </Box>
      </Box>

      <MovementDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSave={handleSave}
        onDelete={selectedMovement ? handleDelete : undefined}
        movement={selectedMovement}
      />
    </Box>
  );
};

export default MovementLibrary;

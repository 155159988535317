import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Button, TextField, Container, Typography, Box, Paper, Avatar, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import logo from '../assets/red_logo.jpg';
import { useAuth } from '../auth/context/AuthContext';

const isRegistrationDisabled = process.env.REACT_APP_DISABLE_REGISTRATION === 'true';

const Login: React.FC = () => {
  const [input, setInput] = useState({
    username: '',
    password: '',
  });
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (input.username && input.password) {
      try {
        await auth.loginAction(input.username, input.password);
        enqueueSnackbar('Login successful!', { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(err.message || 'Login failed. Please check your username and password.', {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar('Please provide username and password', { variant: 'warning' });
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          mt: 8,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: 'white',
          transition: 'box-shadow 0.3s ease-in-out',
          '&:hover': {
            boxShadow: '0 0 15px 5px rgba(255, 0, 0, 0.3)',
          },
        }}
      >
        <img src={logo} alt="Logo" style={{ width: '100px', marginBottom: '20px' }} />{' '}
        {/* Add the logo */}
        <Avatar sx={{ m: 1, bgcolor: 'red' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ color: 'red' }}>
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={handleInput}
            sx={{
              '& .MuiInputBase-root': { color: 'red' },
              '& .MuiInputLabel-root': { color: 'red' },
              '& .MuiInputLabel-root.Mui-focused': { color: 'red' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'red',
                },
                '&:hover fieldset': {
                  borderColor: 'red',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'red',
                },
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleInput}
            sx={{
              '& .MuiInputBase-root': { color: 'red' },
              '& .MuiInputLabel-root': { color: 'red' },
              '& .MuiInputLabel-root.Mui-focused': { color: 'red' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'red',
                },
                '&:hover fieldset': {
                  borderColor: 'red',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'red',
                },
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, bgcolor: 'red', color: 'white' }}
          >
            Sign In
          </Button>
          {!isRegistrationDisabled && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body2">
                Don't have an account?{' '}
                <Link href="/register" sx={{ color: 'red' }}>
                  Register here
                </Link>
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;

import { Workout } from '../@monaco-uwl/UWLVisitor/Workout';

import { parseUWLGeneric } from './parseGeneric';
import { Component } from './parser.types';
import { parseUWLStrength } from './parseStrength';

function assignPrefixes(components: Component[]): Component[] {
  // Track the current letter (A, B, C...) and number (1, 2, 3...) for prefixes
  let currentLetter = 0;
  let currentNumber = 1;

  for (let i = 0; i < components.length; i++) {
    const currentComponent = components[i];
    const nextComponent = i < components.length - 1 ? components[i + 1] : null;

    if (currentComponent.should_be_inside_alternating) {
      // Reset number for new alternating group
      if (i === 0 || !components[i - 1].should_be_inside_alternating) {
        currentNumber = 1;
      }
      // Assign prefix like A1, A2 for alternating components
      currentComponent.prefix = `${String.fromCharCode(65 + currentLetter)}${currentNumber}`;
      currentNumber++;

      // Increment letter at the end of an alternating group
      if (!nextComponent?.should_be_inside_alternating) {
        currentLetter++;
      }
    } else {
      // Assign prefix like A, B for non-alternating components
      currentComponent.prefix = String.fromCharCode(65 + currentLetter);
      currentLetter++;
      currentNumber = 1; // Reset number for non-alternating
    }
  }

  return components;
}

// Helper function to determine if a strength workout should be alternating
function determineIfAlternating(
  current: Workout,
  previous: Workout | null,
  next: Workout | null,
): boolean {
  if (current.is_alternating) {
    return next?.type.toLowerCase() === 'strength';
  }
  return previous?.type.toLowerCase() === 'strength' && previous?.is_alternating;
}

export function parseUWLComponents(wod: Workout[]): Component[] {
  const components: Component[] = [];

  for (let i = 0; i < wod.length; i++) {
    const workout = wod[i];
    const workoutType = workout.type.toLowerCase();

    // Handle generic workouts
    if (workoutType === 'generic') {
      components.push(parseUWLGeneric(workout, ''));
      continue;
    }

    // Handle strength workouts
    if (workoutType === 'strength') {
      const should_be_inside_alternating = determineIfAlternating(workout, wod[i - 1], wod[i + 1]);
      components.push(parseUWLStrength(workout, '', should_be_inside_alternating));
      continue;
    }
  }

  return assignPrefixes(components);
}

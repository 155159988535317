export function formatSecondsToTime(seconds: number): string {
  if (!seconds) return 'Not specified';

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  const format = (n: number) => n.toString().padStart(2, '0');

  return hours > 0
    ? `${format(hours)}:${format(minutes)}:${format(secs)}`
    : `${format(minutes)}:${format(secs)}`;
}

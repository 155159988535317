import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { Box, Typography } from '@mui/material';
import React from 'react';

import { SessionType } from '../../../../generalParser/parser.types';
import { RestType } from '../../../../wodupParser/wodup.types';
import { styles } from '../../WodUpPreview.styles';
import { SetRow } from '../SetRow/SetRow';

import { StrengthPreviewProps } from './StrengthPreview.types';
import { getMovementName, formatRestType } from './StrengthPreviewUtils';
import { useMovementCalculations } from './useMovementsCalculations';

export const StrengthPreview: React.FC<StrengthPreviewProps> = ({ component, sessionType }) => {
  const { movement } = component;
  const { allSetsEqual, hasUniformTempo } = useMovementCalculations(movement);

  const renderSets = () => {
    const { numberOfSets } = component;
    const baseProps = {
      numberOfSets,
      attributes: movement.attributes,
    };

    return allSetsEqual ? (
      <SetRow key={0} index={null} {...baseProps} />
    ) : (
      [...Array(numberOfSets)].map((_, index) => (
        <SetRow key={index} index={index} {...baseProps} />
      ))
    );
  };

  const movementName = getMovementName(
    movement,
    hasUniformTempo,
    movement?.attributes?.tempo || [],
  );

  const headerText = `${component.prefix || ''}${component.prefix ? '. ' : ''}${
    component.name ? `${component.name} ${movementName}` : movementName
  }`.replace(/"/g, '');

  return (
    <Box
      sx={{
        ...styles.genericComponent,
        ...(sessionType === SessionType.AM ? styles.amComponent : styles.pmComponent),
      }}
    >
      <Box sx={styles.genericHeaderWrapper}>
        <Box sx={sessionType === SessionType.AM ? styles.headerLineAM : styles.headerLinePM} />
        <Typography sx={styles.genericHeader}>
          {headerText}
          <FitnessCenterIcon sx={styles.strengthIcon} />
        </Typography>
      </Box>

      <Box sx={styles.contentWrapper}>
        <Typography sx={styles.sectionTitle}>Sets:</Typography>
        {renderSets()}

        <Typography sx={styles.sectionTitle}>
          Rest: <span style={styles.requiredIndicator}>*</span>
        </Typography>
        <Typography sx={styles.sectionContent}>
          {formatRestType(component.rest_type as RestType, component.rest_duration)}
        </Typography>

        <Typography sx={styles.sectionTitle}>Notes</Typography>
        <Typography sx={styles.sectionContent}>
          {component.notes || 'No additional notes'}
        </Typography>
      </Box>
    </Box>
  );
};

import { languages } from 'monaco-editor';

import { createLexer } from './UWLParserFacade';

// https://tomassetti.me/writing-a-browser-based-editor-using-monaco-and-antlr/

const EOF = -1;

class UWLToken implements languages.IToken {
  startIndex: number;
  scopes: string;

  constructor(ruleName: string, startIndex: number) {
    this.scopes = 'uwl.' + ruleName.toLocaleLowerCase();
    this.startIndex = startIndex;
  }
}

class UWLLineTokens implements languages.ILineTokens {
  tokens: languages.IToken[];
  endState: languages.IState;

  constructor(tokens: languages.IToken[]) {
    this.endState = new UWLState();
    this.tokens = tokens;
  }
}

function tokensForLine(input: string): languages.ILineTokens {
  const errorStartingPoints: number[] = [];

  const lexer = createLexer(input);
  lexer.removeErrorListeners();
  //let errorListener = new ErrorCollectorListener();

  //let done = false;
  const myTokens: languages.IToken[] = [];

  do {
    const token = lexer.nextToken();
    if (token === null || token.type === EOF) {
      break;
    } else {
      const tokenTypeName = lexer.symbolicNames[token.type];
      myTokens.push(new UWLToken(tokenTypeName!, token.column));
    }
  } while (true);

  for (const e of errorStartingPoints) {
    myTokens.push(new UWLToken('error', e));
  }

  myTokens.sort((a, b) => (a.startIndex > b.startIndex ? 1 : -1));

  return new UWLLineTokens(myTokens);
}

export class UWLState implements languages.IState {
  clone(): languages.IState {
    return new UWLState();
  }
  equals(other: languages.IState): boolean {
    return true;
  }
}

export class UWLTokensProvider implements languages.TokensProvider {
  getInitialState(): languages.IState {
    return new UWLState();
  }
  tokenize(line: string, state: languages.IState): languages.ILineTokens {
    return tokensForLine(line);
  }
}

import { CharStream, CommonTokenStream } from 'antlr4ng';

import { UniversalWorkoutLanguageLexer } from './antlr/.antlr/UniversalWorkoutLanguageLexer';
import { UniversalWorkoutLanguageParser } from './antlr/.antlr/UniversalWorkoutLanguageParser';

export function createLexer(input: string): UniversalWorkoutLanguageLexer {
  const chars = CharStream.fromString(input);
  const lexer = new UniversalWorkoutLanguageLexer(chars);

  return lexer;
}

export function createParserFromLexer(
  lexer: UniversalWorkoutLanguageLexer,
): UniversalWorkoutLanguageParser {
  const tokens = new CommonTokenStream(lexer);
  return new UniversalWorkoutLanguageParser(tokens);
}

export function createParser(input: string): UniversalWorkoutLanguageParser {
  const lexer = createLexer(input);
  return createParserFromLexer(lexer);
}

import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { authService } from '../../services/authService';
import { tokenService } from '../../services/tokenService';

interface AuthContextType {
  token: string;
  loginAction: (username: string, password: string) => Promise<void>;
  logoutAction: () => void;
}

const AuthContext = createContext<AuthContextType>({
  token: '',
  loginAction: async () => {
    throw new Error('Login action not implemented');
  },
  logoutAction: () => {
    throw new Error('Logout action not implemented');
  },
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [token, setToken] = useState(tokenService.getToken() || '');
  const navigate = useNavigate();

  const loginAction = async (username: string, password: string) => {
    const accessToken = await authService.login(username, password);
    setToken(accessToken);
    tokenService.setToken(accessToken);
    navigate('/programs');
  };

  const logoutAction = () => {
    setToken('');
    tokenService.removeToken();
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ token, loginAction, logoutAction }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

import { useMemo } from 'react';

import { Movement } from '../../../../@monaco-uwl/UWLVisitor/Movement';

import { areAllSetsEqual, areArrayValuesEqual } from './StrengthPreviewUtils';

export const useMovementCalculations = (movement: Movement) => {
  return useMemo(() => {
    const attributes = movement?.attributes || {};
    const { reps = [], load = [], tempo = [], duration = [], distance = [] } = attributes;

    const hasSets = [reps, load, tempo, duration, distance].some((arr) => arr.length > 0);
    const allSetsEqual = areAllSetsEqual({ reps, load, tempo, duration, distance });
    const hasUniformTempo = tempo.length > 0 && areArrayValuesEqual(tempo);

    return {
      hasSets,
      allSetsEqual,
      hasUniformTempo,
    };
  }, [movement]);
};

import { Monaco } from '@monaco-editor/react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
} from '@mui/material';
import { editor, IPosition } from 'monaco-editor';
import React, { useEffect, useState } from 'react';

import MonacoEditor from '../../@monaco-uwl/MonacoEditor';
import { Workout } from '../../@monaco-uwl/UWLVisitor/Workout';
import { parseUWLContent } from '../../generalParser/sessionParser';
import { parseSession } from '../../wodupParser/sessionWodupParser';
import WodUpPreview from '../WodUpPreview/WodUpPreview';

import { styles } from './ExpandedViewDialog.styles';
import { ExpandedViewDialogProps } from './ExpandedViewDialog.types';

const DialogHeader: React.FC<{ weekNumber: number; dayNumber: number }> = ({
  weekNumber,
  dayNumber,
}) => <DialogTitle sx={styles.dialogTitle}>{`Week ${weekNumber} - Day ${dayNumber}`}</DialogTitle>;

const EditSection: React.FC<{
  content: string;
  onContentChange: (content: string) => void;
  onEditorDidMount?: (editor: editor.IStandaloneCodeEditor, monacoInstance: Monaco) => void;
  initialCursorPosition?: IPosition | null;
}> = ({ content, onContentChange, onEditorDidMount, initialCursorPosition }) => {
  const handleEditorMount = (editor: editor.IStandaloneCodeEditor, monacoInstance: Monaco) => {
    if (initialCursorPosition) {
      editor.setPosition(initialCursorPosition);
      editor.revealPositionInCenter(initialCursorPosition);
      editor.focus();
    }
    onEditorDidMount?.(editor, monacoInstance);
  };

  return (
    <Paper elevation={3} sx={styles.editPaper}>
      <Typography variant="body1" sx={styles.sectionTitle}>
        Edit Content
      </Typography>
      <Box sx={styles.editorBox}>
        <MonacoEditor
          value={content}
          onChange={(value: string | undefined) => onContentChange(value ?? '')}
          onMount={handleEditorMount}
        />
      </Box>
    </Paper>
  );
};

const PreviewSection: React.FC<{ Uwlcontent: Workout[][] }> = ({ Uwlcontent }) => (
  <Paper elevation={3} sx={styles.previewPaper}>
    <Typography variant="body1" sx={styles.sectionTitle}>
      Preview
    </Typography>
    <Box sx={styles.previewBox}>
      <WodUpPreview Uwlcontent={Uwlcontent} />
    </Box>
  </Paper>
);

const ExpandedViewDialog: React.FC<ExpandedViewDialogProps> = ({
  open,
  onClose,
  content,
  onContentChange,
  weekNumber,
  dayNumber,
  UwlContent,
  onEditorDidMount,
  initialCursorPosition,
}) => {
  const [debugOutput, setDebugOutput] = useState<string>('');
  const [showDebugWindow, setShowDebugWindow] = useState(false);

  const handleDebugParse = () => {
    try {
      const sessions = parseUWLContent(UwlContent);
      const wod = parseSession(sessions[0]);
      setDebugOutput(JSON.stringify(wod, null, 2));
      setShowDebugWindow(true);
    } catch (error) {
      setDebugOutput('Error parsing content');
    }
  };

  useEffect(() => {
    if (open && content) {
      onContentChange(content);
    }
  }, [open, content, onContentChange]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} fullWidth sx={styles.dialog}>
      <DialogHeader weekNumber={weekNumber} dayNumber={dayNumber} />
      {process.env.REACT_APP_SHOW_DEBUG_TOOLS === 'true' && (
        <Button variant="contained" onClick={handleDebugParse} sx={styles.debugButton}>
          Debug Parse
        </Button>
      )}
      <DialogContent sx={styles.dialogContent}>
        <Box sx={styles.contentWrapper}>
          <EditSection
            content={content}
            onContentChange={onContentChange}
            onEditorDidMount={onEditorDidMount}
            initialCursorPosition={initialCursorPosition}
          />
          <PreviewSection Uwlcontent={UwlContent} />
          {process.env.REACT_APP_SHOW_DEBUG_TOOLS === 'true' && showDebugWindow && (
            <Paper sx={styles.debugWindow}>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="h6">Debug Output</Typography>
                <IconButton onClick={() => setShowDebugWindow(false)} size="small">
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography sx={styles.debugOutput}>{debugOutput}</Typography>
            </Paper>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ExpandedViewDialog;

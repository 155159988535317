export const headerStyles = {
  wrapper: {
    p: 1,
    borderBottom: 1,
    borderColor: 'grey.300',
    bgcolor: 'grey.200',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  text: {
    fontWeight: 'bold',
  },
  dayText: {
    fontWeight: 'bold',
    marginLeft: 'auto',
  },
};

export const contentStyles = {
  wrapper: {
    p: 1,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '250px',
    position: 'relative',
  },
};

export const monacoMimicStyles = {
  width: '100%',
  height: '100%',
  overflow: 'auto',
  whiteSpace: 'pre-wrap',
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
  padding: '4px',
  lineHeight: '18px',
  color: '#000000',
  backgroundColor: '#ffffff',
  border: 'none',
  outline: 'none',
};

import { SxProps } from '@mui/material';

export const dialogPaperProps: SxProps = {
  maxHeight: '90vh',
  mt: 2,
};

export const dialogTitleStyles: SxProps = {
  bgcolor: '#2A2A2A',
  color: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  py: 2,
};

export const closeIconStyles: SxProps = {
  color: 'white',
};

export const dialogContentStyles: SxProps = {
  mt: 2,
  pt: 2,
};

export const nameBoxStyles: SxProps = {
  mb: 3,
};

export const textFieldStyles: SxProps = {
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#1976d2',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#1976d2',
  },
};

export const formGroupStyles: SxProps = {
  mb: 3,
};

export const checkboxStyles: SxProps = {
  '&.Mui-checked': {
    color: '#1976d2',
  },
  '&:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
  },
};

export const helperTextStyles: SxProps = {
  mb: 3,
};

export const videoUrlFieldStyles: SxProps = {
  ...textFieldStyles,
  mb: 1,
};

export const dialogActionsStyles: SxProps = {
  p: 2,
  display: 'flex',
  justifyContent: 'space-between',
};

export const removeButtonStyles: SxProps = {
  color: '#E31B23',
  borderColor: '#E31B23',
  '&:hover': {
    borderColor: '#E31B23',
    bgcolor: 'rgba(227, 27, 35, 0.04)',
  },
};

export const cancelButtonStyles: SxProps = {
  mr: 1,
  textTransform: 'none',
};

export const saveButtonStyles: SxProps = {
  textTransform: 'none',
  bgcolor: '#1976d2',
  '&:hover': {
    bgcolor: '#1565c0',
  },
  '&.Mui-disabled': {
    bgcolor: 'rgba(25, 118, 210, 0.12)',
  },
};

export const editButtonStyles: SxProps = {
  textTransform: 'none',
  color: '#1976d2',
  bgcolor: 'transparent',
  '&:hover': {
    bgcolor: 'rgba(25, 118, 210, 0.04)',
  },
};

export const paginationStyles = {
  button: {
    textTransform: 'none',
    bgcolor: 'background.paper',
    borderColor: 'grey.300',
    '&:hover': {
      bgcolor: 'grey.200',
    },
  },
  pageInfo: {
    fontWeight: 'bold',
    color: 'text.primary',
  },
};

export const styles = {
  container: {
    p: 3,
    bgcolor: 'background.default',
    borderRadius: 2,
  },
  contentBox: {
    bgcolor: 'background.default',
    p: 2,
    borderRadius: 1,
  },
  exerciseTitle: {
    fontWeight: 'bold',
    color: 'primary.main',
  },
  description: {
    mb: 2,
  },
  sectionTitle: {
    fontWeight: 'bold',
    color: 'text.primary',
    mt: 0.5,
    mb: 0.25,
    fontSize: '0.8rem',
  },
  movementBox: {
    display: 'flex',
    alignItems: 'center',
    mb: 0.5,
  },
  infoIcon: {
    ml: 1,
    fontSize: 'small',
  },
  setBox: {
    display: 'flex',
    alignItems: 'center',
    mb: 1,
  },
  checkbox: {
    p: 0,
    mr: 1,
    color: 'secondary.main',
  },
  checkIcon: {
    mr: 1,
    fontSize: 'small',
  },
  sessionPaper: {
    p: 3,
    borderRadius: 2,
    bgcolor: 'background.paper',
    boxShadow: 3,
  },
  sessionTitle: {
    fontWeight: 'bold',
    mb: 2,
    pb: 1,
    borderBottom: '2px solid',
    borderColor: 'divider',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
  },
  sessionIcon: {
    marginRight: 1,
    fontSize: 'large',
  },
  componentBox: {
    mt: 1,
    mb: 1,
  },
  amSession: {
    borderLeft: 6,
    borderColor: 'warning.main',
  },
  pmSession: {
    borderLeft: 6,
    borderColor: 'info.main',
  },
  amComponent: {
    borderTop: 3,
    borderColor: 'warning.light',
  },
  pmComponent: {
    borderTop: 3,
    borderColor: 'info.light',
  },
  genericComponent: {
    bgcolor: 'background.paper',
    borderRadius: 2,
    p: 1.5,
    boxShadow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  genericHeaderWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    mb: 0.5,
  },
  headerLineAM: {
    width: 2,
    minHeight: 16,
    bgcolor: '#FFA500',
    mr: 0.5,
    borderRadius: 1,
    flexShrink: 0,
  },
  headerLinePM: {
    width: 2,
    minHeight: 16,
    bgcolor: '#1976D2',
    mr: 0.5,
    borderRadius: 1,
    flexShrink: 0,
  },
  genericHeader: {
    color: 'text.primary',
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: 1.2,
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    flex: 1,
  },
  contentWrapper: {
    overflow: 'auto',
    flexGrow: 1,
  },
  sectionContent: {
    ml: 0.5,
    mb: 0.5,
    color: 'text.primary',
    fontSize: '0.8rem',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },
  movementItem: {
    display: 'flex',
    alignItems: 'center',
    mb: 0.25,
    overflow: 'hidden',
  },
  movementBulletAM: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    bgcolor: '#FFA500',
    mr: 0.5,
    flexShrink: 0,
  },
  movementBulletPM: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    bgcolor: '#1976D2',
    mr: 0.5,
    flexShrink: 0,
  },
  requiredIndicator: {
    color: 'red',
    marginLeft: '-1px',
  },
  strengthIcon: {
    fontSize: '1rem',
    marginLeft: '0.5rem',
    verticalAlign: 'middle',
    color: 'text.secondary',
  },
  warmupIcon: {
    fontSize: '1rem',
    marginLeft: '0rem',
    marginRight: '0.3rem',
    verticalAlign: 'middle',
    color: 'text.secondary',
  },
  setRow: {
    display: 'flex',
    alignItems: 'left',
    marginBottom: 0,
  },
  setRowIcon: {
    marginRight: 0,
  },
  alternatingIcon: {
    fontSize: '1rem',
    marginLeft: '0rem',
    verticalAlign: 'middle',
    marginRight: '0.3rem',
    color: 'text.secondary',
  },
  alternatingComponent: {
    border: '2px solid',
    borderRadius: 2,
    p: 1.5,
    mb: 1,
  },
  alternatingComponentAM: {
    borderColor: 'warning.main',
  },
  alternatingComponentPM: {
    borderColor: 'info.main',
  },
  alternatingSubComponent: {
    ml: 2,
    mt: 1,
    mb: 1,
  },
};

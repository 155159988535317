import { Movement } from '../@monaco-uwl/UWLVisitor/Movement';

export interface GenericComponent {
  type: 'Generic';
  is_alternating: boolean;
  should_be_inside_alternating: boolean;
  prefix: string;
  name: string;
  score_by: string;
  description: string;
  movements: string[];
  time_cap: number;
  notes: string;
}

export interface StrengthComponent {
  type: 'Strength';
  is_alternating: boolean;
  should_be_inside_alternating: boolean;
  name: string;
  prefix: string;
  rest_type: string;
  rest_duration?: number;
  movement: Movement;
  notes: string;
  numberOfSets: number;
}

export interface WarmUpComponent {
  type: 'WarmUp';
  name: string;
  description: string;
  movements: string[];
  notes: string;
}

export type Component = GenericComponent | StrengthComponent;

export interface Session {
  type: SessionType;
  components: Component[];
}

export enum SessionType {
  AM = 'AM',
  PM = 'PM',
}

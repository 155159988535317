import AddIcon from '@mui/icons-material/Add';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemButton,
  Paper,
  CircularProgress,
  Box,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import api from '../api/base';
import CreateProgramForm from '../components/CreateProgramForm';

export interface Program {
  id: number;
  name: string;
  description: string;
  days: number;
  clientCount: number;
}

const ProgramsList: React.FC = () => {
  const [programs, setPrograms] = useState<Program[]>([]);
  const [loading, setLoading] = useState(true);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [editingProgram, setEditingProgram] = useState<Program | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPrograms();
  }, []);

  const fetchPrograms = async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/programs');
      setPrograms(response.data);
    } catch (error) {
      console.error('Error fetching programs:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProgramSelect = (programId: number) => {
    navigate(`/workout-planner/${programId}`);
  };

  const handleCreateProgram = async (program: Program) => {
    setPrograms([...programs, program]);
    setShowCreateForm(false);
    await fetchPrograms();
  };

  const handleEditProgram = (program: Program) => {
    setEditingProgram(program);
    setShowCreateForm(true);
  };

  const handleUpdateProgram = async (updatedProgram: Program) => {
    try {
      await api.put(`/api/programs/${updatedProgram.id}`, updatedProgram);
      setPrograms(programs.map((p) => (p.id === updatedProgram.id ? updatedProgram : p)));
      setShowCreateForm(false);
      setEditingProgram(null);
    } catch (error) {
      console.error('Error updating program:', error);
    }
  };

  const handleDeleteProgram = async (programId: number) => {
    if (window.confirm('Are you sure you want to delete this program?')) {
      try {
        await api.delete(`/api/programs/${programId}`);
        setPrograms(programs.filter((p) => p.id !== programId));
      } catch (error) {
        console.error('Error deleting program:', error);
      }
    }
  };

  if (showCreateForm) {
    return (
      <CreateProgramForm
        onSubmit={editingProgram ? handleUpdateProgram : handleCreateProgram}
        onCancel={() => {
          setShowCreateForm(false);
          setEditingProgram(null);
        }}
        initialProgram={editingProgram}
      />
    );
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h4" fontWeight="bold">
          Programs
        </Typography>
        <Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setShowCreateForm(true)}
            sx={{
              mr: 2,
              textTransform: 'none',
              bgcolor: '#1976d2',
              '&:hover': { bgcolor: '#1565c0' },
            }}
          >
            Create program
          </Button>
          {/* Commented out manage tags button
          <Button
            variant="outlined"
            startIcon={<SettingsIcon />}
            sx={{
              textTransform: 'none',
              color: '#1976d2',
              borderColor: '#1976d2',
              '&:hover': { bgcolor: 'rgba(25, 118, 210, 0.04)' },
            }}
          >
            Manage tags
          </Button>
          */}
        </Box>
      </Box>

      <Box display="flex" mb={4}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search"
          sx={{ mr: 2, bgcolor: 'background.paper' }}
        />
        {/* Commented out filters button
        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          sx={{
            textTransform: 'none',
            color: '#1976d2',
            borderColor: '#1976d2',
            '&:hover': { bgcolor: 'rgba(25, 118, 210, 0.04)' },
            minWidth: '120px',
          }}
        >
          Filters
        </Button>
        */}
      </Box>

      <Paper elevation={3}>
        {loading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : (
          <List disablePadding>
            <ListItem sx={{ bgcolor: 'grey.100', py: 2, px: 3 }}>
              <Box width="10%" fontWeight="bold">
                Days
              </Box>
              <Box width="75%" fontWeight="bold">
                Name
              </Box>
              <Box width="15%" />
            </ListItem>
            {programs.map((program) => (
              <ListItem
                disablePadding
                key={program.id}
                sx={{ borderBottom: '1px solid', borderColor: 'grey.200' }}
              >
                <ListItemButton
                  onClick={() => handleProgramSelect(program.id)}
                  sx={{ py: 2, px: 3 }}
                >
                  <Box width="10%" display="flex" alignItems="center">
                    <CalendarTodayIcon sx={{ mr: 1, color: 'text.secondary' }} />
                    <Typography>{program.days}</Typography>
                  </Box>
                  <Box width="75%">
                    <Typography variant="subtitle1" fontWeight="medium">
                      {program.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {program.description}
                    </Typography>
                  </Box>
                  <Box width="15%" display="flex" justifyContent="flex-end" alignItems="center">
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditProgram(program);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteProgram(program.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
    </Container>
  );
};

export default ProgramsList;

const drawerWidth = 130;

export const styles = {
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: drawerWidth,
      boxSizing: 'border-box',
      backgroundColor: '#2C2C2C',
      borderRight: 'none',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  menuItem: (isActive: boolean, justifyContent: 'flex-start' | 'center') => ({
    display: 'flex',
    justifyContent,
    alignItems: 'center',
    padding: '10px 12px',
    color: '#FFFFFF',
    backgroundColor: isActive ? '#3C3C3C' : 'inherit',
    border: 'none',
    boxShadow: 'none',
    transition: 'background-color 0.2s ease-in-out',
    '&:not(.Mui-disabled):hover': {
      backgroundColor: '#3C3C3C',
      transform: 'scale(1.02)',
      transition: 'all 0.2s ease-in-out',
    },
  }),
  listItemIcon: {
    color: '#FFFFFF',
    minWidth: '30px',
  },
  listItemText: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
  },
  signOutButton: (isActive: boolean) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0',
    color: '#FFFFFF',
    backgroundColor: isActive ? '#3C3C3C' : 'inherit',
    '&:hover': {
      backgroundColor: '#3C3C3C',
    },
    border: 'none',
    boxShadow: 'none',
  }),
  signOutIcon: {
    color: '#FFFFFF',
    minWidth: 'auto',
  },
  inProgressBadge: {
    position: 'relative',
    '&::after': {
      content: '"SOON"',
      position: 'absolute',
      top: -8,
      right: -8,
      backgroundColor: 'red',
      color: '#FFFFFF',
      fontSize: '0.6rem',
      padding: '2px 4px',
      borderRadius: '4px',
      fontWeight: 'bold',
    },
  },
  inProgressMenuItem: {
    opacity: 0.7,
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: 'inherit !important',
      transform: 'none !important',
      transition: 'none !important',
    },
  },
};
